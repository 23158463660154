const TextError = (props) => {
  return (
    <div
      style={{
        textAlign: 'start',
        // paddingLeft: '5px',
      }}
    >
      <div className="error">{props.children && props.children + ' *'}</div>
    </div>
  )
}

export default TextError

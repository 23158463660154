import { useState, useEffect } from 'react'
import { validateArray } from '../../utils/Validation'
import { Loading } from '../../components/animation'
import { Icon } from '../../components/buttons'
import { H3, H4 } from '../../components/text'
import { BoxFlex } from '../../components/boxes'
import { useAxiosAuth } from '../../hooks'
import HeaderFilter from './HeaderFilter'
import TableMain from './TableMain'
// import TableFooter from './TableFooter'

const TableFilterContainer = (props) => {
  const {
    param,
    header,
    onClick,
    preConfig = (data) => data,
    boxFilters,
    handleReload,
    addParam = '',
    addHeader = [],
    componet = () => {},
    emptyListMessage,
  } = props
  const headerSelector = generateHeader(header)

  function generateHeader(header) {
    let aux = []
    if (validateArray(header)) {
      aux = header.filter((element) => !element.type)
    }
    return [...addHeader, ...aux]
  }

  const [urlParam, seturlParam] = useState({
    search: {
      field: validateArray(headerSelector)
        ? headerSelector[0].search || headerSelector[0].key
        : '',
      search: '',
    },
  })
  const [pageNumber, setPageNumber] = useState(1)
  const [filters, setfilters] = useState({})

  function getFilter(JsonData) {
    let aux = `?PageNumber=${pageNumber}${
      urlParam.search.search
        ? `&ForQuery=${urlParam.search.search}&TypeQuery=${urlParam.search.field}`
        : ''
    }${addParam ? `&${addParam}` : ''}`
    if (JsonData) {
      for (let key in JsonData) {
        aux = aux + `${JsonData[key] ? `&${key}=${JsonData[key]}` : ''}`
      }
    }
    return aux
  }
  const { response, loading, reload, header: headerAxios } = useAxiosAuth({
    method: 'GET',
    url: `${param}${getFilter(filters)}`,
  })

  useEffect(() => {
    if (filters && filters !== {}) {
      reload()
    }
  }, [filters])

  useEffect(() => {
    if (!loading) {
      reload()
    }
  }, [pageNumber])

  useEffect(() => {
    if (!loading && typeof handleReload === 'boolean') {
      reload()
    }
  }, [handleReload])

  function onSearch() {
    reload()
  }
  return (
    <div>
      {!loading && componet(reload)}
      <HeaderFilter
        urlParam={urlParam}
        seturlParam={seturlParam}
        filters={filters}
        setfilters={setfilters}
        onSearch={onSearch}
        options={headerSelector}
        boxFilters={boxFilters}
      />
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        <>
          <TableMain
            header={header}
            main={preConfig(response)}
            handleInfo={onClick}
            reload={reload}
          />
          <Footer
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            pageInfo={headerAxios}
            amount={response.length}
          />
        </>
      ) : (
        <>
          <br />
          {emptyListMessage ? emptyListMessage : <H3>No tienes registros</H3>}
        </>
      )}
    </div>
  )
}

function generatePage(header) {
  if (header && header.pagination) {
    return JSON.parse(header.pagination)
  }
  return null
}

const Footer = (props) => {
  const { amount, pageInfo, setPageNumber, pageNumber } = props

  const header = generatePage(pageInfo)
  function handlePageNumber(number) {
    setPageNumber((a) => a + number)
  }

  if (header) {
    return (
      <BoxFlex>
        <H4 margin="0" fontSize="1.4rem">
          Mostrando del {(pageNumber - 1) * header.itemsPerPage + 1} -{' '}
          {(pageNumber - 1) * header.itemsPerPage + amount} de{' '}
          {header.totalItems}
        </H4>
        <BoxFlex>
          {header.currentPage !== 1 && (
            <Icon
              handleModal={() => setPageNumber(1)}
              disabled={false}
              children="1"
            />
          )}
          <Icon
            handleModal={() => handlePageNumber(-1)}
            disabled={header.currentPage > 1 ? false : true}
            ico="chevron-left"
          />
          {pageNumber - 1 > 1 && (
            <Icon
              handleModal={() => setPageNumber(pageNumber - 1)}
              disabled={false}
              children={pageNumber - 1}
            />
          )}
          <Icon disabled={true} children={pageNumber} />
          {pageNumber + 1 < header.totalPages && (
            <Icon
              handleModal={() => setPageNumber(pageNumber + 1)}
              disabled={false}
              children={pageNumber + 1}
            />
          )}
          <Icon
            disabled={header.totalPages === pageNumber ? true : false}
            handleModal={() => handlePageNumber(1)}
            ico="chevron-right"
          />
          {header.totalPages !== pageNumber && (
            <Icon
              handleModal={() => setPageNumber(header.totalPages)}
              disabled={false}
              children={header.totalPages}
            />
          )}
        </BoxFlex>
      </BoxFlex>
    )
  } else {
    return (
      <H4 margin="0" fontSize="1.4rem">
        Total: {amount}
      </H4>
    )
  }
}

export default TableFilterContainer

import { withRouter } from 'react-router-dom'
import { ButtonIcon } from '../buttons'
const GoBackFlex = (props) => {
  const {
    history,
    children,
    justify,
    center,
    location,
    content = 'Volver atrás',
  } = props
  const stylesFlex = {
    display: 'flex',
    justifyContent: justify ? justify : 'space-between',
  }
  const status = [
    '/',
    '/course-english',
    '/course-programming',
    '/about',
    '/course-chess',
    '/seminar/big-data',
    '/resume-contact',
    '/presentation',
    '/select/rol',
    '/welcome/educator',
    '/welcome/afiliate',
    '/welcome/student'
  ]
  if (status.indexOf(location.pathname) > -1) {
    return null
  } else {
    return (
      <div style={stylesFlex}>
        <ButtonIcon
          content={content}
          icon="chevron-left"
          backButton={true}
          onClick={() => history.goBack()}
        />
        {children}
        {center && <div className=""></div>}
      </div>
    )
  }
}

export default withRouter(GoBackFlex)

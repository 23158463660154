import './style/style.css'
import { Sidebar } from '../navigation'
import { BrowserRouter } from 'react-router-dom'
import Header from './Header'
import Navbar from './Navbar'
import Main from '../../routes/Main'
import { Snackbar } from '../common'
import { Information } from './aside'
import { connect } from 'react-redux'
import GridContainer from './GridContainer'
import { GlobalModal } from '../modal'
import { closeSession, changeInfo, changeMembership } from '../../store/actions'
import { useAxiosAuth } from '../../hooks'
import { validateStatus } from '../../utils/Validation'
import { useEffect } from 'react'
const Template = (props) => {
  const { responsive, areas, navigation } = props.layoutReducer
  const contStyle = {
    boxShadow: 'none',
    borderRadius: '0px',
  }
  const { response, loading, reload, error } = useAxiosAuth({
    method: 'GET',
    url: '/users/globalinfo',
  })
  useEffect(() => {
    if (response && !loading && validateStatus(error)) {
      props.changeMembership(response.membershipCurrent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, loading, error])
  useEffect(() => {
    reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.localStorage.getItem('id')])

  return (
    <BrowserRouter>
      <GridContainer responsive={responsive} areas={areas}>
        {areas.includes('Nav') && (
          <div className="itemNavbar">
            <Navbar />
          </div>
        )}
        {navigation && (
          <div className="itemnav">
            <Header headerTitle={props.headerTitle} {...props} />
          </div>
        )}
        {areas.includes('Sidebar') && (
          <div className="itemSidebar">
            <Sidebar />
          </div>
        )}
        {areas.includes('Conten') && (
          <div
            className="itemContent"
            style={!areas.includes('Inf') ? contStyle : null}
          >
            <Main />
          </div>
        )}
        {areas.includes('Inf') && (
          <div className="itemInformation">
            <Information />
          </div>
        )}
        <GlobalModal />
        <Snackbar />
      </GridContainer>
    </BrowserRouter>
  )
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
    headerTitle: state.headerTitle,
    layoutReducer: state.layoutReducer,
    membership: state.membership,
  }
}
export default connect(mapStateToProps, {
  closeSession,
  changeInfo,
  changeMembership,
})(Template)

import * as Yup from "yup";
export const colorsDefault = {
  white: "#ffffff",
  white2: "#F8F8F8",
  orange1: "#f5b38a",
  orange2: "#EE7C38",
  yellow: "#F4B518",
  green: "#469585",
  purple: "#773B8E",
  black: "#000000",
};
export const utbColors = {
  color1: "#34393F",
  color2: "#686868",
  color3: "#F4B518",
  color4: "#A417B3",
  color5: "#B89121",
  color6: "#fff",
  color7: "#494D4C",
  color8: "#A417B3",
  fondo1: "#F4B51821",
  fondo2: "#34393F",
  fondo3: "#F4B518",
};
export const urlAvatar =
  "https://res.cloudinary.com/dzxmzxvbr/image/upload/v1614259401/user_h6o64n.jpg";

export const avatarMale =
  "https://res.cloudinary.com/dv4mdvthq/image/upload/v1686155167/avatar_qdbnqw.webp";
export const avatarFemale =
  "https://res.cloudinary.com/dv4mdvthq/image/upload/v1686155128/avatar_grb2tn.webp";

export const avatarChild =
  "https://res.cloudinary.com/pataformacgi/image/upload/v1655472643/assets/pngegg_pmvwzb_vl86bj.png";

export const partelogo1 =
  "https://res.cloudinary.com/dzxmzxvbr/image/upload/v1616871926/assets/partelogo1_ackky4.png";
export const partelogo2 =
  "https://res.cloudinary.com/dzxmzxvbr/image/upload/v1616871926/assets/partelogo2_tkiuds.png";
const dropdownCompletionTime = [
  { key: "Complejidad:", value: "" },
  { key: "Alta", value: "1" },
  { key: "Media", value: "2" },
  { key: "Baja", value: "3" },
];
const dropdownLanguage = [
  { key: "Idioma:", value: "" },
  { key: "Español", value: "es" },
  { key: "Inglés", value: "en" },
  { key: "Alemán", value: "de" },
  { key: "Francés", value: "fr" },
  { key: "Portugués", value: "pt" },
];
export const dropdownGender = [
  { key: "Genero:", value: "" },
  { key: "Masculino", value: "male" },
  { key: "Femenino", value: "female" },
];
export const dropdownRol = [
  { key: "Tipo de rol:", value: "" },
  { key: "Afiliado", value: "afiliado" },
  { key: "Creador", value: "creador" },
];
export const dropdownCurrency = [
  { key: "Moneda:", value: "" },
  { key: "Bolivianos", value: "Bs" },
  { key: "Dolares", value: "SUS" },
];

export const dropdownDepartament = [
  { key: "Expedida en:", value: "" },
  { key: "Chuquisaca", value: "CH" },
  { key: "La Paz", value: "LP" },
  { key: "Cochabamba", value: "CB" },
  { key: "Oruro", value: "OR" },
  { key: "Potosí", value: "PT" },
  { key: "Tarija", value: "TJ" },
  { key: "Santa Cruz", value: "SC" },
  { key: "Beni", value: "BE" },
  { key: "Pando", value: "PD" },
];
export const filesExtension = {
  ".pdf": "Archivo PDF",
  ".docx": "Archivo Word",
  url: "Enlace",
  "image/*": "Imágenes",
  "audio/*": "Audio",
  "video/*": "Video",
  "": "Todo tipo de archivos",
};
export const TypeLesson = {
  video: "Video",
  quiz: "Examen",
  forum: "Foro",
  homework: "Tarea",
  virtualclass: "Clase virtual",
  certificate: "Certificado",
};
export const TypeStatus = {
  available: "Inconcluso",
  completed: "Presentado",
  scored: "Calificado",
};
export const colors = {
  red: "#c93f3f3f",
  yellow: "#e3ad2d3f",
  green: "#4695853f",
  gray: "#5F59613f",
};
export const dropdownExtension = [
  { key: "Archivo PDF", value: ".pdf" },
  { key: "Archivo Word", value: ".docx" },
  { key: "Enlace", value: "url" },
  { key: "Imágenes", value: "image/*" },
  { key: "Audio", value: "audio/*" },
  { key: "Todo tipo de archivos", value: "" },
];
export const optionsList = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "7", value: "7" },
  { key: "8", value: "8" },
  { key: "9", value: "9" },
  { key: "10", value: "10" },
  { key: "11", value: "11" },
  { key: "12", value: "12" },
  { key: "13", value: "13" },
  { key: "14", value: "14" },
  { key: "15", value: "15" },
  { key: "16", value: "16" },
  { key: "17", value: "17" },
  { key: "18", value: "18" },
  { key: "19", value: "19" },
  { key: "20", value: "20" },
];
export const InputsJsonQuiz = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Título del examen",
  },
  // {
  //   name: 'StartDate',
  //   initial: null,
  //   validate: Yup.string().required('Campo requerido').nullable(),
  //   control: 'date',
  //   label: 'Fecha de inicio',
  //   dateFormat: 'MMMM d, yyyy HH:mm',
  //   showTimeSelect: true,
  //   timeIntervals: 15,
  // },
  // {
  //   name: 'FinishDate',
  //   initial: null,
  //   validate: Yup.string().required('Campo requerido').nullable(),
  //   control: 'date',
  //   label: 'Fecha de conclusión',
  //   dateFormat: 'MMMM d, yyyy HH:mm',
  //   showTimeSelect: true,
  //   timeIntervals: 15,
  // },
  {
    type: "severalInputs",
    inputs: [
      {
        name: "QuizTimeLimit",
        initial: "00:00:00",
        validate: Yup.string()
          .required("Campo requerido")
          .matches(
            /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/,
            "Tiempo no válido"
          ),
        type: "text",
        label: "Tiempo limite",
      },
      {
        name: "QuizMinimumGrade",
        initial: 0,
        validate: Yup.number()
          .typeError("Eso no parece un número")
          .required("Campo Requerido")
          .max(100, "La cantidad máxima de nota es 100")
          .min(0, "La cantidad mínima de nota es 0"),
        type: "number",
        label: "Nota mínima de aprobación",
      },
    ],
  },
  {
    name: "Public",
    initial: false,
    validate: Yup.boolean().required("Campo requerido"),
    type: "checkbox",
    label: "Publicar el examen",
  },
  {
    name: "Attemps",
    initial: 1,
    validate: Yup.string().required("Campo requerido"),
    type: "number",
    label: "Numero de intentos",
  },
  {
    name: "Description",
    initial: null,
    control: "editor",
    validate: null,
    label: "Descripción",
  },
];
export const InputsJsonForum = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Título del foro",
  },
  // {
  //   name: 'StartDate',
  //   initial: null,
  //   validate: Yup.string().required('Campo requerido').nullable(),
  //   control: 'date',
  //   label: 'Fecha de inicio',
  //   dateFormat: 'MMMM d, yyyy HH:mm',
  //   showTimeSelect: true,
  //   timeIntervals: 15,
  // },
  // {
  //   name: 'FinishDate',
  //   initial: null,
  //   validate: Yup.string().required('Campo requerido').nullable(),
  //   control: 'date',
  //   label: 'Fecha de conclusión',
  //   dateFormat: 'MMMM d, yyyy HH:mm',
  //   showTimeSelect: true,
  //   timeIntervals: 15,
  // },
  {
    name: "Public",
    initial: false,
    validate: Yup.boolean().required("Campo requerido"),
    type: "checkbox",
    label: "Publicar el examen",
  },
  {
    name: "Description",
    initial: null,
    control: "editor",
    validate: null,
    label: "Descripción",
  },
];

export const InputsJsonHomework = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Título de la tarea",
  },
  {
    name: "FileExtension",
    initial: "",
    validate: Yup.string(),
    control: "select",
    label: "Tipo de archivo permitido",
    options: dropdownExtension,
  },
  {
    name: "NumberFiles",
    initial: 1,
    validate: Yup.string().required("Campo requerido"),
    type: "number",
    label: "Numero de archivos permitidos",
  },
  {
    name: "StartDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de inicio",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "FinishDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de conclusión",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "Public",
    initial: false,
    validate: Yup.boolean().required("Campo requerido"),
    type: "checkbox",
    label: "Publicar el examen",
  },
  {
    name: "Description",
    initial: null,
    control: "editor",
    validate: null,
    label: "Descripción",
  },
];
export const InputsJsonVirtual = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Título",
  },
  {
    name: "StartDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de inicio",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "AditionalUrlResources",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Enlace de la clase virtual",
  },
  {
    name: "Public",
    initial: false,
    validate: Yup.boolean().required("Campo requerido"),
    type: "checkbox",
    label: "Publicar clase",
  },
  {
    name: "Description",
    initial: null,
    control: "editor",
    validate: null,
    label: "Descripción",
  },
];
export const InputsJsonCertificate = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del certificado",
  },
  {
    name: "StartDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de inicio",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "Public",
    initial: false,
    validate: Yup.boolean().required("Campo requerido"),
    type: "checkbox",
    label: "Publicar el examen",
  },
  {
    name: "Description",
    initial: null,
    control: "editor",
    validate: null,
    label: "Descripción",
  },
];
export const InputsEditProgram = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del programa",
  },
  {
    name: "CompletionTime",
    initial: 0,
    validate: Yup.string().required("Campo requerido"),
    type: "number",
    label: "Duración del curso en meses",
  },
  {
    name: "ComplexityLevel",
    initial: "",
    validate: null,
    control: "select",
    label: "Conplejidad",
    options: dropdownCompletionTime,
  },
  {
    name: "LanguageCode",
    initial: "",
    validate: null,
    control: "select",
    label: "Idioma",
    options: dropdownLanguage,
  },
  {
    name: "StartDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de inicio",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "FinishDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de conclusión",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "AddressedTo",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "textarea",
    label: "¿Para quien es este diplomado?",
  },
  {
    name: "CourseAchievements",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "textarea",
    label: "Lo que aprenderás",
  },
  {
    name: "Description",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "textarea",
    label: "Descripción",
  },
  {
    name: "WhatsappGroupUrl",
    initial: "",
    validate: null,
    type: "text",
    label: "Enlace de whatsApp",
  },
];
export const InputsEditCourse = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del programa",
  },
  {
    name: "StartDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de inicio",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  // {
  //   name: 'FinishDate',
  //   initial: null,
  //   validate: Yup.string().required('Campo requerido').nullable(),
  //   control: 'date',
  //   label: 'Fecha de conclusión',
  //   dateFormat: 'MMMM d, yyyy HH:mm',
  //   showTimeSelect: true,
  //   timeIntervals: 15,
  // },
  {
    name: "UpdateUser",
    initial: true,
    validate: Yup.boolean().required("Campo requerido"),
    type: "checkbox",
    label: "Modificar para estudiantes",
  },
  {
    name: "Description",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "textarea",
    label: "Descripción",
  },
];
export const InputsEditModule = [
  {
    name: "name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del programa",
  },
  {
    name: "startDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de inicio",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "finishDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de conclusión",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "CompletionTime",
    initial: 0,
    validate: Yup.string().required("Campo requerido"),
    type: "number",
    label: "Duración del módulo en meses",
  },
  {
    name: "description",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "textarea",
    label: "Descripción",
  },
  {
    name: "Goal",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "textarea",
    label: "Meta del módulo",
  },
];
export const InputsProgramManager = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del programa",
  },
  {
    name: "CategoryId",
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    label: "Seleccione una categoría",
    url: "/category",
    keyOption: "name",
    value: "id",
  },
  {
    name: "Description",
    initial: "",
    validate: Yup.string(),
    control: "textarea",
    label: "Descripción",
  },
  // {
  //   type: 'getSelect',
  //   label: 'Categoría:',
  //   name: 'CategoryId',
  //   url: '/category',
  //   urlKey: 'id',
  //   urlLabel: 'name',
  //   initial: 'Todas las categorías',
  // },
];

export const InputsCourseManagerTeacher = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del curso",
    titleStep: "Escribe el nombre del curso",
    descriptionStep: "Ej: Los 5 hábitos de la gente feliz",
    amount: 4,
  },
  {
    name: "CountModule",
    control: "select",
    initial: "1",
    validate: Yup.string().required("Campo requerido"),
    label: "Cantidad de Temas / Capitulos",
    options: optionsList,
    titleStep: "Selecciona la cantidad de temas",
    descriptionStep: "Ej: 5",
    amount: 4,
  },
  {
    name: "SubcategoryId",
    control: "getSelect",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    label: "Categoría del curso",
    url: "/category/4/children",
    keyOption: "name",
    value: "id",
    titleStep: "Escoge la categoría a la que pertenece",
    descriptionStep: "Ej: Desarrollo Personal",
    amount: 4,
  },
  {
    name: "PriceCourse",
    initial: "",
    validate: Yup.number()
      .typeError("Debe ser un número")
      .required("Campo requerido")
      .min(70, "El precio mínimo es 100")
      .max(1000, "El precio máximo es 1000"),
    type: "number",
    label: "Precio del curso (Bs.)",
    titleStep: "Escribe el precio del curso en bolivianos",
    descriptionStep: "Ej: 100",
    amount: 5,
  },
  {
    name: "Description",
    initial: "",
    validate: Yup.string(),
    control: "textarea",
    label: "Descripción",
    titleStep: "Describe brevemente en que consiste el curso y sus beneficios",
    amount: 6,
  },
  {
    name: "PhotoUrl",
    validate: Yup.string().required("Campo requerido"),
    control: "file",
    initial: null,
    //validate: null,
    label: "Selecciona una imagen",
    type: "image/*",
  },
];
export const InputsCreateParallel = [
  {
    name: "VersionName",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre del paralelo",
  },
];
export const InputsParallel = [
  {
    name: "Name",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre",
  },
  {
    name: "VersionName",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    type: "text",
    label: "Nombre de la version",
  },
  {
    name: "StartDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de inicio",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "FinishDate",
    initial: null,
    validate: Yup.string().required("Campo requerido").nullable(),
    control: "date",
    label: "Fecha de conclusión",
    dateFormat: "MMMM d, yyyy HH:mm",
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: "Amount",
    initial: "",
    validate: Yup.number().required("Campo requerido"),
    type: "number",
    label: "Precio",
  },
  {
    name: "Currency",
    initial: "",
    validate: Yup.string().required("Campo requerido"),
    control: "select",
    label: "Moneda",
    options: dropdownCurrency,
  },
  {
    name: "Description",
    initial: "",
    validate: Yup.string(),
    control: "textarea",
    label: "Descripción",
  },
];
export const fakeData = [
  {
    title: "PARA NIÑ@S DE 4 A 6 AÑOS",
    color: "#007E6C",
    main: {
      images: [
        "https://res.cloudinary.com/pataformacgi/image/upload/v1655472641/assets/img6_gpcldd_hl61gv.png",
        "https://res.cloudinary.com/pataformacgi/image/upload/v1655472641/assets/img5_cyxhyc_eqne8w.png",
      ],
      contentStyle: true,
      content: [
        {
          title: "¿POR QUÉ ES IMPORTANTE APRENDER INGLÉS?",
          color: "#007E6C",
          margin: 0,
          section:
            "Ayudará a familiarizarse más con el idioma, su capacidad de concentración y facilidad para aprender.",
        },
        {
          title: "¿CÓMO LO DEBE APRENDER?",
          color: "#007E6C",
          margin: 0,
          section: "Mediante juegos y actividades didácticas.",
        },
        {
          title: "SABÍAS QUE...",
          margin: 70,
          color: "#007E6C",
          section:
            "Aprenden mejor cuando son más pequeños y amplían su vocabulario.",
        },
      ],
    },
  },
  {
    title: "PARA NIÑ@S DE 7 A 9 AÑOS",
    color: "#ee7c38",
    main: {
      images: [
        "https://res.cloudinary.com/pataformacgi/image/upload/v1655472639/assets/img1_hbnzl5_y2wdfv.png",
        "https://res.cloudinary.com/pataformacgi/image/upload/v1655472639/assets/img2_cpvbhz_rh3jvj.png",
      ],
      contentStyle: true,
      content: [
        {
          title: "¿POR QUÉ ES IMPORTANTE APRENDER INGLÉS?",
          color: "#ee7c38",
          margin: 0,
          section:
            "La enseñanza resulta más efectiva, pues asimilan las palabras de una manera muy natural.",
        },
        {
          title: "SUS VENTAJAS",
          color: "#ee7c38",
          margin: 0,
          section:
            "El acceso al mundo permite conocer otras culturas, y el hablar otro idioma es una habilidad que permitirá mejorar su confianza.",
        },
        {
          title: "¿SABÍAS QUE...",
          color: "#ee7c38",
          margin: 70,
          section:
            "Las y los niños que aprenden inglés tienen mejores oportunidades laborales, tanto en Bolivia como en el extranjero.",
        },
      ],
    },
  },
  {
    title: "PARA NIÑ@S DE 10 A 14 AÑOS",
    color: "#6a3081",
    main: {
      images: [
        "https://res.cloudinary.com/pataformacgi/image/upload/v1655472641/assets/img3_y4zo6b_oqmsd0.png",
        "https://res.cloudinary.com/pataformacgi/image/upload/v1655472640/assets/img4_w4urnj_pmfmdf.png",
      ],
      contentStyle: false,
      content: [
        {
          title: "¿ES ÚTIL APRENDER INGLÉS?",
          color: "#6a3081",
          margin: 0,
          section:
            "Sí. Ayuda en su desarrollo, aprendizaje y proyectos a futuro.",
        },
        {
          title: "VENTAJAS EN LA ERA DIGITAL",
          color: "#6a3081",
          margin: 0,
          section: "La lengua principal de la era de internet es el inglés.",
        },
        {
          title: "¿SABÍAS QUE...",
          color: "#6a3081",
          margin: 70,
          section:
            "Desarrolla la creatividad, las habilidades multitareas y la capacidad de memoria.",
        },
      ],
    },
  },
];

export const errorsMessage = {
  wrong_usernameOrEmail: "Error de usuario o contraseña",
  invalid_password: "Contraseña incorrecta",
};
export const rolName = {
  student: "Estudiante",
  tutor: "Tutor",
  educator: "Educator",
  admin: "Administrador",
  sales: "Ventas",
  manager: "Gerente",
  socialnetwork: "Redes Sociales",
  humanResources: "Recursos humanos",
  affiliate: "Afiliado",
};

export const gender = {
  male: "Hombre",
  female: "Mujer",
};
export const rol = {
  afiliado: "Afiliado",
  creador: "Creador",
};

import { H3 } from "../../components/text";
import { LoadingScreen } from "../../components/common";
import { withRouter } from "react-router-dom";
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom'

const WaitConfirmEmail = (props) => {
  const params = new URLSearchParams(props.location.search);

  const ConfirmationForm = () => {
    const [confirmationCode, setConfirmationCode] = useState(Array(5).fill(''));
    const inputsRef = useRef([]);

    const handleInputChange = (index, event) => {
        const newConfirmationCode = [...confirmationCode];
        newConfirmationCode[index] = event.target.value;
        setConfirmationCode(newConfirmationCode);

        if (event.target.value && index < 4) {
            inputsRef.current[index + 1].focus();
        }
    };
    const handleSubmit = (event) => {
      event.preventDefault();
      const code = confirmationCode.join('');
      window.location.href = `/confirm-email/${code}`;
  };

return (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}}>
    <form onSubmit={handleSubmit}>
      <div style={{fontSize:"20px"}}><label htmlFor="confirmationCode">Código de Confirmación:</label><br /></div>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',            
            maxWidth: '200px',
            marginBottom: '20px'
        }}>
            {confirmationCode.map((_, i) => (
                <input
                    key={i}
                    type="text"
                    id={`confirmationCode${i + 1}`}
                    name={`confirmationCode${i + 1}`}
                    maxLength="1"
                    value={confirmationCode[i]}
                    onChange={(event) => handleInputChange(i, event)}
                    ref={el => inputsRef.current[i] = el}
                    style={{
                        width: '20px',
                        padding: '10px',
                        textAlign: 'center',
                        border: '2px solid #FFA500',
                        borderRadius: '4px',
                        marginRight: '5px',                                                                   
                    }}
                />
            ))}
        </div>
        <input
            type="submit"
            value="Enviar"
            style={{
                backgroundColor: '#FFA500',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                textAlign: 'center',
                textDecoration: 'none',
                display: 'inline-block',
                fontSize: '16px',
                margin: '4px 2px',
                cursor: 'pointer',
                borderRadius: '4px'
            }}
        />
    </form>
</div>
  
);
};



  return (
    <>       
      <LoadingScreen>
        <H3 margin="0 0 20px 0">Esperando Confirmación de Email</H3>
        <h1 style={{ margin: "0" }}>
          Bienvenid@ por favor ingresa a tu correo para ser parte de midas
        </h1>
        <p style={{ fontSize: "1.4rem" }}>
          Se le envió un correo de confirmación al correo:
        </p>
        <h3 style={{ fontSize: "2rem" }}>{params.get("email")}</h3>
        <ConfirmationForm/>
        
      </LoadingScreen>
    </>
  );
};

export default withRouter(WaitConfirmEmail);

import './style/ButtonModalS.css'
import { avatarMale, avatarFemale } from '../../utils/Constant'
import { useAxiosAuth } from '../../hooks'
import { processUrlImage } from '../../utils/ProcessData'
import { putEmptyAction } from '../../services/action/ActionAuthorization'
import { Link, withRouter } from 'react-router-dom'
import {
  validateImg,
  validateArray,
  validateStatus,
} from '../../utils/Validation'
import { H3 } from '../text'
import { useState, useRef, useEffect } from 'react'

function ButtonModalS(props) {
  const { sesionUser } = props
  const [activate, setactivate] = useState(false)
  function cerrar() {
    setactivate(!activate)
    props.closeSession()
    localStorage.removeItem('token_seguridad')
    localStorage.removeItem('id')
  }
  const url = processUrlImage(
    sesionUser
      ? validateImg(sesionUser.user.photoUrl)
      : sesionUser.user.gender === 'male'
      ? avatarMale
      : avatarFemale,
    40,
  )
  const BtnEnter = (
    <div className="">
      <Link
        to={{
          pathname: '/auth/login',
          state: {
            from: props.location,
          },
        }}
        className="ButtonModalS"
      >
        <i className="fas fa-user-circle"></i>
      </Link>
    </div>
  )
  const menuUser = (
    <div className="userPhoto">
      <div
        onClick={() => setactivate(!activate)}
        className="userPhoto__container"
      >
        <div
          className="userPhoto__img"
          style={{
            backgroundImage: `url(${
              sesionUser && sesionUser.user && sesionUser.user.photoUrl
                ? sesionUser.user.photoUrl
                : sesionUser.user.gender === 'female'
                ? avatarFemale
                : avatarMale
            })`,
          }}
        ></div>
        <H3
          color="white"
          fontSize="1.4rem"
          fontWeight="500"
          margin="0"
          padding="2px 0"
        >
          {sesionUser.user.firstName
            ? sesionUser.user.firstName
            : sesionUser.user.username}
          <br />
          {sesionUser.user.lastName
            ? sesionUser.user.lastName
            : sesionUser.user.email}
        </H3>
      </div>
      {activate && (
        <ul className="userPhoto__ul">
          <li className="userPhoto__li">
            <Link
              className="userPhoto__link"
              to="/Account"
              onClick={() => setactivate(!activate)}
            >
              <span>Mi Perfil</span> <i className="far fa-user"></i>
            </Link>
          </li>
          <li className="userPhoto__li">
            <Link className="userPhoto__link" onClick={cerrar} to="/">
              <span>Cerrar Sesion</span> <i className="fas fa-door-open"></i>
            </Link>
          </li>
        </ul>
      )}
    </div>
  )

  return (
    <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
      {/* {sesionUser?.authenticate && <NotificationContain />} */}
      <Link to="/shopping-cart" className="ButtonModalS">
        <i className="fas fa-shopping-cart"></i>
      </Link>
      {sesionUser && sesionUser.authenticate ? menuUser : BtnEnter}
    </div>
  )
}

const NotificationContain = () => {
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/notification?userId=${window.localStorage.getItem('id')}`,
  })
  if (loading) {
    return null
  } else {
    return (
      <Notification response={response} loading={loading} reload={reload} />
    )
  }
}

const Notification = (props) => {
  const { response = [], reload } = props
  const [modal, setmodal] = useState(false)
  const [notifications, setNotifications] = useState(response)
  const latestNotify = useRef(null)
  latestNotify.current = notifications

  useEffect(() => {
    setNotifications(response)
  }, [response])

  // useEffect(() => {
  //   const connection = new HubConnectionBuilder()
  //     .withUrl(`${config.fetchURL}/notificationHub`)
  //     .withAutomaticReconnect()
  //     .build()
  //   const connect = connection.connection
  //   connection
  //     .start()
  //     .then(() => {
  //       connection.on(
  //         'ReceiveMessage',
  //         (userId, type, isView, idReference, idNotification) => {
  //           const updatedChat = [...latestNotify.current]
  //           updatedChat.push({
  //             idNotification: userId,
  //             idReference: idReference,
  //             isView: isView,
  //             type: type,
  //             userId: idNotification,
  //           })
  //           setNotifications(updatedChat)
  //         },
  //       )
  //       postEmptyAction(
  //         `/auth/SocketConnection/${connect.connectionId}`,
  //       ).then(() => {})
  //     })
  //     .catch((e) => console.log('Connection failed: ', e))
  // }, [])

  async function handleOnClick(idNotification) {
    await putEmptyAction(`/notification/${idNotification}`).then((resp) => {
      if (validateStatus(resp.status)) {
        reload()
      }
      setmodal(!modal)
    })
  }
  return (
    <div className="Notification">
      <div
        onClick={() => setmodal(!modal)}
        className="Notification__icon ButtonModalS"
      >
        <i className="fas fa-bell"></i>
        {validateArray(notifications) && (
          <H3 color="var(--white)" fontSize="1.1rem">
            {notifications.length}
          </H3>
        )}
      </div>
      {modal && (
        <>
          <div
            onClick={() => setmodal(!modal)}
            className="Notification__closeModal"
          ></div>
          <div className="Notification__container">
            <H3
              margin="10px 10px 5px"
              textAlign="center"
              color="var(--gray)"
              fontWeight="600"
            >
              Notificaciones
            </H3>
            <div className="Notification__main">
              {validateArray(notifications) ? (
                notifications.map((notify) =>
                  notify.type === 'newContact' ? (
                    <Link
                      key={notify.idNotification}
                      className="Notification__notify"
                      onClick={() => handleOnClick(notify.idNotification)}
                      // to={`/contacts?contactId=${notify.idReference}`}
                      to={`/resume-contact?navigation=contacts&contactId=${notify.idReference}`}
                    >
                      <H3
                        color="var(--gray)"
                        fontSize="1.4rem"
                        fontWeight="500"
                        margin="10px"
                      >
                        <i className="fas fa-user-plus"></i> Tienes un nuevo
                        contacto
                      </H3>
                    </Link>
                  ) : (
                    <></>
                  ),
                )
              ) : (
                <H3
                  color="var(--gray)"
                  fontSize="1.4rem"
                  fontWeight="500"
                  margin="10px"
                >
                  No tienes notificaciones
                </H3>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default withRouter(ButtonModalS)

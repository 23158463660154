import './style/ContentQuiz.css'
import { useState } from 'react'
import { sendMail } from './SendMail'
import { ButtonIcon } from '../buttons'
import { CardMainOptions } from '../card'
import { convertD } from '../../utils/ConvertDate'
import { validateStatus } from '../../utils/Validation'
import { putEmptyAction } from '../../services/action/ActionAuthorization'
import { Link, withRouter } from 'react-router-dom'
import { IconText, H2, H4 } from '../../components/text'
const ContentQuizRol = (props) => {
  const {
    contentData: {
      id,
      name,
      timeLimit,
      minimumGrade,
      startDatePerUser,
      finishDatePerUser,
      attemps,
      unlimited,
    },
    overt,
    ChangePublic,
    PutLesson,
    match: {
      params: { cid, mid, pid },
    },
    history,
    changeModal,
    openSnackbar,
    deleteLesson,
  } = props
  const [limit, setlimit] = useState(unlimited)

  function ChangeLimit() {
    putEmptyAction(`/lesson/${id}/limit`).then((res) => {
      if (validateStatus(res.status)) {
        setlimit(!limit)
        openSnackbar('Se guardaron los cambios', true, true)
      } else {
        openSnackbar('No se guardaron los cambios', true, false)
      }
    })
  }

  const copyCurrentLink = () => {
    const url = `/program${
      pid ? '/' + pid : ''
    }/course/${cid}/module/${mid}/quiz/${id}/`
    navigator.clipboard.writeText(`${window.location.origin}${url}`)
    openSnackbar('Enlace copiado', true, true)
  }
  const op = (
    <>
      <ButtonIcon
        onClick={() =>
          history.push(
            `/program${
              pid ? '/' + pid : ''
            }/course/${cid}/module/${mid}/quiz/${id}/ratings`,
          )
        }
        content="Ver notas del examen"
        icon="bars"
      />
      {/* <ButtonIcon
        content={limit ? 'Con fecha limite' : 'Sin fecha limite'}
        onClick={ChangeLimit}
        icon={limit ? 'calendar-times' : 'calendar-check'}
      /> */}
      <ButtonIcon
        content={overt ? 'Anular publicación' : 'Publicar'}
        onClick={ChangePublic}
        icon={overt ? 'eye' : 'eye-slash'}
      />
      {/* {overt && (
        <ButtonIcon
          onClick={() => sendMail(id, changeModal, openSnackbar)}
          content="Enviar correo"
          icon="envelope"
        />
      )} */}
      <ButtonIcon
        onClick={() =>
          history.push(
            `/program${
              pid ? '/' + pid : ''
            }/course/${cid}/module/${mid}/quiz/${id}/edit`,
          )
        }
        content="Editar preguntas"
        icon="list-alt"
      />
      <ButtonIcon onClick={PutLesson} content="Editar lección" icon="edit" />
      {/* <ButtonIcon
        onClick={copyCurrentLink}
        content="Copiar enlace"
        icon="copy"
      /> */}
      <ButtonIcon
        onClick={() => deleteLesson(id, name)}
        content="Eliminar lección"
        icon="trash"
        style={{ color: 'var(--red)' }}
      />
    </>
  )
  return (
    <CardMainOptions options={op}>
      <Link
        to={`/program${
          pid ? '/' + pid : ''
        }/course/${cid}/module/${mid}/quiz/${id}/ratings`}
        className="ContentQuizRol"
      >
        <div className="ContentHomework__head">
          <H2>{name}</H2>
          <H4
            margin="0"
            fontSize="1.6rem"
            textAlign="end"
            border="1px solid var(--gray)"
          >
            Examen
          </H4>
        </div>
        {/* <IconText icon="calendar-day">
          {convertD(startDatePerUser, 'LLLL')} (Fecha Inicio)
        </IconText>
        {limit ? (
          <IconText icon="calendar-times">No tiene fecha limite</IconText>
        ) : (
          <IconText icon="calendar-times">
            {convertD(finishDatePerUser, 'LLLL')} (Fecha Limite)
          </IconText>
        )} */}
        <IconText icon="hand-holding-heart">
          {attemps} (Nro. de intentos)
        </IconText>
        <IconText icon="hourglass-end">{timeLimit} (Tiempo Limite)</IconText>
        <IconText icon="medal">{minimumGrade} (Nota de aprobación)</IconText>
      </Link>
    </CardMainOptions>
  )
}
export default withRouter(ContentQuizRol)

import './Rol.css'
import { connect } from 'react-redux'
import {
  changeLayout,
  startSession,
  openSnackbar,
  changeSession,
} from '../../store/actions'
import { useEffect } from 'react'
import { postEmptyAction } from '../../services/action/ActionAuthorization'
import {
  validateStatus,
  validateInstitution,
  validateArray,
} from '../../utils/Validation'
import { Link } from 'react-router-dom'
import { H2 } from '../../components/text'

const Rol = (props) => {
  const { changeLayout, openSnackbar, changeSession } = props
  const { institutions } = props.sesionUser
  const isEducator = validateInstitution(institutions, 'educator')
  const isAffiliate = validateInstitution(institutions, 'affiliate')

  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  async function handleChangeRol(rol) {
    await postEmptyAction(`/Rol/register/${rol}`).then((response) => {
      if (validateStatus(response.status)) {
        RenderToken(window.localStorage.getItem('id'))
      } else {
        openSnackbar('No se pudo registrar', true, false)
      }
    })
  }

  const RenderToken = async (user) => {
    postEmptyAction(`/auth/login/replacedToken/${user}`).then((resp) => {
      if (validateStatus(resp.status)) {
        const { institutions, courses, user, token, father } = resp.data
        window.localStorage.setItem('id', user.id)
        window.localStorage.setItem('token_seguridad', token)
        changeSession({ ...user, father: father }, courses, institutions, true)
        openSnackbar('registro exitoso', true, true)
      } else {
        openSnackbar('No se pudo guardar', true, false)
      }
    })
  }

  return (
    <div className="Rol__container">
      <div className="Rol__background">
        <section className="Rol__section">
          <H2
            fontSize="4rem"
            color="white"
            display="flex"
            alignItems="center"
            margin="0"
            gap="10px"
          >
            <i className="fa fa-rocket"></i>
            <p>
              Bienvenido a <span style={{ color: '#f0821c' }}>Midas</span>
            </p>
          </H2>
          <h3 className="gray-color">
            ¿Cuál es tu Objetivo Principal con Midas?
          </h3>
          <div className="Rol__btns">
            {/* <Link to={'/welcome/student'} className="btn" onClick={rolStudent}>
              + Estudiante
            </Link> */}
            {!validateArray(isEducator) ? (
              <Link
                to={'/welcome/educator'}
                className="Rol__btn"
                onClick={() => handleChangeRol(2)}
              >
                + Creador
              </Link>
            ) : null}
            {!validateArray(isAffiliate) ? (
              <Link
                to={'/welcome/afiliate'}
                className="Rol__btn"
                onClick={() => handleChangeRol(9)}
              >
                + Afiliado
              </Link>
            ) : null}
          </div>
        </section>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
  openSnackbar,
  startSession,
  changeSession,
})(Rol)

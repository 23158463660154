import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Google } from '../buttons'
import Auth from '../../routes/Auth'
import './style/ModalLog.css'
const ModalLog = ({ redirect }) => {
  const { t } = useTranslation('formLR')
  const history = useHistory()
  const pathName = history.location.pathname
  return (
    <React.Fragment>
      {pathName === '/Bienvenid@ por favor ingresa a tu correo para ser parte de midas' ? null : (
        <center>
          <img
            style={{ height: '80px' }}
            src="https://res.cloudinary.com/pataformacgi/image/upload/v1675126147/assets/midas_logo_bpc76l.png"
            alt=""
          />
        </center>
      )}
      <br />
      <div className="LoginForm__title">
        <NavLink
          exact
          to={{
            pathname: `${
              pathName.includes('/auth/test-vocacional')
                ? '/auth/test-vocacional-login'
                : '/auth/login'
            }`,
            state: {
              from: redirect ? redirect.from : '/',
            },
          }}
          className="title__option"
          activeClassName="LoginForm__titleAc"
        >
          {t('lrIniciar')}
        </NavLink>
        <span className="LoginForm__titleSpan"> </span>
        <NavLink
          exact
          to={{
            pathname: `${
              pathName.includes('/auth/test-vocacional')
                ? '/auth/test-vocacional-register'
                : '/auth/register'
            }`,
            state: {
              from: redirect ? redirect.from : '/',
            },
          }}
          className="title__option"
          activeClassName="LoginForm__titleAc"
        >
          / {t('lrRegistro')}
        </NavLink>
      </div>
      <div className="LoginForm__form">
        <Auth />
      </div>
      <br />
      <div className="LoginForm__fg">
        {/* <div className="Login_separated"></div> */}
        <div className="LoginForm__external">
          <div>
            <span className="form__span">O inicia con:</span>
          </div>
          {/* <div>
            <Facebook />
          </div> */}
          <div>
            <Google />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ModalLog

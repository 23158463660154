import { useState } from 'react'
import { postAction } from '../../services/action/Action'
import { Contact } from '../../utils/FormParameters'
import { validateStatus } from '../../utils/Validation'
import { GlobalForm } from '../form'
import { Section } from '../text'
import H4 from '../text/H4'
import { parsePhoneNumber } from 'react-phone-number-input'
const ContactModal = ({ openSnackbar, onClose }) => {
  const [load, setload] = useState(true)

  async function handleOnSubmit(values) {
    if (values.Phone !== undefined) {
      const codeNumber = parsePhoneNumber(values.Phone)
      const data = {
        ...values,
        Country: codeNumber.country,
        CountryCode: codeNumber.countryCallingCode,
        NationalNumber: codeNumber.nationalNumber,
      }
      setload(false)
      await postAction(`/contact/external`, data).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Datos enviados', true, true)
          onClose()
        } else {
          openSnackbar('Error al enviar mensaje', true, false)
        }
      })
    } else {
      setload(true)
      openSnackbar('Datos del telefono incorrecto', true)
    }
  }

  return (
    <div style={{ textAlign: 'center' }} className="Contact__container_main">
      
      <H4
        color="white"
        fontSize="1.8rem"
        textAlign="center"
        fontWeight="bold"
        margin="10px 0"
      >
        Tenemos una oferta especial para ti
      </H4>
      <Section
        textAlign="center"
        fontWeight="400"
        margin="0 0 15px 0"
        fontSize="1.5rem"
        color="var(--white)"
      >
        Un asesor educativo te espera con un gran descuento para acceder a
        nuestras suscripciones
      </Section>
      <div style={{ width: '300px', margin: '0 auto' }}>
        <GlobalForm
          InputsJson={Contact}
          textBtn="Enviar"
          data={{}}
          load={load}
          onSubmit={handleOnSubmit}
          colorBtn="var(--green)"
          widthBtn="60%"
          labelColor="var(--white)"
        />
      </div>
    </div>
  )
}

export default ContactModal

import { NavLink } from "react-router-dom";
import "./style/LinkNav.css";
const LinkNav = (props) => {
  const { to, icon, children } = props;
  return (
    <NavLink
      to={to}
      exact
      className={({ isActive, isPending }) =>
        isPending ? "LinkNav pending" : isActive ? "LinkNav active" : "LinkNav"
      }
    >
      <i className={`fas fa-${icon} nav__linkIcon`}></i>
      <span className="nav__linkText">{children}</span>
    </NavLink>
  );
};

export default LinkNav;

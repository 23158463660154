import { useEffect, useState } from "react";
import "./CourseInfo.css";
import { connect } from "react-redux";
import { changeLayout, openSnackbar } from "../../../../store/actions";
import { getAction } from "../../../../services/action/ActionAuthorization";
import { useTranslation } from "react-i18next";
import { LoadingScreen } from "../../../../components/common";
import { H2, H3, H4, Section } from "../../../../components/text";
import { DefaultBtn } from "../../../../components/buttons";
import { BoxFlex } from "../../../../components/boxes";
import { ImgDefault } from "../../../../components/image";
import { GoBackFlex } from "../../../../components/navigation";
import { UseOnSubmit } from "../../../../hooks/useOnSubmit";
import {
  validateArray,
  validateInstitution,
} from "../../../../utils/Validation";
import { ContactHeader } from "../../../../containers/catalogue";
import { FooterComponent } from "../../../Home/Home";
import {
  useActionParam,
  useAxios,
  useSize,
  useAxiosAuth,
} from "../../../../hooks";
import { _config } from "gsap/gsap-core";

function CourseInfo(props) {
  const { match, changeLayout, openSnackbar, membership, history } = props;
  const { t } = useTranslation("CourseInfo");
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const id_afiliado = params.get("afiliado");
  const id = match.params.id;
  const { institutions } = props.sesionUser;
  const isAffiliate = validateInstitution(institutions, "affiliate");
  const uId = window.localStorage.getItem("id");
  const [target, currentSize] = useSize();
  const [shopping, setshopping] = useState([]);
  const { response: course, loading } = useAxios({
    method: "GET",
    url: uId ? `/Course/${id}/User/${uId}` : `/Course/${id}`,
  });

  const {} = useAxios({
    method: "PUT",
    url: `/affiliate/view/${id_afiliado}`,
  });
  const { dataRequest } = useActionParam({
    action: getAction,
    param: uId ? `/users/${uId}/preregister` : null,
  });
  const { response, reload } = useAxiosAuth({
    method: "GET",
    url: window.localStorage.getItem("id") ? "/Shoppingcart" : "",
  });

  useEffect(() => {
    setshopping(getShopping());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, window.localStorage.getItem("shopping")]);

  function getShopping() {
    if (window.localStorage.getItem("id")) {
      return response ? response : [];
    } else {
      const storageShop = window.localStorage.getItem("shopping")
        ? JSON.parse(window.localStorage.getItem("shopping"))
        : [] || [];
      return storageShop;
    }
  }
  function reloadShopping() {
    reload();
    setshopping(getShopping());
  }
  useEffect(() => {
    changeLayout("ContentOnly");
  }, [changeLayout]);

  const filterShopping = validateArray(
    shopping.filter((f) => f.programManagerId === parseInt(id))
  );

  async function handleShopping(course) {
    if (window.localStorage.getItem("id")) {
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: "Shoppingcart",
          data: {
            ProgramManagerId: id,
            CodeAffiliate: id_afiliado,
          },
        },
        openSnackbar: openSnackbar,
        textSuccess: "Se adiciono al carrito con exito",
        textWrong: "No se pudo adicionar",
        functionSuccess: () => {
          history.push("/shopping-cart");
        },
        functionWrong: () => {},
      });
    } else {
      window.localStorage.setItem(
        "shopping",
        JSON.stringify([
          ...shopping,
          {
            programManagerId: id,
            name: course.name,
            codeAffiliate: id_afiliado,
          },
        ])
      );
      openSnackbar("Se adiciono al carrito con exito", true, true);
      history.push("/shopping-cart");
    }
    reloadShopping();
  }
  async function handleRemove(course, filterShopping) {
    if (window.localStorage.getItem("id")) {
      await UseOnSubmit({
        axiosParams: {
          method: "DELETE",
          url: `/Shoppingcart/${filterShopping[0].id}`,
        },
        openSnackbar: openSnackbar,
        textSuccess: "Se elimino del carrito",
        textWrong: "No se pudo eliminar",
      });
    } else {
      const shoppingList = shopping.filter(
        (f) => f.programManagerId !== course.programManagerId
      );
      window.localStorage.setItem(
        "shopping",
        JSON.stringify([...shoppingList])
      );
      openSnackbar("Se elimino del carrito", true, true);
    }
    reloadShopping();
  }
  const onSubmitAfiliado = async (id) => {
    try {
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: `/affiliate/programManager/${id}`,
          data: {
            UrlPage:
              _config.domainURL === "http://localhost:3000"
                ? `http://localhost:3000/graduates/${id}/course`
                : `https://midas.li/graduates/${id}/course`,
          },
        },
        openSnackbar: openSnackbar,
        textSuccess: "Se affilio con exito",
        textWrong: "No se pudo affiliar intentelo de nuevo",
        functionSuccess: () => {
          history.push("/affiliate");
        },
        functionWrong: () => {},
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (!loading) {
    return (
      <div
        className="CourseInfo"
        style={{
          marginTop: match?.params?.type === "single-course" ? "10px" : "10px",
        }}
      >
        <div className="CourseInfo__header">
          <div ref={target} className="CourseInfo__Htitle">
            <BoxFlex justify="space-between">
              <GoBackFlex location={history.location} />
              <div className="CourseInfo__btn">
                {membership && membership.status === "completed" ? null : (
                  <DefaultBtn
                    onClick={() =>
                      filterShopping
                        ? handleRemove(
                            course,
                            shopping.filter(
                              (f) => f.programManagerId === parseInt(id)
                            )
                          )
                        : handleShopping(course)
                    }
                  >
                    <i
                      className={`fas fa-${
                        filterShopping ? "cart-arrow-down" : "cart-plus"
                      }`}
                    ></i>{" "}
                    {filterShopping
                      ? "Eliminar del carrito"
                      : match?.params?.type === "single-course"
                      ? "Comprar"
                      : membership !== null
                      ? "Tomar el curso"
                      : "Añadir al carrito"}
                  </DefaultBtn>
                )}
                {validateArray(isAffiliate) ? (
                  course.affiliateStatus ? (
                    <H3
                      fontWeight="500"
                      color="var(--purple)"
                      fontSize="1.5rem"
                    >
                      Afiliado
                    </H3>
                  ) : validateArray(isAffiliate) && course.affiliateStatus === false ? (
                    <DefaultBtn onClick={() => onSubmitAfiliado(id)}>
                      Afiliarse al Curso
                    </DefaultBtn>
                  ) : null
                ) : validateArray(isAffiliate) &&  uId ? (
                  <H3 fontWeight="500" color="var(--purple)" fontSize="1.5rem">
                    Afiliado
                  </H3>
                ) : null}
                <ContactHeader
                  id={id}
                  course={course}
                  history={history}
                  membership={membership}
                  dataRequest={dataRequest}
                  type={match?.params?.type ? match.params.type : null}
                />
              </div>
            </BoxFlex>
            <br />
            <div
              style={{
                background: match?.params?.type ? "#389f8aa2" : "#e3ad2da2",
              }}
              className="CourseInfo__Hhead"
            >
              {course.photoUrl ? (
                <img
                  className="CourseInfo__Himg"
                  src={course.photoUrl}
                  alt="course"
                />
              ) : (
                <img
                  className="CourseInfo__Himg"
                  src="https://res.cloudinary.com/dzxmzxvbr/image/upload/v1607986593/diplomados/fotos/06GestionTributaria_hadppb.png"
                  alt="course"
                />
              )}
              <H3
                position="relative"
                color="var(--white)"
                margin="15px 15px 0"
                fontSize="2rem"
                textTransform="uppercase"
              >
                {course.name}
              </H3>
            </div>
          </div>

          <div className="CourseInfo__Hcontact">
            {match?.params?.type === "single-course" && (
              <>
                {course.paymentPlan &&
                validateArray(course.paymentPlan.prices) ? (
                  <div
                    style={{
                      background: "var(--green)",
                      padding: "10px 0 8px",
                      borderRadius: "10px",
                    }}
                  >
                    <H3
                      margin="0"
                      color="var(--white)"
                      fontSize="3rem"
                      textAlign="center"
                    >
                      Inversión: {course.paymentPlan.prices[0].currency}{" "}
                      {course.paymentPlan.prices[0].amount}
                    </H3>
                  </div>
                ) : null}
              </>
            )}
            <br />
            <br />
            <div className="CourseInfo__information">
              {course?.description && (
                <ListCard list={course.description}>
                  {match?.params?.type
                    ? "DESCRIPCIÓN DEL CURSO"
                    : t("descriptionDiploma")}
                </ListCard>
              )}
              {course?.courseAchievements && (
                <ListCard list={course.courseAchievements}>
                  {t("learn")}
                </ListCard>
              )}
              {course?.addressedTo && (
                <ListCard list={course.addressedTo}>
                  {match?.params?.type
                    ? "¿PARA QUIEN ES ESTE CURSO?"
                    : t("whoIsDiploma")}
                </ListCard>
              )}
            </div>
            <div className="CourseInfo__Content">
              {course && validateArray(course.courses) ? (
                <>
                  <H2 color="var(--gray)">{t("diplomaContent")}</H2>
                  {course.courses
                    .sort((a, b) => a.order - b.order)
                    .map(function (l) {
                      return (
                        <CardModule
                          key={l.id}
                          modules={l.modules}
                          description={l.description}
                        >
                          {l.name}
                        </CardModule>
                      );
                    })}
                </>
              ) : null}
            </div>
            <div className="CourseInfo__Content">
              {course && validateArray(course.modules) ? (
                <>
                  <H2 color="var(--gray)">
                    {match?.params?.type
                      ? "Contenido del curso"
                      : t("diplomaContent")}
                  </H2>
                  {course.modules
                    .sort((a, b) => a.order - b.order)
                    .map(function (l) {
                      return (
                        <CardModule
                          key={l.id}
                          modules={l.lessons}
                          color={
                            match?.params?.type ? "#389f8aa2" : "var(--yellow)"
                          }
                          description={l.description}
                        >
                          {l.name}
                        </CardModule>
                      );
                    })}
                </>
              ) : null}
            </div>
          </div>
        </div>
        {validateArray(course.teachers) && (
          <div className="CourseInfo__Teachers">
            <H3 fontSize="2rem" textTransform="uppercase">
              Docente
            </H3>
            <div className="CourseInfo__listTeachers">
              {course.teachers.map((teacher) => (
                <div className="CourseInfo__cadTeachers">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <>
                      <div
                        style={{
                          backgroundImage: `url("${
                            teacher.photoUrl
                              ? teacher.photoUrl
                              : "https://res.cloudinary.com/ahsoizdev/image/upload/v1691114112/pngwing.com_ld8qca.png"
                          }")`,
                          backgroundSize: "cover",
                          borderRadius: "50%",
                          width: "150px",
                          minWidth: "150px",
                          height: "150px",
                          boxShadow: "0 0 5px rgba(0,0,0,0.1)",
                        }}
                      ></div>

                      <div className="">
                        <H3 textTransform="uppercase" margin="0">
                          {teacher.name} {teacher.lastName}
                        </H3>
                        <H3 margin="0" fontWeight="500">
                          {teacher.profession}
                        </H3>
                        {teacher.description && (
                          <>
                            <br />
                            <H3 margin="0" fontWeight="500">
                              {teacher.description}
                            </H3>
                          </>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* <FooterComponent /> */}
      </div>
    );
  } else {
    return <LoadingScreen />;
  }
}

const mapStateToProps = (state) => {
  return {
    membership: state.membership,
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, { changeLayout, openSnackbar })(
  CourseInfo
);

const ListCard = (props) => {
  return (
    <div className="ListCard">
      <H4 color="var(--purple)" fontWeight="500" margin="0" fontSize="1.8rem">
        {props.children}
      </H4>
      <hr />
      <div style={{ margin: "8px 1%" }}>
        <Section>{props.list}</Section>
      </div>
    </div>
  );
};

function CardModule({
  children,
  description,
  modules,
  color = "var(--yellow)",
}) {
  const [view, setView] = useState(false);
  return (
    <div className="CardModule">
      <div
        style={{ backgroundColor: color }}
        className="CardModule__card"
        onClick={() => {
          if (modules) {
            setView(!view);
          }
        }}
      >
        <div className="CardModule__title">
          <H4 margin="0" fontSize="1.6rem" color="var(--white)">
            {children}
          </H4>
          {modules && (
            <i className={`fas fa-angle-${view ? "down" : "right"}`}></i>
          )}
        </div>
        {view ? <Section>{description}</Section> : null}
      </div>
      {view ? (
        <div className="CardModule__list">
          {validateArray(modules)
            ? modules
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((module) => (
                  <div key={module.id} className="CardLesson">
                    <H4 margin="0" fontSize="1.4rem" color="var(--black)">
                      {module.name}
                    </H4>
                  </div>
                ))
            : null}
        </div>
      ) : null}
    </div>
  );
}

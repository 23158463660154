import './SubCategories.css'
import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import { Search } from '../../../components/inputs'
import { H2, H3 } from '../../../components/text'
import { openSnackbar, changeModal } from '../../../store/actions'
import { useAxios, useAxiosAuth } from '../../../hooks'
import { validateArray } from '../../../utils/Validation'
import { LoadCircle } from '../../../components/animation'
import { ListCourses } from '../../../containers/catalogue'
import { useSteps } from '../../../hooks'
import { StepTips } from '../../../components/boxes'
import { ButtonFloating } from '../../../components/buttons'
import CardCategories from '../../../components/card/CardCategories'

const SubCategories = (props) => {
  const {
    location,
    match: { params },
    changeModal,
  } = props
  const amount = 2
  const { step, nextStep } = useSteps({
    name: 'create_course',
    amount: amount,
    changeModal: changeModal,
    welcome: (
      <>
        <H2 color="var(--gray)">Te damos la bienvenida a midas.li</H2>
        <H3>
          Estas en la vista como afiliado podrás visualizar los cursos
          disponibles. A través de nuestra plataforma podrás generar ingresos
          como afiliado ofreciendo nuestros productos digitales (cursos,
          seminarios, talleres, etc) en redes sociales.
        </H3>
      </>
    ),
  })

  const [url, seturl] = useState('')
  const [shopping, setshopping] = useState([])
  const searchUrl = new URLSearchParams(location.search)
  const [category, setcategory] = useState('' || searchUrl.get('category'))

  const { response: shopp, reload } = useAxiosAuth({
    method: 'GET',
    url: window.localStorage.getItem('id') ? '/Shoppingcart' : '',
  })

  function reloadShopping() {
    reload()
    setshopping(getShopping())
  }

  useEffect(() => {
    setshopping(getShopping())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopp])

  function getShopping() {
    if (window.localStorage.getItem('id')) {
      return shopp ? shopp : []
    } else {
      const storageShop = window.localStorage.getItem('shopping')
        ? JSON.parse(window.localStorage.getItem('shopping'))
        : [] || []
      return storageShop
    }
  }

  const { response, loading } = useAxios({
    method: 'GET',
    url: `/category/${params.id}/children`,
  })

  return loading ? (
    <LoadCircle />
  ) : (
    <>
      <StepTips
        step={step}
        nextStep={nextStep}
        stepNumber={2}
        title="Filtros de búsqueda"
        description="Podrás utilizar estos filtros para buscar cursos tanto por categoría como por nombre."
        amount={amount}
        right={false}
      >
        <div className="SubCategories__list">
          <HeaderSearch
            {...props}
            seturl={seturl}
            response={response}
            category={category}
            setcategory={setcategory}
          />
        </div>
      </StepTips>
      <br />
      {category ? (
        <ListCourses
          category={category}
          shopping={shopping}
          reloadShopping={reloadShopping}
          url={url}
          {...props}
        />
      ) : (
        <ListCourses
          category={params.id}
          shopping={shopping}
          reloadShopping={reloadShopping}
          url={url}
          {...props}
        />
      )}
      <ButtonFloating />
    </>
  )
}

const HeaderSearch = (props) => {
  const {
    seturl,
    history,
    response,
    category,
    setcategory,
    match: { params },
  } = props

  function handleSelect(setFunction, e) {
    const { value } = e.target
    history.push(`/catalogue/${params.url}/${params.id}?category=${value}`)
    setFunction(value)
  }

  function handleCardClick(categoryId, categoryUrl) {
    history.push(`/catalogue/${params.url}/${params.id}?category=${categoryId}`);
    setcategory(categoryId);
  }

  // function handleCardClick(categoryId, categoryUrl) {
  //   console.log(`Clic en la subcategoría ${categoryId}`);
  //   history.push(`/catalogue/${params.url}/${params.id}?category=${categoryId}`);
  //   setcategory(categoryId);
  // }

  return (
    <>
      <h1 style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>Lista de los cursos</h1>

      {/* {validateArray(response) && (
        <select
          className="SubCategories__select"
          onChange={(e) => handleSelect(setcategory, e)}
          value={category}
        >
          <option value="">Elija una subcategoria</option>
          {response.map((subCategory) => (
            <option key={subCategory.id} value={subCategory.id}>
              {subCategory.name}
            </option>
          ))}
        </select>
      )} */}
      {validateArray(response) && (
        <div className="SubCategories__cards">
          {response.map((subCategory) => (
            <CardCategories
              key={subCategory.id}
              subCategory={subCategory}
              onClick={() => handleCardClick(subCategory.id, subCategory.url)}
            />
          ))}
        </div>
      )}
      <div className="SubCategories__list">
        <CategoryFilters seturl={seturl} />
      </div>
    </>
  )
}

const CategoryFilters = (props) => {
  const { seturl } = props
  const [search, setsearch] = useState({
    field: 'name',
    search: '',
  })
  function onChange(e) {
    const { name, value } = e.target
    setsearch((a) => ({ ...a, [name]: value }))
  }
  function onClick() {
    seturl(
      `${
        search?.search
          ? '&ForQuery=' + search.search + '&TypeQuery=' + search.field
          : ''
      }`,
    )
  }
  return (
    <Search
      options={[{ key: 'name', name: 'Nombre del curso' }]}
      onChange={onChange}
      onClick={onClick}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    membership: state.membership,
  }
}
export default connect(mapStateToProps, { openSnackbar, changeModal })(
  SubCategories,
)

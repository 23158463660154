import './CoursesTeacher.css'
import { useActionParam, useAxiosAuth } from '../../hooks'
import { validateArray, validateStatus } from '../../utils/Validation'
import { CourseModule } from '../../components/card'
import { H2, H3 } from '../../components/text'
import { connect } from 'react-redux'
import {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
  changeSession,
} from '../../store/actions'
import { useEffect } from 'react'
import { CardMainOptions } from '../../components/card'
import { ButtonIcon, Icon, RenderButton } from '../../components/buttons'
import { TableDefault } from '../../components/table'
import { Loading, LoadCircle } from '../../components/animation'
import { getAction } from '../../services/action/ActionAuthorization'
import { useState } from 'react'
import { urlAvatar } from '../../utils/Constant'
import { CompModal } from '../../components/modal'
import moment from 'moment'
import { BoxFlex, StepTips } from '../../components/boxes'
import { useSteps } from '../../hooks'
import { FormNewModule } from '../../components/form'
import { PendingsList } from '../../containers/coursesTeacher'
import PdfList from '../../containers/coursesTeacher/PdfList'
import ProgramManagerTeacher from '../../containers/institutions/institution/ProgramManager/ProgramManagerTeacher'

const CoursesTeacher = (props) => {
  const {
    changeLayout,
    changeInfo,
    openSnackbar,
    changeModal,
    changeSession,
    sesionUser,
  } = props
  const amount = 3
  const { step, nextStep, setStep } = useSteps({
    name: 'create_course',
    amount: amount,
    changeModal: changeModal,
    welcome: (
      <>
        <H2 color="var(--gray)">Te damos la bienvenida a midas.li</H2>
        <H3>
          Estas en la vista como creador podrás visualizar tus cursos, encuentra
          lo que necesitas y gestiona tus ventas más fácilmente.
        </H3>
      </>
    ),
  })
  const [modal, setmodal] = useState({
    view: false,
    mid: -1,
  })

  const { response: dataRequest, reload } = useAxiosAuth({
    method: 'GET',
    url: '/course/coursesPerProfessor',
  })

  useEffect(() => {
    changeLayout('')
    changeInfo(<PendingsList />)
  }, [changeLayout, changeInfo])

  function ListStudents({ mid }) {
    const { dataRequest, statusRequest } = useActionParam({
      action: getAction,
      param: `/course/${mid}/studentList`,
    })
    const [load, setload] = useState(true)
    function downloadList() {
      setload(false)
      getAction(`/course/${mid}/studentListPdf`).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          window.open(response.data, '_blank')
        }
      })
    }
    const header = [
      { key: 'photoUrl', name: 'Avatar', type: 'img' },
      { key: 'firstName', name: 'Nombres' },
      { key: 'lastName', name: 'Apellidos' },
      { key: 'email', name: 'Correo' },
    ]
    function preConfig(list) {
      list.forEach((u) => {
        if (!u.firstName) {
          u.firstName = 'N/A'
        }
        if (!u.lastName) {
          u.lastName = 'N/A'
        }
        if (!u.photoUrl) {
          u.photoUrl = urlAvatar
        }
      })
      return list
    }
    return (
      <div className="ListStudents">
        <div className="ListStudents__header">
          <H2>Estudiantes</H2>
          {load ? (
            <Icon ico="list" handleModal={() => downloadList()}>
              Descargar lista
            </Icon>
          ) : (
            <Loading />
          )}
        </div>
        {validateStatus(statusRequest) ? (
          validateArray(dataRequest) ? (
            <TableDefault
              // handleInfo={handleInfo}
              header={header}
              main={preConfig(dataRequest)}
            />
          ) : (
            <H3>No hay estudiantes inscritos</H3>
          )
        ) : (
          <LoadCircle />
        )}
      </div>
    )
  }
  function CardModuleOption({ moduleData, rol }) {
    const op = (
      <>
        <ButtonIcon
          onClick={() =>
            changeInfo(
              <FormNewModule
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                cid={moduleData.id}
                reload={handleReload}
              />,
            )
          }
          content="Crear módulo"
          icon="plus"
        />
        <ButtonIcon
          onClick={() => setmodal({ view: !modal.view, mid: moduleData.id })}
          content="Estudiantes"
          icon="users"
        />
        {moduleData.whatsappGroupUrl && (
          <ButtonIcon
            onClick={moduleData.whatsappGroupUrl}
            content="Grupo de WhatsApp"
            prefix="fab"
            icon="whatsapp"
            type="link"
          />
        )}
        {/* <ButtonIcon
          onClick={() =>
            changeInfo(
              <ViewCourseSchedule
                {...props}
                reload={reload}
                changeModal={changeModal}
                openSnackbar={openSnackbar}
                match={{
                  params: { cid: moduleData.id },
                }}
              />,
            )
          }
          content="Horario"
          icon="calendar-alt"
        /> */}
        {/* <ButtonIcon
          onClick={() => history.push(`/course-notes/${moduleData.id}`)}
          content="Notas de los estudiantes"
          icon="tasks"
        /> */}
        {/* <ButtonIcon
          onClick={() =>
            history.push(`/courses/${moduleData.id}/course-progress`)
          }
          content="Avance de los estudiantes"
          icon="spinner"
        /> */}
        {/* <ButtonIcon
          onClick={() =>
            changeInfo(
              <CourseTracking
                pid={moduleData.id}
                iid={moduleData.institutionId}
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                changeModal={changeModal}
              />,
            )
          }
          content="Ver listas de seguimiento"
          icon="list"
        /> */}
      </>
    )
    return (
      <CardMainOptions className="CardModuleOption" options={op}>
        <CourseModule
          moduleData={moduleData}
          rol={rol}
          handleCreateModule={() =>
            changeInfo(
              <FormNewModule
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                cid={moduleData.id}
                reload={handleReload}
              />,
            )
          }
        />
        ;
      </CardMainOptions>
    )
  }
  function handleReload() {
    changeInfo(<></>)
    reload()
  }

  return (
    <div className="CoursesTeacher">
      <BoxFlex justify="space-between">
        <H2>Mis Cursos</H2>
        <BoxFlex justify="flex-end">
          <RenderButton
            background="#e7e7e7"
            color="var(--gray)"
            onClick={() => {
              setStep(1)
              localStorage.removeItem('create_course')
            }}
          >
            <i className="fas fa-question-circle"></i>
          </RenderButton>
          <StepTips
            step={step}
            nextStep={nextStep}
            stepNumber={3}
            title="Descargar guías"
            description="Con este botón obtener archivos en formato PDF con información, instrucciones y consejos en forma de guías o manuales."
            amount={amount}
          >
            <div>
              <RenderButton
                background="#e7e7e7"
                color="var(--gray)"
                onClick={() =>
                  changeInfo(
                    <PdfList
                      changeInfo={changeInfo}
                      openSnackbar={openSnackbar}
                    />,
                  )
                }
              >
                Descargar Guia PDFs <i className="fas fa-file-pdf"></i>
              </RenderButton>
              {/* <Icon
              ico="calendar-al"
              handleModal={() =>
                changeInfo(
                  <PdfList
                    changeInfo={changeInfo}
                    openSnackbar={openSnackbar}
                  />,
                )
              }
            >
              Descargar Guia PDFs
            </Icon> */}
            </div>
          </StepTips>
        </BoxFlex>
      </BoxFlex>
      <ProgramManagerTeacher
        iid={1}
        changeInfo={changeInfo}
        openSnackbar={openSnackbar}
        changeModal={changeModal}
        reload={reload}
        changeSession={changeSession}
        sesionUser={sesionUser}
        step={step}
        nextStep={nextStep}
        amount={amount}
      />
      <br />
      <div className="CoursesTeacher__list">
        {validateArray(dataRequest) ? (
          dataRequest
            .sort(function (left, right) {
              return moment
                .utc(left.startDate)
                .diff(moment.utc(right.startDate))
            })
            .map((l) => (
              <CardModuleOption
                moduleData={{ ...l, moduleToList: l.modules }}
                key={l.id}
                rol="educator"
              />
            ))
        ) : (
          <h3>Aún no hay tiene Cursos Creados</h3>
        )}
      </div>
      {modal.view && modal.mid !== -1 ? (
        <CompModal
          onClose={() => setmodal({ view: !modal.view })}
          closeOutside={true}
        >
          <ListStudents mid={modal.mid} />
        </CompModal>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
  changeSession,
})(CoursesTeacher)

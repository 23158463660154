import { Switch, Route, withRouter } from "react-router-dom";
import React, { useEffect } from "react";
import {
  Account,
  Chat,
  Home,
  Questions,
  Courses,
  Auth,
  CourseInfo,
  Verify,
  Modules,
  Programs,
  VideoContent,
  HomeworkContent,
  QuizContent,
  Institutions,
  Institution,
  Enrollment,
  Page404,
  AnswersContent,
  ProgramInstitution,
  Catalogue,
  EnglishCourse,
  CoursesTeacher,
  ModulesRolUser,
  QuizEdit,
  QuizRatings,
  CourseInstitution,
  CourseNotes,
  CourseProgress,
  ProgrammingCourse,
  Score,
  ProgrammingInfo,
  EnglishInfo,
  InfoUAGRM,
  Forum,
  Academic,
  Shopping,
  SeminarUAGRM,
  Certificate,
  ListCertificate,
  Order,
  Redirect,
  Confirmation,
  OrderResult,
  Contacts,
  Manager,
  ChessCourse,
  ChessCourseInfo,
  Geolocation,
  ResumeContact,
  Payment,
  PaymentResult,
  JitsiContent,
  ShortCourses,
  Admin,
  CourseNotFound,
  OnlyPayment,
  OnlyPaymentResult,
  UserTracking,
  NewHome,
  Webinar,
  Kids,
  Registered,
  Contents,
  Rol,
  NewAbout,
  DigitalSignature,
  TermsOfService,
  Welcome,
  ConfirmEmail,
  WaitConfirmEmail,
  GenerateResource,
} from "../view";
import { ProtectedRoute, GoBackFlex } from "../components/navigation";
import TagManager from "react-gtm-module";
import { changeHeader } from "../store/actions";
import { connect } from "react-redux";
import ScheduleView from "../containers/institutions/institution/ScheduleView";
import Affiliate from "../view/Affiliate/Affiliate";
import { useState } from "react";
import ProfileModal from "../components/modal/ProfileModal";

const Main = (props) => {
  const { changeHeader, history, sesionUser } = props;
  console.log("sesionUser", sesionUser);
  const p = history.location.pathname;
  const [showProfileModal, setShowProfileModal] = useState(true);
  useEffect(() => {
    if (p.includes("big-data")) {
      changeHeader(
        <img
          src="https://res.cloudinary.com/pataformacgi/image/upload/v1675126147/assets/midas_logo_bpc76l.png"
          // src="https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472644/assets/Rectangle_339_y2ol2t_em6bju.png"
          alt="Logotipo UAGRM"
        />
      );
    } else {
      changeHeader(
        <img
          src="https://res.cloudinary.com/pataformacgi/image/upload/v1675126147/assets/midas_logo_bpc76l.png"
          // src="https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472641/assets/logo_2_okkya4_zuaobh.png"
          alt="Logotipo Capacita Global"
        />
      );
    }
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: p,
      },
    });
  }, [p]);
  return (
    <>
      {/* {showProfileModal && sesionUser?.completeProfile ? (
        <ProfileModal
          onClose={() => setShowProfileModal(false)}
          redirectTo="/Account"
          buttonText="Completar datos"
        />
      ) : null} */}

      {showProfileModal &&
      sesionUser?.completeProfile === false &&
      window.location.pathname !== "/Account" ? (
        <ProfileModal
          onClose={() => setShowProfileModal(false)}
          redirectTo="/Account"
          buttonText="Completar datos"
        />
      ) : null}

      <GoBackFlex location={props.history.location} />

      <Switch>
        {/* <Route exact path="/" component={HomeHero} /> */}

        <Route exact path="/" component={NewHome} />
        <Route exact path="/terms-of-service" component={TermsOfService} />
        <Route exact path="/webinar/registered" component={Registered} />
        <Route exact path="/webinar" component={Webinar} />
        <Route exact path="/geolocation" component={Geolocation} />
        <Route exact path="/about" component={Home} />
        <Route exact path="/about" component={Home} />
        <Route exact path="/contents" component={Contents} />
        <Route exact path="/redirect" component={Redirect} />
        <Route exact path="/catalogue/short-courses" component={ShortCourses} />
        <Route path="/catalogue" component={Catalogue} />
        <Route path="/certificate/:luid/list" component={ListCertificate} />
        <Route path="/certificate/:luid" component={Certificate} />
        <Route path="/order/:oid" component={Order} />
        <Route path="/confirmation" component={Confirmation} />
        <Route path="/order-result" component={OrderResult} />
        <Route path="/payment-result" component={PaymentResult} />
        <Route path="/payment" component={Payment} />
        <Route path="/kids" component={Kids} />
        <Route path="/presentation" component={NewAbout} />
        <Route path="/waitConfirmEmail" component={WaitConfirmEmail} />
        <Route path="/generateResource" component={GenerateResource} />

        <ProtectedRoute
          path="/select/rol"
          component={Rol}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          path="/welcome/:type"
          component={Welcome}
          validate={["isAuthenticated"]}
        />
        {/* <ProtectedRoute
          path="/welcome/student"
          component={Student}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          path="/welcome/afiliate"
          component={Afiliate}
          validate={['isAuthenticated']}
        />
        <ProtectedRoute
          path="/welcome/educator"
          component={Educator}
          validate={['isAuthenticated']}
        /> */}
        <ProtectedRoute
          path="/digitalSignature"
          component={DigitalSignature}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          path="/confirm-email/:token"
          component={ConfirmEmail}
          validate={["isAuthenticated"]}
        />

        {/* <ProtectedRoute
          exact
          path="/order/:oid"
          component={Order}
          validate={['isAuthenticated']}
        /> */}

        <ProtectedRoute
          exact
          path="/programs/:status?"
          component={Programs}
          validate={["isAuthenticated"]}
        />
        <Route exact path="/shopping-cart" component={Shopping} />
        <ProtectedRoute
          exact
          path="/only-payment"
          component={OnlyPayment}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/only-payment-result"
          component={OnlyPaymentResult}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/manager"
          component={Manager}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/courses"
          component={CoursesTeacher}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/courses/:id/user-tracking"
          component={UserTracking}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/institution"
          component={Institutions}
          validate={["isAuthenticated", "accessInstitution"]}
        />
        <ProtectedRoute
          exact
          path="/institution/:iid/:name?"
          component={Institution}
          validate={["isAuthenticated", "accessInstitution"]}
        />
        <ProtectedRoute
          exact
          path="/institution/:iid/program/:pid"
          component={ProgramInstitution}
          validate={["isAuthenticated", "accessInstitution"]}
        />
        <ProtectedRoute
          exact
          path="/institution/:iid/course/:pid"
          component={CourseInstitution}
          validate={["isAuthenticated", "accessInstitution"]}
        />
        <ProtectedRoute
          exact
          path="/account"
          component={Account}
          validate={["isAuthenticated"]}
        />
        <Route exact path="/questions" component={Questions} />
        <Route exact path="/verifyemail" component={Verify} />
        {/* <Route exact path="/calendar" component={Calendar} /> */}
        <ProtectedRoute
          exact
          path="/chat"
          component={Chat}
          validate={["isAuthenticated"]}
        />
        {/* <Route path="/auth" component={Auth} /> */}
        <ProtectedRoute
          path="/auth"
          component={Auth}
          validate={["notAuthenticated"]}
        />
        <Route path="/graduates/:gid/enrollment/:eid?" component={Enrollment} />
        <Route path="/graduates/:id/:type?" component={CourseInfo} />
        <Route path="/UAGRM/big-data" component={InfoUAGRM} />
        {/* <Route path="/seminar/:id" component={Seminar} /> */}
        <Route path="/seminar/:id" component={SeminarUAGRM} />
        <Route exact path="/course-english/:id" component={EnglishInfo} />
        <Route path="/course-english" component={EnglishCourse} />
        <Route
          exact
          path="/course-programming/:id"
          component={ProgrammingInfo}
        />
        <Route path="/course-programming" component={ProgrammingCourse} />
        <Route exact path="/course-chess/:id" component={ChessCourseInfo} />
        <Route path="/course-chess" component={ChessCourse} />
        <ProtectedRoute
          exact
          path="/course-notes/:cid"
          component={CourseNotes}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/courses/:cid/course-progress"
          component={CourseProgress}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:id"
          component={Courses}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid/score"
          component={Score}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid/academic/:uid/:type"
          component={Academic}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/:rol"
          component={ModulesRolUser}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid"
          component={Modules}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/video/:id"
          component={VideoContent}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/jitsi/:id"
          component={JitsiContent}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/home-work/:id"
          component={HomeworkContent}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/forum/:id"
          component={Forum}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/quiz/:qid/edit"
          component={QuizEdit}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/quiz/:qid/ratings"
          component={QuizRatings}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/resume-contact"
          component={ResumeContact}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/admin"
          component={Admin}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/affiliate"
          component={Affiliate}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/quiz/:qid"
          component={QuizContent}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/program/:pid?/course/:cid/module/:mid/answer/:aid"
          component={AnswersContent}
          validate={["isAuthenticated"]}
        />
        <ProtectedRoute
          exact
          path="/calendar/viewSchule/"
          component={ScheduleView}
          validate={["isAuthenticated"]}
        />
        <Route path="/coursenotfound" component={CourseNotFound} />
        <Route path="*" component={Page404} />
      </Switch>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, { changeHeader })(withRouter(Main));

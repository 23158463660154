import React, { Component } from 'react'
import SignaturePad from '../../components/canva/SignatureCanvas'
import styles from './styles.css'
import { Card, CardBody, CardTitle, CardText, Button } from 'reactstrap'

class DigitalSignature extends Component {
  state = { trimmedDataURL: null, signPad: false }

  sigPad = {}

  clear = () => {
    this.sigPad.clear()
    this.setState({ signPad: false })
  }

  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
    })
  }

  addSign = () => {
    this.setState({ signPad: true })
  }

  render() {
    let { trimmedDataURL } = this.state

    return (
      <div className="App">
        <Card>
          <CardBody>
            <CardTitle>Contrato</CardTitle>
            <CardText>
              <p>
                Primera.- Como garantía para la deuda contraída con “El
                acreedor” la parte deudora, da en prenda los artículos
                mencionados en el punto “A”, de las declaraciones de este
                contrato, las cuales se encontrarán en completa disposición,
                tanto de disfrute como de manejo de “El acreedor” durante el
                tiempo que se tarde en saldarse dicha deuda, quedando marginada
                las legislaciones que marquen límites. Segunda.- “El deudor”
                concede y entrega el vehículo a “El acreedor” quedando “El
                deudor” completamente conforme en que se utilice por parte de
                “El acreedor” y que se realicen los gastos para mantener dicho
                vehículo en condiciones óptimas. Tercera.- Quedan conformes las
                partes, que los frutos o beneficios del uso de este vehículo, se
                reducirán de los intereses y deuda que genera el presente
                contrato de prenda. Cuarta.- “El deudor” puede vender el
                vehículo objeto de este contrato fuera del plazo marcado, y para
                realizar la enajenación deberá completar el costo del adeudo
                junto con sus intereses. Quinta.- En caso de que el vehículo se
                encuentre dañado en forma absoluta, o se haga un uso fuera de lo
                marcado por la ley o su naturaleza, se podrá proceder a
                depositarlo en una tercera persona, que sería elegida previo
                acuerdo por ambas partes. En caso de que exista un daño mayor en
                dicho vehículo, se podrá poner una fianza que lo garantice.
                Séptima.- en caso de que no se pague la deuda en el plazo
                fijado, “El acreedor” podrá enajear en forma pública para
                restituirse el valor de la deuda correspondiente y en su caso
                restituir el costo sobrante a “El deudor” no sin antes cubrir
                los gastos alternos y las costas judiciales. Octavo.- La parte
                deudora renuncia a la jurisdicción que conforme a derecho le
                corresponda por encontrarse en residencia en el Estado de
                México, y se acogerá a la jurisdicción y leyes de la Ciudad de
                México, Distrito Federal. Leído este contrato a las partes, y
                quedando plenamente conforme con el mismo, se procede a su
                firma, existiendo un testigo por cada una de las partes, quienes
                firmarán este contrato. Fuente:
                https://www.ejemplode.com/47-contratos/2279-ejemplo_de_contrato_de_prenda.html#ixzz800zda1jv
              </p>

              <div className="trimmedUrl">
                {trimmedDataURL ? (
                  <img
                    className={styles.sigImage}
                    src={trimmedDataURL}
                    alt=""
                  />
                ) : null}
              </div>
              <br />
              {trimmedDataURL && this.state.signPad === false ? null : (
                <Button className="btnSignature" onClick={this.addSign}>
                  Presiona Para Firmar
                </Button>
              )}
            </CardText>
          </CardBody>
          {this.state.signPad === true && (
            <div>
              <div className={styles.sigContainer}>
                <SignaturePad
                  canvasProps={{ className: styles.sigPad }}
                  ref={(ref) => {
                    this.sigPad = ref
                  }}
                />
              </div>
              <div>
                <button className={styles.buttons} onClick={this.trim}>
                  Completar Firma
                </button>
                <button styles={styles.buttons} onClick={this.clear}>
                  Cerrar Pad
                </button>
              </div>
            </div>
          )}
        </Card>
      </div>
    )
  }
}

export default DigitalSignature

import { validateArray } from '../../../../utils/Validation'
import { BoxFlex, ToolTip } from '../../../../components/boxes'
import { RenderButton } from '../../../../components/buttons'
import { OrderAside } from '../../../../containers/institutions/ProgramInstitution'
import { useState } from 'react'
import './ModulesContainer.css'
import ModuleContainer from './ModuleContainer'
const ModulesContainer = (props) => {
  const {
    mod,
    mid,
    rol,
    reload,
    openSnackbar,
    changeInfo,
    changeModal,
    type,
  } = props
  const [status, setstatus] = useState(mod.id.toString() === mid ? true : false)

  function handleOrder() {
    changeInfo(
      <OrderAside
        changeInfo={changeInfo}
        openSnackbar={openSnackbar}
        url={null}
        putUrl={`/module/${mod.id}/OrderLessons`}
        handleReload={reload}
        textTitle="lecciones"
        data={mod.lessons}
      />,
    )
  }

  return (
    <div className="ModuleLections">
      <BoxFlex wrap="nowrap">
        <div className="Course__subTitle" onClick={() => setstatus(!status)}>
          <h3>{mod.name}</h3>
          <i className={`fas fa-chevron-${status ? 'down' : 'right'}`}></i>
        </div>
        {/* <ToolTip content="Ordenar lecciones">
          <div className="">
            <RenderButton onClick={handleOrder} fontSize="2rem">
              <i className={`fas fa-sort-numeric-down`}></i>
            </RenderButton>
          </div>
        </ToolTip> */}
      </BoxFlex>
      {status && (
        <div className="Course__main">
          {validateArray(mod.lessons) ? (
            mod.lessons
              .sort((a, b) => a.orderNumber - b.orderNumber)
              .map((lection) => (
                <ModuleContainer
                  key={lection.id}
                  type={type}
                  lection={lection}
                  rol={rol}
                  reload={reload}
                  openSnackbar={openSnackbar}
                  changeInfo={changeInfo}
                  changeModal={changeModal}
                />
              ))
          ) : (
            <h3 className="ModuleLections__h3">
              Aún no hay contenido en el curso
            </h3>
          )}
        </div>
      )}
    </div>
  )
}

export default ModulesContainer

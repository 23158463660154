import * as Yup from 'yup'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { FormikControl } from '../../input'
import { RenderButton } from '../../buttons'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../animation/index'
import { postAction } from '../../../services/action/Action'
import { openSnackbar } from '../../../store/actions'
import { validateStatus } from '../../../utils/Validation'
import { connect } from 'react-redux'
const PasswordRecovery = (props) => {
  const { t } = useTranslation('Register')
  const { verifyKey } = props.match.params
  const [actions, setactions] = useState({
    load: true,
    msj: true,
  })
  const initialValues = {
    email: '',
    password: '',
    password_confirmation: '',
  }
  const validationSchema = Yup.object({
    email: Yup.string().email(t('invalidEmail')).required(t('fieldObligatory')),
    password: Yup.string()
      .required(t('fieldObligatory'))
      .min(4, t('charactersRequired')),
    password_confirmation: Yup.string()
      .required(t('fieldObligatory'))
      .min(4, t('charactersRequired'))
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
  })
  function save(res) {
    if (validateStatus(res.status)) {
      setactions((a) => ({ ...a, msj: false }))
      props.openSnackbar('Ahora usar su nueva contraseña', true, true)
      props.history.push('/auth/login')
    } else {
      setactions((a) => ({ ...a, load: true }))
      props.openSnackbar('El enlace no es valido', true, false)
    }
  }
  const onSubmit = async (values) => {
    try {
      setactions((a) => ({ ...a, load: false }))
      await postAction('/auth/verifyrecovery', { ...values, verifyKey }).then(
        (response) => {
          save(response)
        },
      )
    } catch (e) {
      setactions((a) => ({ ...a, load: true }))
      console.log(e)
    }
  }
  return (
    <>
      <h3 className="form__span">Restablecer Contraseña</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="email"
                label={t('email')}
                name="email"
                labelColor="white"
              />
              <FormikControl
                control="input"
                type="password"
                label={t('password')}
                name="password"
                labelColor="white"
              />
              <FormikControl
                control="input"
                type="password"
                label={t('confirmPassword')}
                labelColor="white"
                name="password_confirmation"
              />
              {actions.msj ? (
                actions.load ? (
                  <RenderButton
                    type="submit"
                    fontSize="1.8rem"
                    background="linear-gradient(90deg, #f0cf0c 0%, #eea417 100%)"
                    width="100%"
                    borderRadius="10px"
                    disabled={!formik.isValid}
                  >
                    Restablecer
                  </RenderButton>
                ) : (
                  <Loading />
                )
              ) : (
                <center>
                  <h3>Revisa tu correo</h3>
                </center>
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
const mapDispatchToProps = {
  openSnackbar,
}
export default connect(null, mapDispatchToProps)(withRouter(PasswordRecovery))

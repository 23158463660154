import React from 'react';
import "./style/CardCategories.css";

const CardCategories = ({ subCategory, onClick }) => {
  const iconUrls = [
    '',//00
    '',//01
    '',//02
    '',//03
    '',//04
    '',//05
    '',//06
    '',//07
    '',//08
    '',//09
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089868/internacional_1_xiwqnd.png',//10
    '',//11
    '',//12
    '',//13
    '',//14
    '',//15
    '',//16
    '',//17
    '',//18
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089869/megafono_tguitl.png',//19
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089867/matematicas_mvc3h0.png',//29
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089866/derecho-penal_jiztqe.png',//30
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089866/contabilidad_up1afy.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089865/desarrollar_fapkax.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089864/mano_drzcev.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089863/informatica_dnds0h.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089863/business-intelligence_d9enuf.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089862/economico_jvxweb.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089861/investigar_li2b4z.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089860/cocinero_xrif3v.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089860/rodillo-derma_ji2zix.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089859/promocion_l2jxn7.png',
    'https://res.cloudinary.com/doutxgxmu/image/upload/v1705089858/elipsis_cme9ke.png'
  ];

  const imageUrl = iconUrls[subCategory.id];

  return (
    <div className="SubCategories__card" onClick={onClick}>
      <img src={imageUrl} alt={subCategory.name} />
      <p>{subCategory.name}</p>
    </div>
  );
};

export default CardCategories;


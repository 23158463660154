import { useEffect, useState } from "react";
import { TableFilterContainer, TableDefault } from "../../components/table";
import {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
} from "../../store/actions";
import { connect } from "react-redux";
import { useAxiosAuth, useSteps } from "../../hooks";
import { H3, H2 } from "../../components/text";
import { ButtonIcon, DefaultBtn, RenderButton } from "../../components/buttons";
import { BoxFlex, StepTips } from "../../components/boxes";
import { UseOnSubmit } from "../../hooks/useOnSubmit";
import config from "../../config.json";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { validateArray } from "../../utils/Validation";
import { VideoPlayer } from "../../components/common";
import RenderModal from "../../components/modal/RenderModal";

const Affiliate = (props) => {
  const { changeInfo, changeLayout, changeModal } = props;
  const { response, loading } = useAxiosAuth({
    method: "GET",
    url: `/affiliate`,
  });
  const [modal, setmodal] = useState(true);
  const amount = 7;
  const { step, nextStep } = useSteps({
    name: "view-affiliate",
    amount: amount,
    changeModal: changeModal,
    initialStep: 0,
    welcome: (
      <>
        <H2 color="var(--gray)">Te damos la bienvenida a midas.li</H2>
        <H3>
          Estás en la vista de afiliado, donde podrás ver los cursos a los que
          estás afiliado. Aquí tendrás acceso al enlace que debes compartir en
          tus redes sociales para poder ganar comisiones.
        </H3>
      </>
    ),
  });

  useEffect(() => {
    changeLayout("");
    changeInfo(<></>);
    // changeModal()
  }, []);

  useEffect(() => {
    if (validateArray(response) && !loading) {
      changeInfo(
        <ViewAffiliate
          data={response[0]}
          step={step}
          nextStep={nextStep}
          amount={amount}
        />
      );
    }
  }, [response, loading, step]);

  useEffect(() => {
    if (!modal) {
      nextStep()
    }
  }, [modal]);

  function onClick(data) {
    changeInfo(
      <ViewAffiliate
        data={data}
        step={step}
        nextStep={nextStep}
        amount={amount}
      />
    );
  }

  function Modal()
  {
    if(!window.localStorage.getItem('view-affiliate') && modal === true)
    {
      return  (
        <RenderModal
          width="700px"
          closeOutside={true}
          onClose={() => setmodal(!modal)}
        >
          <VideoPlayer
            url="https://res.cloudinary.com/ddwggcymx/video/upload/f_auto:video,q_auto/v1/Funnel/Video/fwfr9uvl0r97xiedy4es"
            width="100%"
            controls={false}
            height="calc(100vh - 250px)"
            muted={false}
            playing={true}
          />
        </RenderModal>
      )
    }
    else
    {
      return ""
    }
  }

  return (
    <div>
      <Modal/>
      <BoxFlex justify="end" margin="5px 0">
        <StepTips
          step={step}
          nextStep={nextStep}
          stepNumber={2}
          title="Ir al Funnel"
          description="Cuando hagas clic en este botón, podrás acceder a nuestra herramienta de creación de Funnels, que te ayudará a promocionar el curso de Afiliado."
          amount={amount}
        >
          <StepTips
          step={step}
          nextStep={nextStep}
          stepNumber={3}
          title="Que es Funnel?"
          description="Un funnel  es como una tubería mágica que muestra cómo las personas van de '¡Me gusta esto!' a '¡Lo compro!' Al principio, muchas personas están interesadas, pero a medida que avanzan, algunas deciden comprar, como cuando vas a una tienda de juguetes y eliges tu juguete favorito para comprarlo."
          amount={amount}
        >
          <a
            href="http://funnel.midas.li/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <DefaultBtn>Ir al Funnel</DefaultBtn>
          </a>
        </StepTips>
        </StepTips>
      </BoxFlex>

      <StepTips
        step={step}
        nextStep={nextStep}
        stepNumber={4}
        title="Lista de productos afiliados"
        description="Aquí encontrarás una lista con los cursos a los que te afiliaste y toda la información detallada asociada a cada uno de ellos."
        amount={amount}
      >
        <TableFilterContainer
          param={`/affiliate`}
          header={[
            {
              key: "programManagerName",
              name: "Cursos",
            },
            {
              key: "quantitySold",
              name: "Compras",
            },
            {
              key: "price",
              name: "Precio del curso",
            },
            {
              key: "membershipPercentage",
              name: "Porcentaje de comisión",
            },
            {
              key: "total",
              name: "Total ganado",
            },
          ]}
          onClick={onClick}
        />
      </StepTips>
    </div>
  );
};

const ViewAffiliate = (props) => {
  const { data, step, nextStep, amount } = props;
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `/affiliate/${data.programManagerId}`,
  });

  useEffect(() => {
    reload();
  }, [data]);

  const onSubmitAfiliado = async () => {
    try {
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: `/affiliate/programManager/${data.programManagerId}`,
          data: {
            UrlPage:
              config.domainURL === "http://localhost:3000"
                ? `http://localhost:3000/graduates/${data.programManagerId}/course`
                : `https://midas.li/graduates/${data.programManagerId}/course`,
          },
        },
        openSnackbar: openSnackbar,
        textSuccess: "Afiliado con exito",
        textWrong: "No se pudo Afiliar, intentelo de nueo",
        functionSuccess: () => reload(),
        process: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (loading) {
    return null;
  }
  return (
    <StepTips
      step={step}
      nextStep={nextStep}
      stepNumber={5}
      title="Tabla de cursos afiliados"
      description="Al momento de hacer clic en uno de los cursos en los que esta afiliado se abrirá esta tabla que brindará mas información de las ventas."
      amount={amount}
    >
      <div>
        <BoxFlex justify="space-between">
          <H3 margin="0">Códigos de afiliado</H3>
          <StepTips
            step={step}
            nextStep={nextStep}
            stepNumber={6}
            title="Generar nuevo codigo"
            description="Al Hacer Click en este boton podra generar un codigo de Afiliado, el cual se podrá usar para ganar comisiones cuando los usuarios adquieran los cursos con su codigo."
            amount={amount}
          >
            <RenderButton onClick={onSubmitAfiliado}>
              Generar nuevo código
            </RenderButton>
          </StepTips>
        </BoxFlex>
        <StepTips
          step={step}
          nextStep={nextStep}
          stepNumber={7}
          title="Información de las ventas"
          description="En esta tabla se muestran el codigo de Afiliado, una URL para acceder a los cursos y un boton para copiar la URL en el portapapeles para compartir esta mas facilmente."
          amount={amount}
        >
          <TableFilterContainer
            param={`/affiliate/${data.programManagerId}`}
            header={[
              {
                key: "codeAffiliate",
                name: "Codigo de Afiliado",
              },
              {
                key: "url",
                name: "Url de afiliado",
                type: "a",
              },
              {
                key: "url",
                name: "Copiar Url",
                type: "preProcess",
                validate: validCode,
              },
              {
                key: "quantitySold",
                name: "Cantidad de ventas",
              },
              {
                key: "visited",
                name: "Cantidad de vistas",
              },
              {
                key: "creationDate",
                name: "Fecha de Creacion",
                type: "date",
              },
            ]}
            //onClick={onclick}
          />
        </StepTips>
      </div>
    </StepTips>
  );
};

export default connect(null, {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
})(Affiliate);

function validCode(data) {
  const copyCurrentLink = () => {
    const url = data.url + "?affiliate=" + data.programManagerId;
    navigator.clipboard.writeText(`${url}`);
    openSnackbar("Enlace copiado", true, true);
  };
  return (
    <RenderButton onClick={copyCurrentLink} content="Copiar URL">
      <i className="far fa-copy"></i>
    </RenderButton>
  );
}

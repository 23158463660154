import './style/CourseModule.css'
import { CircleBar, LineBar } from '../progress'
import { withRouter } from 'react-router-dom'
import { validateArray, validateEducator } from '../../utils/Validation'
import { dataLock, convertD } from '../../utils/ConvertDate'
import { DefaultBtn } from '../../components/buttons'
import { connect } from 'react-redux'
import { useState } from 'react'
import { H2, H3, H4 } from '../text'
const CourseModule = (props) => {
  const {
    moduleData: {
      userRolToList,
      name,
      id: cid,
      moduleToList,
      versionName,
      startDate,
      programName,
    },
    pid,
    rol,
    history,
    sesionUser,
    hiddenDate = false,
    handleCreateModule,
  } = props
  const [detail, setdetail] = useState(true)
  function handleUnit(module) {
    history.push(
      `/program${pid ? '/' + pid : ''}/course/${cid}/module/${module.id}${
        // rol ? '/educator' : ''
        validateEducator(sesionUser.rols, cid) ? '/educator' : ''
      }`,
    )
  }
  return (
    <div
      className="CourseModule"
      style={{ boxShadow: rol ? '' : '1px 1px 10px rgba(0, 0, 0, 0.2)' }}
    >
      {/* <div className="CourseModule__header">
        {!versionName && <div></div>}
        {hiddenDate ? (
          <div></div>
        ) : (
          <H3>Fecha de inicio:{convertD(startDate, 'LL')}</H3>
        )}
        {versionName && (
          <h3 className="CourseModule__version">{versionName}</h3>
        )}
      </div> */}
      <H2 margin="5px 0">{programName}</H2>
      <H4 fontSize="2rem" margin="0" fontWeight="600">
        {name}
      </H4>
      <H4 margin="0">Progreso de unidades</H4>
      {validateArray(moduleToList) ? (
        <>
          <div className={detail ? 'CourseModule__unitBar' : ''}>
            <div className={detail ? 'CourseModule__progressCircles' : ''}>
              {moduleToList
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map(function (l, index) {
                  let p = (userRolToList ? userRolToList.percentage : 0.1) / 100
                  let c = Math.ceil(p * moduleToList.length)
                  const lockModule = rol
                    ? true
                    : dataLock(l.startDate) * dataLock(userRolToList.startDate)
                  if (detail) {
                    return (
                      <CircleBar
                        content={l.name}
                        status={c >= l.orderNumber ? true : false}
                        order={l.orderNumber}
                        key={index}
                        lock={rol ? dataLock(l.startDate) : lockModule}
                        onClick={lockModule ? () => handleUnit(l) : null}
                        date={
                          hiddenDate ? null : convertD(l.startDate, 'DD/MM')
                        }
                      />
                    )
                  } else {
                    return (
                      <div className="CourseModule__cicleText" key={index}>
                        <CircleBar
                          // content={l.name}
                          onClick={lockModule ? () => handleUnit(l) : null}
                          status={c >= l.orderNumber ? true : false}
                          order={l.orderNumber}
                          lock={lockModule}
                        />
                        <div
                          onClick={lockModule ? () => handleUnit(l) : null}
                          className="CourseModule__Text"
                        >
                          <H4 margin="5px 0">{l.name}</H4>
                          {!hiddenDate && (
                            <H4 margin="0" color="var(--gray)">
                              Fecha de inicio: {convertD(l.startDate, 'LL')}
                            </H4>
                          )}
                        </div>
                      </div>
                    )
                  }
                })}
            </div>
            {detail && (
              <LineBar
                border={false}
                progressline={userRolToList ? userRolToList.percentage : 0}
              />
            )}
          </div>
          <br />
          <center>
            <span
              className="CourseModule__details"
              onClick={() => setdetail(!detail)}
            >
              {detail ? (
                <>
                  Más detalles <i className="fas fa-chevron-down"></i>
                </>
              ) : (
                <>
                  Menos detalles <i className="fas fa-chevron-up"></i>
                </>
              )}
            </span>
          </center>
        </>
      ) : (
        <>
          <H3>Aún no hay unidades en el módulo</H3>
          <center>
            <DefaultBtn onClick={handleCreateModule}>Crear modulo +</DefaultBtn>
          </center>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, null)(withRouter(CourseModule))

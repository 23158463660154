import { DefaultBtn } from '../../components/buttons'
import { H4, H2, H3 } from '../../components/text'
import { useState, useEffect } from 'react'
import { putShopp } from '../../services/action/Shopping'
import { postAction } from '../../services/action/Action'
import {
  validateArray,
  validateStatus,
  validateInstitution,
} from '../../utils/Validation'
import { RenderModal } from '../../components/modal'
import { ActionButton } from '../../components/buttons'
import { Loading } from '../../components/animation'
import { SimpleInput, SimpleSelect } from '../../components/input'
import { makeid } from '../../utils/ProcessData'
import { useAxios } from '../../hooks'
import './ShoppingAside.css'
import config from '../../config.json'
const ShoppingAside = (props) => {
  const [shopp, setshopp] = useState(null)
  const [modal, setmodal] = useState(false)
  function processPay(jsonList, jsonLitShop) {
    let aux = []
    for (let j in jsonList) {
      if (jsonList[j]?.programManagerId && jsonList[j]?.paymentPlanId) {
        aux.push({
          ProgramManagerId: jsonList[j].programManagerId,
          PaymentPlanId: jsonList[j].paymentPlanId,
        })
      }
    }

    for (let index = 0; index < jsonLitShop.length; index++) {
      aux[index] = {
        ...aux[index],
        CodeAffiliate: props.shopping[index].codeAffiliate
          ? props.shopping[index].codeAffiliate
          : null,
      }
    }
    return aux
  }
  useEffect(() => {
    putShopp({
      Orders: processPay(props.chosenPlan, props.shopping),
      CouponCode: props.handleCoupon?.code ? props.handleCoupon.code : null,
    }).then((response) => {
      if (validateStatus(response?.status)) {
        setshopp(response.data)
      }
    })
  }, [props])

  return shopp ? (
    <div>
      <div className="Shopping__aside">
        <H4 textAlign="center">{shopp.total} Bs.</H4>
        <H4 textAlign="center" margin="0">
          Precio a pagar
        </H4>
      </div>
      <br />
      <H4 fontWeight="600" textAlign="center">
        Resumen
      </H4>
      <div className="Shopping__section">
        <div className="Shopping__course">
          <H4 color="var(--green)">Cursos</H4>
          <H4 color="var(--green)">Precios</H4>
        </div>
        <div className="Shopping__price">
          {validateArray(shopp.priceProgramManager)
            ? shopp.priceProgramManager.map((program) => (
                <div
                  className="Shopping__course"
                  key={program.programManagerId}
                >
                  <H4>{program.name}</H4>
                  <H4>{program.totalAmount} Bs.</H4>
                </div>
              ))
            : null}
        </div>
        {shopp.couponDiscount ? (
          <>
            <div className="Shopping__course">
              <H4>Descuento del cupón</H4>
              <H4>-{shopp.couponDiscount}</H4>
            </div>
          </>
        ) : null}
        <br />
        <div className="Shopping__course">
          <H4 color="var(--green)">Total</H4>
          <H4>{shopp.total} Bs.</H4>
        </div>
      </div>
      <br />
      <center>
        <DefaultBtn onClick={() => setmodal(!modal)}>
          Completar el pedido
        </DefaultBtn>
      </center>
      {modal && (
        <RenderModal
          width="700px"
          onClose={() => setmodal(!modal)}
          closeOutside
        >
          <ModalRegister
            {...props}
            dataShopping={shopp}
            orders={processPay(props.chosenPlan, props.shopping)}
            onClose={() => setmodal(!modal)}
          />
        </RenderModal>
      )}
    </div>
  ) : null
}
export const ModalRegister = (props) => {
  const {
    onClose,
    dataShopping,
    orders,
    startSession,
    handleCoupon,
    sesionUser,
    changeModal,
    history,
    urlPost = '/order',
    textClose = 'Volver al pedido',
    dataPayment = {},
    orderResult = 'order-result',
  } = props
  const { institutions } = sesionUser
  const isAdmin = validateArray(validateInstitution(institutions, 'admin'))
  // Geolocation
  const [position, setposition] = useState({
    latitude: null,
    longitude: null,
  })
  const { response, loading, reload } = useAxios({
    method: 'GET',
    url:
      position.latitude && position.longitude
        ? `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.latitude},${position.longitude}&key=AIzaSyBpryRjZq881pzHF62NDj0RHL41pFmplzo`
        : null,
  })
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setposition(position.coords)
      },
      function (error) {
        console.error('Error Code = ' + error.code + ' - ' + error.message)
      },
    )
  }, [])
  useEffect(() => {
    if (position.latitude && position.longitude) {
      reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position])
  // Geolocation

  const [dataModal, setDataModal] = useState({
    status: false,
    user: {
      name: isAdmin ? '' : sesionUser?.user?.firstName || '',
      lastname: isAdmin ? '' : sesionUser?.user?.lastName || '',
      email: isAdmin ? '' : sesionUser?.user?.email || '',
      paisIndex: '',
      estado_lbl: '',
      phone: '',
      Amount: dataShopping.total,
      Currency: dataShopping.currency ? dataShopping.currency : 'BOB',
      Order_id: makeid(8),
    },
    paymentType: true,
  })
  const [load, setload] = useState(false)
  const [view, setview] = useState(true)
  function handleModal(name, data) {
    setDataModal((a) => ({
      ...a,
      [name]: data,
    }))
  }
  const { response: respCountry, loading: loadCountry } = useAxios({
    method: 'GET',
    url: `/Country`,
  })
  function handleDisabled() {
    let disabled = false
    if (dataModal.user) {
      for (let key in dataModal.user) {
        if (dataModal.user[key] === '') {
          disabled = true
        }
      }
    }
    return disabled
  }

  async function handleOnSubmit() {
    const {
      name,
      lastname,
      email,
      paisIndex,
      estado_lbl,
      phone,
      Amount,
      Currency,
      Order_id,
    } = dataModal.user
    setload(true)
    const uid = window.localStorage.getItem('id')
    await postAction(urlPost, {
      ...dataPayment,
      Name: name,
      LastName: lastname,
      Email: email,
      Country: respCountry[paisIndex].code,
      City: estado_lbl,
      Telephone: phone,
      PaymentMethod: dataModal.paymentType ? 'onlinePayment' : 'transfer',
      Amount: Amount,
      Currency: Currency,
      Order_id: Order_id,
      CouponId: handleCoupon?.id ? handleCoupon.id : null,
      ProgramManagers: orders,
    }).then((response) => {
      setload(false)
      if (response && validateStatus(response.status)) {
        if (response.data !== 'already_exists') {
          const { rolsUserToReturn, user, token, institutions } = response.data
          if (!uid) {
            window.localStorage.setItem('id', user.id)
            window.localStorage.setItem('token_seguridad', token)
            startSession(
              user,
              rolsUserToReturn ? rolsUserToReturn : institutions,
              [],
              true,
            )
          }
        } else {
          if (response.data === 'already_exists') {
            changeModal(
              true,
              'Tu cuenta ya existe',
              'Debes iniciar sesion para poder continuar con el pago',
              'Iniciar sesion',
              () => history.push('/auth/login'),
            )
          }
        }
        if (dataModal.paymentType) {
          document.getElementById('id-page').submit()
          changeModal(false, null)
          // history.push(`/order-result?result=${Order_id}&order_id=TAR-2132`)
        } else {
          history.push(`/order/${response.data.order}`)
        }
        setview(!view)
      }
    })
  }

  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        <H2 textAlign="center">Finalizar compra</H2>
        <form
          style={{ display: view ? '' : 'none' }}
          target="form-page"
          action="https://www.livees.net/Checkout/api4"
          method="POST"
          id="id-page"
        >
          <div className="ShoppingAside">
            <div
              className="ShoppingAside__form"
              // style={{ width: disabledPaymentType ? '98%' : '48%' }}
              // style={{
              //   width:
              //     response?.plus_code &&
              //     response.plus_code.compound_code.includes('Bolivia')
              //       ? '48%'
              //       : '98%',
              // }}
              style={{
                width: '48%',
              }}
            >
              <H2 textAlign="center">Datos del usuario</H2>
              <UserInput
                dataModal={dataModal}
                setDataModal={setDataModal}
                respCountry={respCountry}
                loadCountry={loadCountry}
                orderResult={orderResult}
              />
            </div>
            {/* {response?.plus_code &&
            response.plus_code.compound_code.includes('Bolivia') ? ( */}
            <div className="ShoppingAside__paymentType">
              <H2 textAlign="center">Método de pago</H2>
              <div
                className={`ShoppingAside__option ${
                  dataModal.paymentType ? 'checked' : ''
                }`}
                onClick={() => handleModal('paymentType', true)}
              >
                <H4 fontWeight="600" margin="10px 0 5px">
                  Pagar usando tarjeta
                </H4>
                <div className="ShoppingAside__paymentImage">
                  <img
                    src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472643/assets/1024px-Old_Visa_Logo.svg_af0gri_idfw4f.png"
                    alt="Logo de Visa"
                  />
                  <img
                    src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472642/assets/MasterCard_early_1990s_logo_akmuca_afg1xs.png"
                    alt="Logo de MasterCard"
                  />
                </div>
              </div>

              <div
                className={`ShoppingAside__option ${
                  !dataModal.paymentType ? 'checked' : ''
                }`}
                onClick={() => handleModal('paymentType', false)}
              >
                <H4 fontWeight="600" margin="10px 0 5px">
                  Lista de cuentas bancarias
                </H4>
                <H4 margin="0">
                  Al realizar el pedido podrá ver las cuentas a las cuales puede
                  realizar el deposito bancario y posteriormente se le pasará
                  los enlaces correspondientes.
                </H4>
              </div>
            </div>
            {/* ) : null} */}
          </div>
          {load ? (
            <Loading />
          ) : (
            <ActionButton
              onClick={handleOnSubmit}
              onCancel={onClose}
              textBtn="Ir al pago"
              textCancel={textClose}
              type="button"
              disabled={handleDisabled()}
            />
          )}
        </form>
        <div
          style={{ display: !view ? '' : 'none' }}
          className="ShoppingAside__iframe"
        >
          {dataShopping.currency === 'USD' && (
            <H3 fontWeight="500" margin="0">
              Nota: Si se encuentra en Bolivia tiene la opción de cambiar a su
              moneda local
            </H3>
          )}
          <iframe
            title="iframe"
            id="form-page"
            name="form-page"
            // scrolling="no"
          ></iframe>
          <div className="PaymentMethods__btn"></div>
        </div>
      </>
    )
  }
}
const UserInput = ({
  dataModal,
  setDataModal,
  respCountry,
  loadCountry,
  orderResult,
}) => {
  function handleOnChange(e) {
    const { name, value } = e.target
    setDataModal((a) => ({
      ...a,
      user: {
        ...a.user,
        [name]: value,
      },
    }))
  }
  const { response: respCities, loading: loadCities, reload } = useAxios({
    method: 'GET',
    url: dataModal.user.paisIndex
      ? `/Country/${respCountry[dataModal.user.paisIndex].code}/cities`
      : null,
  })
  function handleDropdown(list, label, key, value) {
    let aux = [{ key: `${label}:`, value: '' }]
    if (validateArray(list)) {
      list
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((element, index) => {
          aux.push({
            key: element[key],
            value: value ? element[value] : index,
          })
        })
    }
    return aux
  }
  useEffect(() => {
    if (dataModal.user.paisIndex) {
      reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataModal.user.paisIndex])
  return (
    <>
      <input
        type="hidden"
        name="_"
        defaultValue="6n254pj3q89mth8c81f5u9yo9as2xbdglw46fvz7k908er5i0"
      />
      <input
        type="hidden"
        name="__"
        defaultValue="ae8jg10ayld1pb7fcf7an1tm21b5burz943992hsq6vkwo6ix"
      />
      <input
        type="hidden"
        name="postURL"
        defaultValue={`${config.domainURL}/${orderResult}`}
      />
      <input
        type="hidden"
        name="amt2"
        defaultValue={`${dataModal.user.Amount}.00`}
      />
      <input
        type="hidden"
        name="currency"
        defaultValue={dataModal.user.Currency}
      />
      <input
        type="hidden"
        name="invno"
        defaultValue={dataModal.user.Order_id}
      />
      <SimpleInput
        name="name"
        type="text"
        label="Nombres *"
        onChange={handleOnChange}
        value={dataModal.user.name}
        err={dataModal.user.name === '' ? 'Campo requerido' : ''}
      />
      <SimpleInput
        label="Apellidos *"
        type="text"
        name="lastname"
        onChange={handleOnChange}
        value={dataModal.user.lastname}
        err={dataModal.user.lastname === '' ? 'Campo requerido' : ''}
      />
      <SimpleInput
        label="Correo electrónico *"
        type="email"
        name="email"
        onChange={handleOnChange}
        value={dataModal.user.email}
        err={dataModal.user.email === '' ? 'Campo requerido' : ''}
      />
      {!loadCountry && (
        <SimpleSelect
          name="paisIndex"
          label="País *"
          options={handleDropdown(respCountry, 'País', 'name')}
          onChange={handleOnChange}
          err={dataModal.user.paisIndex === '' ? 'Campo requerido' : ''}
        />
      )}
      <input
        type="hidden"
        name="pais"
        defaultValue={
          respCountry?.length > 0 && dataModal.user.paisIndex
            ? respCountry[dataModal.user.paisIndex].code
            : ''
        }
      />
      {!loadCities && (
        <SimpleSelect
          name="estado_lbl"
          label="Departamento *"
          options={handleDropdown(respCities, 'Departamento', 'name', 'name')}
          onChange={handleOnChange}
          err={dataModal.user.estado_lbl === '' ? 'Campo requerido' : ''}
        />
      )}
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
        <span
          style={{
            background: '#b3b3b3',
            padding: '0 10px',
            height: '40px',
            textAlign: 'center',
            lineHeight: '40px',
            borderRadius: '5px',
            fontSize: '1.6rem',
          }}
        >
          {respCountry?.length > 0 && dataModal.user.paisIndex
            ? respCountry[dataModal.user.paisIndex].phoneCode
            : null}
        </span>
        <SimpleInput
          label="Teléfono o celular *"
          type="text"
          name="phone"
          onChange={handleOnChange}
          value={dataModal.user.phone}
          err={dataModal.user.phone === '' ? 'Campo requerido' : ''}
        />
      </div>
    </>
  )
}
export default ShoppingAside

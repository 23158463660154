import './style/Header.css'
import { Link, withRouter } from 'react-router-dom'
import { Sidebar } from '../navigation'
import { ButtonModalS } from '../buttons'

const Header = (props) => {
  const { headerTitle, location, membership, sesionUser, history } = props

  function clickHandler(e, element) {
    history.push('/')
    if (location.pathname === '/') {
      e.preventDefault()
      document
        .getElementById(element)
        .scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }

  return (
    <>
      <div className="Header">
        {sesionUser.authenticate ? <Sidebar /> : null}
        <Link to="/">{headerTitle}</Link>
        <div className="Header__option">
          {sesionUser.authenticate ? null : (
            <ul className="Header__list">
              <li>
                <a onClick={(e) => clickHandler(e, 'home')} href="#">
                  INICIO
                </a>
              </li>
              {location.pathname === '/' ? (
                <>
                  <li>
                    <a onClick={(e) => clickHandler(e, 'aboutUs')} href="#">
                      ACERCA DE
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(e) => clickHandler(e, 'whatIsFunnel')}
                      href="#"
                    >
                      ¿QUÉ ES FUNNEL?
                    </a>
                  </li>
                </>
              ) : null}
              {/* <li>
                <Link to="/catalogue/short-courses">CONTENIDO</Link>
              </li> */}
              {location.pathname === '/' ? (
                <li>
                  <a onClick={(e) => clickHandler(e, 'contact')} href="#">
                    CONTACTANOS
                  </a>
                </li>
              ) : null}
            </ul>
          )}
          <ButtonModalS {...props} />
        </div>
      </div>
      {(location.pathname.includes('/catalogue/short-courses') &&
        membership?.status !== 'completed') ||
      (location.pathname.includes('/graduates/') &&
        location.pathname.includes('/course') &&
        membership?.status !== 'completed') ? (
        <HeaderBanner />
      ) : null}
    </>
  )
}

const HeaderBanner = () => {
  return (
    /*<Link style={{ textDecoration: 'none' }} to="/payment">
      <div className="HeaderBanner">
        <div className="HeaderBanner__main">
          <H3 margin="0" fontSize="5rem" color="var(--white)" fontWeight="500">
            14.<span style={{ fontSize: '2.5rem' }}>99</span> SUS
          </H3>
          <div className="HeaderBanner__text">
            <H3 margin="0" color="var(--white)" fontWeight="700">
              Plan Mensual
            </H3>
            <H3 margin="0" color="var(--white)" fontWeight="500">
              Adquiere una suscripción en nuestra plataforma
            </H3>
          </div>
        </div>
        <NormalLink to="/payment" background="var(--white)">
          Ver planes
        </NormalLink>
      </div>
    </Link>*/
    <></>
  )
}

export default withRouter(Header)

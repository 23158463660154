import { Login, Register, Recover, PasswordRecovery } from "../components/form";
import { Switch, Route } from "react-router-dom";
import AssingRol from "../components/form/auth/AssingRol";
const Auth = () => {
  return (
    <Switch>
      <Route path="/auth/register" component={Register} />
      <Route path="/auth/login" component={Login} />
      <Route path="/auth/recover" component={Recover} />
      <Route path="/auth/test-vocacional-login" component={Login} />
      <Route path="/auth/test-vocacional-register" component={Register} />
      <Route path="/auth/register/rol" component={AssingRol} />
      <Route
        path="/auth/passwordrecovery/:verifyKey"
        component={PasswordRecovery}
      />
    </Switch>
  );
};

export default Auth;

import { useEffect } from 'react'
import { H3 } from '../../components/text'
import { withRouter } from 'react-router-dom'
import { LoadingScreen } from '../../components/common'
import { openSnackbar } from '../../store/actions'
import { postAction } from '../../services/action/ActionAuthorization'
import { validateStatus } from '../../utils/Validation'
import { connect } from 'react-redux'
const ConfirmEmail = (props) => {
  const { token } = props.match.params
  useEffect(() => {
    async function onSubmit() {
      await postAction('/Auth/VerifyEmail', {
        VerifyKey: token,
      }).then((res) => {
        if (validateStatus(res.status)) {
          props.openSnackbar('Correo confirmado', true, true)
        } else {
          props.openSnackbar('El enlace no es valido', true, false)
        }
        props.history.push('/select/rol')
      })
    }
    onSubmit()
  }, [])

  return (
    <LoadingScreen>
      <H3>Confirmando Correo Electrónico...</H3>
    </LoadingScreen>
  )
}

export default connect(null, { openSnackbar })(withRouter(ConfirmEmail))

export const openSnackbar = (content, status, kind) => ({
  type: 'OPEN_SNACKBAR',
  content,
  status,
  kind,
})
export const changeLayout = (layoutStyle, navigation = true) => {
  let layout = {
    content: `"Nav Navbar Navbar Navbar" "Nav Conten Conten Inf"`,
    responsive: `"Navbar" "Conten" "Inf" "Inf"`,
  }
  switch (layoutStyle) {
    case 'Home':
      layout = {
        content: `"Navbar Navbar Navbar Navbar" "Conten Conten Conten Conten"`,
        responsive: `"Navbar" "Conten" "Conten" "Conten"`,
      }
      break
    case 'FullScreen':
      layout = {
        content: `"Nav Navbar Navbar Navbar" "Nav Conten Conten Conten"`,
        responsive: `"Navbar" "Conten" "Conten" "Conten"`,
      }
      break
    case 'FullContent':
      layout = {
        content: `"Conten Conten Conten Conten" "Conten Conten Conten Conten"`,
        responsive: `"Conten" "Conten" "Conten" "Conten"`,
      }
      break
    case 'ContentOnly':
      layout = {
        content: `"Conten Conten Conten Conten" "Conten Conten Conten Conten"`,
        responsive: `"Conten" "Conten" "Conten" "Conten"`,
      }
      break
    case 'BigInformation':
      layout = {
        content: `"Navbar Navbar Navbar Navbar" "Conten Conten Inf Inf"`,
        responsive: `"Navbar" "Conten" "Inf" "Inf"`,
      }
      break
    case 'Information':
      layout = {
        content: `"Navbar Navbar Navbar Navbar" "Conten Inf Inf Inf"`,
        responsive: `"Navbar" "Conten" "Inf" "Inf"`,
      }
      break
    default:
      break
  }
  return {
    type: 'CHANGE_LAYOUT',
    navigation: navigation,
    ...layout,
  }
}
export const changeModal = (
  status,
  title,
  message,
  butttonText,
  onClick,
  width,
) => ({
  type: 'CHANGE_MODAL',
  status,
  title,
  message,
  butttonText,
  onClick,
  width,
})
export const changeHeader = (data) => ({
  type: 'CHANGE_HEADER',
  data,
})
export const changeMembership = (data) => ({
  type: 'CHANGE_MEMBERSHIP',
  data,
})

export const changeTheme = (colors) => ({
  type: 'CHANGE_THEME',
  colors,
})
export const startSession = (user, rols, institutions, status) => ({
  type: 'START_SESSION',
  user,
  rols,
  institutions,
  status,
})
export const changeSession = (user, rols, institutions, status) => ({
  type: 'CHANGE_SESSION',
  user,
  rols,
  institutions,
  status,
})
export const closeSession = () => ({
  type: 'CLOSE_SESSION',
})
export const updateUser = (user) => ({
  type: 'UPDATE_USER',
  user,
})
export const changeLanguageAction = (newLang) => ({
  type: 'CHANGE_LANGUAGE',
  newLang,
})
export const changeInfo = (data) => ({
  type: 'CHANGE_INFO',
  data,
})

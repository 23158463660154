import React, { useState, useEffect } from 'react';
import "./GenerateResource.css";
import config from "../../../src/config.json";


function GenerateResource() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      text: message,
    };

    try {
      document.getElementById('loader').style.display = 'block';

      const responses = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      };

      //const response = await fetch(config.static + "http://164.92.67.59:4050/api/page/GenerateResource", responses);
      const response = await fetch(config.requestURL + "/page/GenerateRecourse", responses);


      if (!response.ok) {
        throw new Error('La solicitud no fue exitosa');
      }

      const data = await response.json();
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: message, role: 'user' },
      ]);
      
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: data.text, role: 'assistant' },
      ]);
      
      setMessage('');
      document.getElementById('loader').style.display = 'none';
    } catch (error) {
      document.getElementById('loader').style.display = 'none';
      console.error('Error al enviar el mensaje:', error);
    }
  };

  useEffect(() => {
    ;
  }, [messages]);

  return(
    <>
    <h2 className='title' >GENERADOR DE IDEAS</h2>
      <div id="loader" className="loader"></div>
      <div className="chat-container">
          {messages.map((msg, index) => (
            <div className="chat">
              <div key={index} className={`message_${msg.role}`}>
                {msg.content}
              </div>
            </div>
          ))}
        <form onSubmit={handleSubmit} className="input-form">
          <input
            type="text"
            value={message}
            onChange={handleChange}
            placeholder="Escribe un mensaje..."
            className="message-input"
          />
          <button type="submit" className="send-button">Enviar</button>
        </form>
      </div>
    </>
  );
}
export default GenerateResource;


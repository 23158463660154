import "./style/Navbar.css";
import { validateArray, validateInstitution } from "../../utils/Validation";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

const Navbar = (props) => {
  const { institutions, authenticate, rols, user } = props.sesionUser;
  const isAdmin = validateInstitution(institutions, "admin");
  const isSeller = validateInstitution(institutions, "sales");
  const isManager = validateInstitution(institutions, "manager");
  const isHumanResources = validateInstitution(institutions, "humanResources");
  const isAffiliate = validateInstitution(institutions, "affiliate");

  const isSocialNetwork = validateInstitution(institutions, "socialnetwork");
  const isEducator = validateInstitution(institutions, "educator");

  const { t } = useTranslation("sidebarT");
  return (
    <div className="Navbar">
      <nav className="Navbar__nav">
        <div className="Navbar__liks">
          {/* <LinkNavbar to="/" icon="home">
            {t("sidInicio")}
          </LinkNavbar> */}
          {/* <LinkNavbar to="/about" icon="handshake">
              ¿Quienes somos?
            </LinkNavbar> */}
          <LinkNavbar to="/catalogue/short-courses/4" icon="book-open">
            Catalogo
          </LinkNavbar>
          {user.father && (
            <LinkNavbar to="/kids" icon="users">
              Mis niñ@s
            </LinkNavbar>
          )}
          {authenticate && (
            <>
              <LinkNavbar to="/programs" icon="chalkboard-teacher">
                Mis Compras
              </LinkNavbar>
              {validateArray(isEducator) ? (
                <LinkNavbar to="/courses" icon="briefcase">
                  Mis Cursos
                </LinkNavbar>
              ) : null}
              {validateArray(isAdmin) &&
                (isAdmin.length === 1 ? (
                  <LinkNavbar
                    to={`/institution/${isAdmin[0].id}`}
                    icon="university"
                  >
                    Institución
                  </LinkNavbar>
                ) : (
                  <LinkNavbar to="/institution" icon="university">
                    Institución
                  </LinkNavbar>
                ))}
              {validateArray(isSeller) || validateArray(isSocialNetwork) ? (
                <>
                  {/* <LinkNavbar to="/contacts" icon="users">
                      Contactos
                    </LinkNavbar> */}
                  <LinkNavbar to="/resume-contact" icon="users">
                    Contactos
                  </LinkNavbar>
                </>
              ) : null}
              {validateArray(isSeller) ||
              validateArray(isSocialNetwork) ||
              validateArray(isManager) ||
              validateArray(isAdmin) ? (
                <>
                  {/* <LinkNavbar to="/calendar/viewSchule/" icon="calendar-alt">
                    Horarios
                  </LinkNavbar> */}
                </>
              ) : null}
              {validateArray(isAffiliate)|| validateArray(isManager) ?(
                <>
                <LinkNavbar to="/manager" icon="chart-pie">
                  Reportes
                </LinkNavbar>
                </>
              ) : null}
              {validateArray(isHumanResources) ? (
                <LinkNavbar to="/admin" icon="user-cog">
                  Administración
                </LinkNavbar>
              ) : null}

              {validateArray(isAffiliate) ? (
                <LinkNavbar to="/affiliate" icon="filter">
                  Productos Afiliados
                </LinkNavbar>
              ) : null}  
              {validateArray(isEducator)  ? (
                <LinkNavbar to="/GenerateResource" icon="fad fa-brain">
                  Generador de Ideas
                </LinkNavbar>
              ) : null}       

              {/* <LinkNavbar to="/account" icon="user-circle">
                  {t("sidCuenta")}
                </LinkNavbar>*/}
            </>
          )}
          {/*<LinkNavbar to="/questions" icon="question-circle">
              {t("sidPreguntas")}
            </LinkNavbar>*/}
        </div>
      </nav>
    </div>
  );
};

const LinkNavbar = (props) => {
  const { to, icon, children } = props;
  return (
    <NavLink
      to={to}
      exact
      className={({ isActive, isPending }) =>
        isPending
          ? "LinkNavbar LinkNav pending"
          : isActive
          ? "LinkNavbar LinkNav active"
          : "LinkNav"
      }
    >
      <i className={`fas fa-${icon} nav__linkIcon`}></i>
      <span className="LinkNavbar__linkText">{children}</span>
    </NavLink>
  );
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, null)(Navbar);

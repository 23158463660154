import "./CourseInfo.css";
import { connect } from "react-redux";
import { useSize } from "../../../../hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { changeLayout } from "../../../../store/actions";
import { ImgDefault } from "../../../../components/image";
import { NormalLink } from "../../../../components/buttons";
import { validateArray } from "../../../../utils/Validation";
import { H2, H4, Section } from "../../../../components/text";
import { LoadingScreen } from "../../../../components/common";
import { useAxiosAuth } from "../../../../hooks";

function CourseInfo({ changeLayout }) {
  const { t } = useTranslation("CourseInfo");
  const [target, currentSize] = useSize();
  const course = {
    id: 17,
    name: "DIPLOMADO EN BIG DATA Y MACHINE LEARNING",
    photoUrl:
      "https://res.cloudinary.com/pataformacgi/image/upload/v1655472643/assets/Mask_Group_52_e4hbs0_ph079m.png",
    description:
      "El Diplomado en Big Data y Machine Learning, pretende  formar líderes en la gestión, análisis y procesamiento de un gran volumen de datos, los cuales no pueden ser tratados con herramientas de software convencional, (lo cual genera un aumento en la rentabilidad de la empresa), mediante la implementación de  algoritmos de Machine Learning con la información recabada para la optimización de los procesos y servicios empresariales.",
    courseAchievements:
      "Permite a los participantes conocer la problemática de Big Data, las plataformas más importantes y las técnicas que permiten manejar esa data para comprender e implementar los principales algoritmos de Machine Learning y presentar visualmente la información en forma efectiva.",
    addressedTo:
      "Ingenieros en sistemas, licenciados en informática, programadores, ingenieros electrónicos,  y personas del área tecnológica que desean implementar mejoras en grandes empresas del área económica e industrial.",
    courses: [
      {
        id: 1,
        name: "FUNDAMENTOS DE BIG DATA",
        description:
          "El Big Data es un concepto que engloba a muchos otros. Está formado por un ecosistema de tecnologías que, combinadas entre sí, nos aportan la potencia necesaria para extraer valor al dato y hacer que nuestra organización sea Data Driven.",
        modules: [
          {
            id: 1,
            name: "UNIDAD I: DEFINICIONES DEL BIG DATA",
          },
          {
            id: 2,
            name: "UNIDAD II: GESTIÓN Y CASOS BASADOS EN DATOS",
          },
          {
            id: 3,
            name: "UNIDAD III: HERRAMIENTAS DE BIG DATA",
          },
          {
            id: 4,
            name: "UNIDAD IV: HADOOP",
          },
        ],
      },
      {
        id: 2,
        name: "IMPLEMENTACIÓN DE BIG DATA Y MANEJO DE DATOS",
        description:
          "Las organizaciones recopilan datos de diversas fuentes, como transacciones comerciales, dispositivos inteligentes (IO), equipo industrial, vídeos, medios sociales y más. En el pasado, su almacenamiento habría sido un problema - pero el almacenamiento más barato en plataformas como los data lakes y el Hadoop han aliviado la carga.",
        modules: [
          {
            id: 1,
            name: "UNIDAD I: DISEÑO DE DATOS",
          },
          {
            id: 2,
            name: "UNIDAD II: MODELADO DE DATOS",
          },
          {
            id: 3,
            name: "UNIDAD III: ARQUITECTURA DE DATOS",
          },
          {
            id: 4,
            name: "UNIDAD IV: DETERMINACIÓN E INTEGRACIÓN DE DATOS",
          },
          {
            id: 5,
            name: "UNIDAD V: SOLUCIONES BASADAS EN DATOS",
          },
        ],
      },
      {
        id: 3,
        name: "DATA MINING",
        description:
          "El Data Mining es un conjunto de técnicas y tecnologías que permiten explorar grandes bases de datos, de manera automática o semiautomática, con el objetivo de encontrar patrones repetitivos que expliquen el comportamiento de estos datos",
        modules: [
          {
            id: 1,
            name: "UNIDAD I: FUNDAMENTOS DE DATA MINING",
          },
          {
            id: 2,
            name: "UNIDAD II: MÉTODOS PREDICTIVOS DE DATA MINING",
          },
          {
            id: 3,
            name: "UNIDAD III: ARBOLES DE DECISIÓN",
          },
          {
            id: 4,
            name: "UNIDAD IV: MINERÍA DE DATOS EN BIG DATA",
          },
        ],
      },
      {
        id: 4,
        name: "MACHINE LEARNING",
        description:
          "Es un método de análisis de datos que automatiza la construcción de modelos analíticos. Es una rama de la inteligencia artificial basada en la idea de que los sistemas pueden aprender de datos, identificar patrones y tomar decisiones con mínima intervención humana.",
        modules: [
          {
            id: 1,
            name: "UNIDAD I: FUNDAMENTOS DE MACHINE LEARNING",
          },
          {
            id: 2,
            name: "UNIDAD II: MÉTODOS DE APRENDIZAJE",
          },
          {
            id: 3,
            name: "UNIDAD III: APLICACIONES DEL MACHINE LEARNING",
          },
          {
            id: 4,
            name: "UNIDAD IV: HERRAMIENTAS PARA MACHINE LEARNING",
          },
        ],
      },
      {
        id: 5,
        name: "ANÁLISIS ESTADÍSTICO",
        description:
          "Ambas disciplinas (Big Data y Estadística) organizan los datos de tal forma que estos resulten claros y comprensibles. A su vez, tienen un segundo objetivo definido: la toma de decisiones. Es con la información analizada que pueden diseñarse caminos a recorrer y acciones a realizar, por lo que tanto la Estadística como el Big Data pueden definirse como necesarios para decidir, la gran diferencia entre ellos, es que el Big Data emplea mayores fuentes de información que analiza de forma cruzada.",
        modules: [
          {
            id: 1,
            name: "UNIDAD I: ESTADÍSTICA Y BIG DATA ",
          },
          {
            id: 2,
            name: "UNIDAD II: ESTADÍSTICA EN EL MACHINE LEARNING",
          },
          {
            id: 3,
            name: "UNIDAD III: CÁLCULO Y MODELO DE PROBABILIDAD ENFOCADO AL MACHINE LEARNING",
          },
        ],
      },
      {
        id: 6,
        name: "PROYECTO FINAL DE BIG DATA",
        description:
          "El proyecto de investigación a presenta los antecedentes, concepto y la realidad de la nueva tecnología emergente, Big data. Además, como parte del alcance, se realiza un análisis de las tendencias sobre las tecnologías, metodologías, herramientas y sus limitaciones",
        modules: [
          {
            id: 1,
            name: "UNIDAD I: FUTURO DEL BIG DATA",
          },
          {
            id: 2,
            name: "UNIDAD II: APLICACIONES DEL BIG DATA",
          },
          {
            id: 3,
            name: "UNIDAD III: DESARROLLO DE PROYECTO FINAL",
          },
        ],
      },
    ],
  };
  const uId = window.localStorage.getItem("id");
  const { response, loading } = useAxiosAuth({
    method: "GET",
    url: uId ? `/users/${uId}/preregister` : null,
  });

  useEffect(() => {
    changeLayout("FullScreen");
  }, [changeLayout]);

  const preRegistrations = response
    ? response.filter((pre) => {
        return pre.signedProgramId === 17;
      })
    : null;

  if (!loading) {
    return (
      <div className="CourseInfo">
        <div className="CourseInfo__header">
          <div ref={target} className="CourseInfo__Htitle">
            <H2 color="var(--gray)">{course.name}</H2>
            <ImgDefault
              url={course.photoUrl}
              className="CourseInfo__Himg"
              currentSize={currentSize}
            />
          </div>
          <div className="CourseInfo__Hcontact">
            <ContactHeader preRegistrations={preRegistrations} />
          </div>
        </div>
        <div className="CourseInfo__content">
          <div className="CourseInfo__information">
            <ListCard list={course ? course.description : ""}>
              {t("descriptionDiploma")}
            </ListCard>
            <ListCard list={course ? course.courseAchievements : ""}>
              {t("learn")}
            </ListCard>
            <ListCard list={course ? course.addressedTo : ""}>
              {t("whoIsDiploma")}
            </ListCard>
          </div>
          <div className="CourseInfo__Content">
            <H2 color="var(--gray)">{t("diplomaContent")}</H2>
            {course
              ? course.courses
                  .sort((a, b) => a.order - b.order)
                  .map(function (l) {
                    return (
                      <CardModule
                        key={l.id}
                        modules={l.modules}
                        description={l.description}
                      >
                        {l.name}
                      </CardModule>
                    );
                  })
              : null}
          </div>
        </div>
      </div>
    );
  } else {
    return <LoadingScreen />;
  }
}
function ContactHeader({ preRegistrations }) {
  return (
    <div>
      <H2 textAlign="center" color="var(--gray)">
        Contacto
      </H2>
      <Section>
        Para mayor información sobre nuestra promoción y planes de pago
        contáctanos
      </Section>
      <H4 fontWeight="400" textAlign="center">
        <i className="fas fa-headset"></i> <span>+591 2-2312135</span>
      </H4>
      <H4 textAlign="center" fontWeight="400">
        <i className="fab fa-whatsapp"></i> <span>+591 78960810</span>
      </H4>
      <H4 textAlign="center" fontWeight="400">
        <i className="fab fa-whatsapp"></i> <span>+591 76702369</span>
      </H4>
      <div className="ContactHeader__btn">
        <NormalLink
          background="#193765"
          to={`/graduates/123/enrollment${
            validateArray(preRegistrations) ? "/" + preRegistrations[0].id : ""
          }`}
        >
          Inscríbete al diplomado
        </NormalLink>
      </div>
    </div>
  );
}

const ListCard = (props) => {
  return (
    <div className="ListCard">
      <H4 color="var(--purple)" fontWeight="500" margin="0" fontSize="1.8rem">
        {props.children}
      </H4>
      <hr />
      <div style={{ margin: "8px 1%" }}>
        <Section>{props.list}</Section>
      </div>
    </div>
  );
};

function CardModule({ children, description, modules }) {
  const [view, setView] = useState(false);
  return (
    <div className="CardModule">
      <div
        style={{ background: "#193765" }}
        className="CardModule__card"
        onClick={() => {
          setView(!view);
        }}
      >
        <div className="CardModule__title">
          <H4 margin="0" fontSize="1.6rem" color="var(--white)">
            {children}
          </H4>
          <i className={`fas fa-angle-${view ? "down" : "right"}`}></i>
        </div>
        {view ? <Section color="var(--white)">{description}</Section> : null}
      </div>
      {view ? (
        <div className="CardModule__list">
          {validateArray(modules)
            ? modules
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((module) => (
                  <div key={module.id} className="CardLesson">
                    <H4 margin="0" fontSize="1.4rem" color="var(--black)">
                      {module.name}
                    </H4>
                  </div>
                ))
            : null}
        </div>
      ) : null}
    </div>
  );
}
export default connect(null, { changeLayout })(CourseInfo);

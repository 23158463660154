import styled from 'styled-components'
const BoxFlexStyle = styled.div`
  display: flex;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  gap: ${(props) => props.gap};
  flex-wrap: ${(props) => props.wrap};
  justify-content: ${(props) => props.justify};
  margin: ${(props) => props.margin};
  @media screen and (max-width: 425px) {
    flex-direction: ${(props) => props.direction};
    gap: 5px;
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  }
`
const BoxFlex = (props) => {
  const {
    children,
    justify = 'center',
    gap = '10px',
    direction = 'column',
    wrap = 'wrap',
    className,
    margin = 0,
    alignItems = 'center',
    ...rest
  } = props
  return (
    <BoxFlexStyle
      className={className}
      direction={direction}
      justify={justify}
      gap={gap}
      wrap={wrap}
      margin={margin}
      alignItems={alignItems}
      style={rest}
    >
      {children}
    </BoxFlexStyle>
  )
}

export default BoxFlex

import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { useState } from 'react'
import './style/Inputs.css'
import InputStyle from './style/InputStyle'
const Input = (props) => {
  const {
    label,
    name,
    disabled,
    labelColor = '#000',
    type,
    index,
    ...rest
  } = props

  const [check, setcheck] = useState({
    typeInput: 'password',
  })
  const handleCheck = (e) => {
    const { checked } = e.target
    setcheck({
      typeInput: checked ? 'text' : 'password',
    })
  }

  return (
    <>
      <InputStyle className="form-control" type={type}>
        <label style={{ color: labelColor }} htmlFor={name}>
          {label}
        </label>
        <Field
          id={name}
          name={name}
          disabled={disabled}
          type={type !== 'password' ? type : check.typeInput}
          step={type === 'time' ? '2' : null}
          className="switch"
          {...rest}
        />
        {type === 'password' && (
          <div className="Input__check">
            <label htmlFor="checkBox">Mostrar contraseña</label>
            <input
              // onFocus={true}
              onChange={handleCheck}
              type="checkbox"
              name="checkBox"
            />
          </div>
        )}
      </InputStyle>
      <div className="">
        <ErrorMessage component={TextError} name={name} />
      </div>
    </>
  )
}

export default Input

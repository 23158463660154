import { connect } from 'react-redux'
import { changeLayout } from '../../store/actions'
import { useEffect } from 'react'
import './Welcome.css'
import '../Rol/Rol.css'
import { Link } from 'react-router-dom'
import { H2, H3 } from '../../components/text'
import { validateArray, validateInstitution } from '../../utils/Validation'

const Welcome = (props) => {
  const {
    changeLayout,
    match: {
      params: { type },
    },
  } = props
  const { institutions } = props.sesionUser
  const isEducator = validateInstitution(institutions, 'educator')
  const isAffiliate = validateInstitution(institutions, 'affiliate')

  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  return (
    <div className="Welcome">
      <div className="Rol__container">
        <div className="Rol__background">
          <section className="Welcome__section">
            <H2
              fontSize="4rem"
              color="white"
              textAlign="center"
              margin="0 0 20px"
            >
              {type === 'afiliate'
                ? 'Bienvenid@ te registraste como Afiliado'
                : type === 'educator'
                ? 'Bienvenid@ te registraste como Creador'
                : ''}
            </H2>
            <div className="Welcome__text">
              <H3 color="white" fontWeight="normal" fontSize="2rem">
                {type === 'afiliate'
                  ? `¡Nos encanta colaborar contigo! Como afiliado, dispondrás de
                múltiples recursos y herramientas de marketing, como enlaces
                personalizados y materiales de promoción preestablecidos, para
                aumentar tus ingresos atrayendo clientes potenciales.`
                  : type === 'educator'
                  ? `Te ayudamos a crear y ofrecer tus propios cursos en línea de
                manera fácil y accesible, independientemente de tu nivel de
                experiencia en la materia. Te brindamos herramientas desde la
                planificación del contenido hasta la creación de materiales de
                apoyo, guiándote en cada paso del proceso para crear un curso
                atractivo, informativo y efectivo.`
                  : ''}
              </H3>
              <img
                src={
                  type === 'afiliate'
                    ? 'https://res.cloudinary.com/ahsoizdev/image/upload/v1678126227/midas/pexels-photomix-company-95916_nbnrp0.jpg'
                    : type === 'educator'
                    ? 'https://res.cloudinary.com/ahsoizdev/image/upload/v1678126410/midas/pexels-pixabay-414628_nu9lzh.jpg'
                    : ''
                }
                alt="images"
              />
            </div>
            <div className="Welcome__btn">
              {validateArray(isEducator) &&
              validateArray(isAffiliate) ? null : (
                <Link
                  to={'/Select/rol'}
                  className="btn-wel button animate__animated animate__backInUp"
                >
                  Seleccionar un rol mas
                </Link>
              )}
              {validateArray(isEducator) && validateArray(isAffiliate) ? (
                <>
                  <Link
                    to="/catalogue/short-courses/4"
                    className="btn-wel button animate__animated animate__backInUp"
                  >
                    Continuar como afiliado
                  </Link>
                  <Link
                    to="/courses"
                    className="btn-wel button animate__animated animate__backInUp"
                  >
                    Continuar como Creador
                  </Link>
                </>
              ) : (
                <Link
                  to={
                    type === 'afiliate'
                      ? '/catalogue/short-courses/4'
                      : type === 'educator'
                      ? '/courses'
                      : ''
                  }
                  className="btn-wel button animate__animated animate__backInUp"
                >
                  Continuar
                </Link>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
})(Welcome)

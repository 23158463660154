import React from "react";
import "../NewHome.css";
import { VideoPlayer } from "../../../components/common";
import { connect } from "react-redux";
import { changeLayout } from "../../../store/actions";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NewAbout = (props) => {
  const { changeLayout } = props;
  useEffect(() => {
    changeLayout("ContentOnly");
  }, [changeLayout]);

  return (
    <div className="">
      <div id="home" className="NewHome__head">
        <div className="Home_OurCourses">
          <div className="EducationVirtual">
            <VideoPlayer
              url="https://player.vimeo.com/video/994528800?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100vw"
              controls={true}
              height="calc(100vh - 150px)"
              playing={true}
              muted={false}
              loop={false}
            />
            <div>
              <br />
              <Link to="/" className="link linkabout">
                Cerrar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  changeLayout,
})(NewAbout);

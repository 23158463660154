import { useState } from 'react'
import { RenderButton } from '../../buttons'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { FormikControl } from '../../input'
import { Loading } from '../../animation/index'
import { postAction } from '../../../services/action/Action'
import { openSnackbar } from '../../../store/actions'
import { connect } from 'react-redux'
const Recover = (props) => {
  const [load, setload] = useState(true)
  const initialValues = {
    UsernameOrEmail: '',
  }

  const validationSchema = Yup.object({
    UsernameOrEmail: Yup.string().required('Required'),
  })
  function save(res) {
    setload(true)
    if (res.status === 200) {
      props.openSnackbar('Enviado revisa tu correo', true, true)
    } else {
      props.openSnackbar('El usuario no existe', true, false)
    }
  }
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction('/auth/RecoveryAccount', values).then((response) => {
        console.log(response)
        save(response)
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }

  return (
    <>
      <br />
      {/* <span className="form__span">Recuperar Contraseña</span> */}
      <span>
        <p className="Change_text">Cambiar contraseña</p>
        <p className="Change_text">
          Indica tu correo electronico y sigue las instrucciones ques se envian
          por correo.
        </p>
      </span>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Correo Electronico o Usuario"
                name="UsernameOrEmail"
                labelColor="white"
              />
              {load ? (
                <RenderButton
                  type="submit"
                  fontSize="1.8rem"
                  background="linear-gradient(90deg, #f0cf0c 0%, #eea417 100%)"
                  width="100%"
                  borderRadius="10px"
                  disabled={!formik.isValid}
                >
                  Enviar Correo
                </RenderButton>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
const mapDispatchToProps = {
  openSnackbar,
}
export default connect(null, mapDispatchToProps)(Recover)

import React, { useEffect } from 'react'
import { validateArray, validateStatus } from '../../../../utils/Validation'
import { LoadCircle } from '../../../../components/animation'
import {
  DefaultBtn,
  ActionButton,
  RenderButton,
} from '../../../../components/buttons'
import { H2, H3, H4 } from '../../../../components/text'
import { StepTips } from '../../../../components/boxes'
import { useSteps } from '../../../../hooks'
import {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
} from '../../../../store/actions'
import { connect } from 'react-redux'
import { CreateLesson } from '../../../../components/form'
import {
  ModulesContainer,
  EditWeighting,
  Deletelessons,
  PendingQualify,
} from '../../../../containers/programs'
import { useActionParam } from '../../../../hooks'
import { getAction } from '../../../../services/action/ActionAuthorization'
import './ModulesRolUser.css'

import { ViewCourseSchedule } from '../../../../containers/institutions/institution/ViewCourseSchedule'
import MonthlyRecords from '../../../../containers/institutions/institution/MonthlyRecords'

const ModulesRolUser = (props) => {
  const {
    match: {
      params: { cid, mid, rol },
    },
    changeInfo,
    openSnackbar,
    changeLayout,
    changeModal,
  } = props

  const amount = 5
  const { step, nextStep, setStep } = useSteps({
    name: 'view-course',
    amount: amount,
    changeModal: changeModal,
    welcome: (
      <>
        <H2 color="var(--gray)">¡Felicitaciones por crear tu curso!</H2>
        <H2 margin="0" fontSize="1.7rem" color="var(--gray)">
          Nos complace regalarte un curso que te ayudará a expandir tus
          habilidades y crear contenido.{' '}
          <a
            href="/program/course/667/module/2269"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <span style={{ color: 'var(--orange2)', cursor: 'pointer' }}>
              Ir al curso multimedia
            </span>
          </a>
        </H2>
        <H3>
          En esta vista podrás ver el contenido de tu cursos, encontrarás lo que
          necesites para gestionar tu curso fácilmente.
        </H3>
      </>
    ),
  })
  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: `/course/${cid}/Modules`,
  })

  useEffect(() => {
    changeLayout()
    // changeInfo(<PendingQualify cid={cid} {...props} />)
  }, [changeLayout, changeInfo])

  function requestReload() {
    changeInfo(<></>)
    reload()
  }

  return (
    <>
      {validateStatus(statusRequest) ? (
        <>
          <H2 margin="0 0 10px" textAlign="center">
            {dataRequest.courseName}
          </H2>
          {/* {dataRequest.parallel && (
            <H2 color="gray" margin="0 0 20px" textAlign="center">
              {dataRequest.parallel}
            </H2>
          )} */}
          <div className="ModulesRolUser__option">
            <RenderButton
              background="#e7e7e7"
              color="var(--gray)"
              onClick={() => {
                setStep(1)
                localStorage.removeItem('view-course')
              }}
            >
              <i className="fas fa-question-circle"></i>
            </RenderButton>
            <StepTips
              step={step}
              nextStep={nextStep}
              stepNumber={5}
              title="Inscribete al curso para crear contenido"
              description="Con este botón podrás inscribirte al curso adicional que te ayudará a expandir tus habilidades y crear contenido."
              amount={amount}
            >
              <RenderButton
                background="#e7e7e7"
                color="var(--gray)"
                typeBtn="link"
                href="/program/course/667/module/2269"
                textDecoration="none"
              >
                Ir al curso multimedia <i className="fas fa-gift"></i>
              </RenderButton>
            </StepTips>
            <StepTips
              step={step}
              nextStep={nextStep}
              stepNumber={4}
              title="Lecciones eliminadas"
              description="Con este botón podrás ver las lecciones que elimines y podrás restaurarlas"
              amount={amount}
            >
              <DefaultBtn
                background={true}
                onClick={() =>
                  changeModal(
                    true,
                    '',
                    <Deletelessons {...props} cid={cid} />,
                    null,
                  )
                }
              >
                Lecciones eliminadas <i className="fas fa-trash"></i>
              </DefaultBtn>
            </StepTips>
            <StepTips
              step={step}
              nextStep={nextStep}
              stepNumber={3}
              title="Registros mensuales"
              description="Con este botón podrás ver la cantidad de personas inscritas por mes a tu curso"
              amount={amount}
            >
              <DefaultBtn
                onClick={() =>
                  changeInfo(<MonthlyRecords cid={cid} {...props} />)
                }
              >
                Registros Mensuales <i className="fas fa-users"></i>
              </DefaultBtn>
            </StepTips>
            <StepTips
              step={step}
              nextStep={nextStep}
              stepNumber={2}
              title="Subir contenido"
              description="Con este botón podrás subir contenido a tu curso, videos, foros y clases virtuales"
              amount={amount}
            >
              <RenderButton
                background="var(--purple)"
                color="black"
                onClick={() =>
                  changeInfo(
                    <CreateLesson
                      cid={cid}
                      mid={mid}
                      rol={rol}
                      reload={requestReload}
                      openSnackbar={openSnackbar}
                      modules={dataRequest.courseModulesDto}
                      changeInfo={changeInfo}
                    />,
                  )
                }
              >
                Subir contenido <i className="fas fa-plus"></i>
              </RenderButton>
            </StepTips>
            {/* <DefaultBtn
              onClick={() =>
                changeInfo(
                  <ViewCourseSchedule
                    changeModal={changeModal}
                    openSnackbar={openSnackbar}
                    reload={reload}
                    {...props}
                  />,
                )
              }
            >
              Horario <i className="fas fa-calendar-alt"></i>
            </DefaultBtn> */}
            {/* <DefaultBtn
              onClick={() =>
                changeInfo(<PendingQualify cid={cid} {...props} />)
              }
            >
              Pendientes por calificar
            </DefaultBtn> */}
          </div>
          <br />
          {validateArray(dataRequest.courseModulesDto)
            ? dataRequest.courseModulesDto
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((mod) => (
                  <React.Fragment key={mod.id}>
                    <ModulesContainer
                      size={dataRequest.courseModulesDto.length}
                      mod={mod}
                      rol={rol}
                      mid={mid}
                      reload={requestReload}
                      changeInfo={changeInfo}
                      openSnackbar={openSnackbar}
                      changeModal={changeModal}
                    />
                  </React.Fragment>
                ))
            : null}
        </>
      ) : (
        <LoadCircle />
      )}
    </>
  )
}

export default connect(null, {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
})(ModulesRolUser)

const PercentageGrades = ({ cid, changeInfo, openSnackbar }) => {
  const { dataRequest, statusRequest } = useActionParam({
    action: getAction,
    param: `/Course/${cid}/ScoredLessons`,
  })
  function handleCancel() {
    changeInfo(
      <PercentageGrades
        cid={cid}
        openSnackbar={openSnackbar}
        changeInfo={changeInfo}
      />,
    )
  }
  function handleEdit() {
    const result = dataRequest.filter((r) => {
      return r.scorePercentage !== 0
    })
    if (result.length === 0) {
      let score = (100 / dataRequest.length).toFixed(2)
      let total = 0
      dataRequest.forEach((r, index) => {
        if (index === dataRequest.length - 1) {
          r.scorePercentage = 100 - total
        } else {
          total = total + parseFloat(score)
          r.scorePercentage = score
        }
      })
    }
    changeInfo(
      <EditWeighting
        cid={cid}
        openSnackbar={openSnackbar}
        changeInfo={changeInfo}
        lessons={dataRequest}
        onCancel={handleCancel}
      />,
    )
  }
  return (
    <div className="PercentageGrades">
      <H2 textAlign="center">Ponderación de las lecciones</H2>
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest) ? (
          <>
            {dataRequest.map((lesson) => (
              <React.Fragment key={lesson.id}>
                <div className="PercentageGrades__lesson">
                  <H4 margin="10px 0">{lesson.name}</H4>
                  <H3 margin="10px 0">{lesson.scorePercentage}%</H3>
                </div>
                <hr />
              </React.Fragment>
            ))}
            <ActionButton
              onClick={handleEdit}
              onCancel={() => changeInfo(<></>)}
              textBtn="Editar ponderación"
              textCancel="Cancelar"
            />
          </>
        ) : null
      ) : (
        <LoadCircle />
      )}
    </div>
  )
}

import React from 'react'
import './style/LoadingScreen.css'
import { LogoCapacita, Circle } from '../animation'
const LoadingScreen = ({ children }) => {
  return (
    <>
      <div className="LoadingScreen">
        <div className="LoadingScreen__main">
          <div className="LoadingScreen__logo">{/* <LogoCapacita /> */}</div>
          <div className="LoadingScreen__circle">
            <Circle size="100px" semicircle={true} time="3s" />
          </div>
          <div className="LoadingScreen__circle2">
            <Circle size="120px" semicircle={false} time="2.2s" />
          </div>
        </div>
        {children}
      </div>
    </>
  )
}

export default LoadingScreen

import { useEffect, useState } from "react";
import "./Home.css";
import { VideoPlayer } from "../../components/common";
import { CardPunctuation } from "../../components/card";
import { H2 } from "../../components/text";
import { connect } from "react-redux";
import { changeLayout } from "../../store/actions";
import { Switch, Route, Link } from "react-router-dom";
import { useAxios } from "../../hooks";
import About from "./About/About";
import moment from "moment";

import { CardsContents } from "../../containers/home/CardsContents";
const Home = ({ changeLayout }) => {
  // const [modal, setmodal] = useState(true)
  const { response, loading } = useAxios({
    method: "GET",
    url: "/category",
  });

  // const { response: response2, loading: loading2 } = useAxios({
  //   method: "GET",
  //   url: "/category/1/courses",
  // });

  useEffect(() => {
    changeLayout("ContentOnly");
  }, [changeLayout]);
  const Message = () => {
    const [showHideAdd, setShowHideAd] = useState(true);
    const handleClose = (e) => {
      setShowHideAd(false);
      e.preventDefault();
    };
    return (
      <a
        // href="https://capacitaglobal.org/graduates/187/single-course"
        href="https://wondermath.capacitaglobal.org/"
        target="_blank"
        rel="noopener noreferrer"
        className="Message"
        style={{ display: showHideAdd ? "block" : "none" }}
      >
        {showHideAdd && (
          <div style={{ gap: "0" }} className="SeminarUAGRM__title">
            <br />
            <i
              className="Message__close fas fa-times"
              onClick={handleClose}
            ></i>
            <img
              src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472639/assets/Untitled_7_ubgbg1_xxgthx.png"
              alt=""
            />
            {/* <H4
            border="1px solid transparent"
            fontSize="2rem"
            margin="0 10px 0"
            fontWeight="800"
          >
            <span style={{ color: '#6a3081' }}>Curso</span>
            <br />
            <span style={{ color: '#6a3081' }}>Wondermath</span>
          </H4> */}
          </div>
        )}
      </a>
    );
  };

  function HomeView() {
    return (
      <>
        {/* {modal && (
          <RenderModal
            width="700px"
            closeOutside={true}
            onClose={() => setmodal(!modal)}
          >
            <div className="SeminarUAGRM__title">
              <br />
              <img
                src="https://res.cloudinary.com/dsgaf5zoj/image/upload/v1625230836/assets/zumlq9hg3o0bkdz2fda5.png"
                alt=""
              />
              <H4
                border="1px solid transparent"
                fontSize="2rem"
                margin="0"
                fontWeight="800"
              >
                <span style={{ color: '#BF1717' }}>WEBINAR </span>
                <span style={{ color: '#193765' }}>BIG DATA</span>
              </H4>
              <div></div>
            </div>
            <H4 color="#193765" margin="20px 0">
              HOLA!!! TE INVITAMOS A QUE PARTICIPES DE NUESTRO WEBINAR BIG DATA
              Y MACHINE LEARNING, ES GRATUITO Y CERTIFICADO POR LA UAGRM-UPCT,
              LLENA EL FORMULARIO Y PARTICIPA
            </H4>
            <center>
              <NormalLink to="/seminar/big-data" background="#BF1717">
                HAZ CLICK AQUI
              </NormalLink>
            </center>
            <br />
          </RenderModal>
        )}
         */}
        <Message />

        <div className="Home__head">
          <div className="Home__header">
            {/* <VideoPlayer
              url="https://capacitaglobal.org/media/assets/home_video.mp4"
              width="100%"
              controls={false}
              height="100%"
              muted={true}
              playing={true}
            /> */}
            <div className="Home_OurCourses">
              <VideoPlayer
                url="https://capacitaglobal.org/media/assets/home_video.mp4"
                width="100%"
                controls={false}
                height="calc(100vh - 350px)"
                muted={true}
                playing={true}
              />
              <div className="EducationVirtual">
                <img
                  src="https://res.cloudinary.com/pataformacgi/image/upload/c_scale,q_auto,f_auto/v1655472641/assets/logo_2_okkya4_zuaobh.png"
                  alt="Logotipo Capacita Global"
                />
                <h2>
                  <span>Educación</span> <span>Virtual</span>
                </h2>
                {/* <RenderButton width="210px" height="60px" color="var(--white)" fontSize='20px' background='rgb(255, 115, 0)' typeBtn='link' href='http://localhost:3000/catalogue/short-courses'>
                  Nuestros Cursos
                </RenderButton> */}
                <Link to="/catalogue/short-courses" className="link">
                  Nuestros Cursos
                </Link>
              </div>
            </div>
            <div className="Home__Hmain"></div>
          </div>
          <div className="Home__content">
            {!loading ? <CardsContents /> : null}
            {/* {!loading ? <CardCategories categories={response} /> : null} */}
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />

        <div className="Home__Image">
          <Link to="/webinar">
            <img
              src="https://res.cloudinary.com/pataformacgi/image/upload/v1670946965/assets/PROPUESTAS_MIDAS.LI_PUBLI_compressed_pages-to-jpg-0005_hadjiy.jpg"
              alt=""
            />
          </Link>
        </div>

        <div className="Home__opinion">
          <br />
          <H2 color="var(--gray)" fontSize="2.5rem" textAlign="center">
            ¿Qué opinan nuestros clientes de nosotros?
          </H2>
          <div className="Home__Ocards">
            <CardPunctuation
              img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOR6V_maiOygirZXKA74C_dv6bCHFjSvh0DQ&usqp=CAU"
              name="Adela Quiroga"
              opinion="Genial el curso de Inventarios, desarrolle un sistema de control en excel que me simplifico el trabajo, saque la mejor nota en contabilidad, y lo mejor es que entendí perfectamente como realizar correctamente un inventario automatizado!!!!"
            />
            <div className="Home__Line"></div>
            <CardPunctuation
              img="https://ichef.bbci.co.uk/news/640/amz/worldservice/live/assets/images/2012/07/15/120715180730_osmar_224x280_f_nocredit.jpg"
              name="Daniel Márquez"
              opinion="Los cursos del área de derecho son bastante claros y prácticos, presentan la información de manera puntual y especifica aclarándote las dudas de un modo muy rápido. Son altamente recomendables ya que puedes accesar a ellos desde la comodidad de tu hogar y a la hora que quieras!!!"
            />
            <div className="Home__Line"></div>
            <CardPunctuation
              img="https://i.pinimg.com/736x/73/33/1d/73331d97c7f39f54bb1678a396ce26f2.jpg"
              name="Carmen Alférez"
              opinion="Es una ventaja poder elegir horarios y avanzar según tu tiempo, más aún si tienes hijos, las conferencias son muy interesantes, y puedes acceder a los registros cuando termina el evento, yo obtuve mi certificado de manera inmediata, me gusta la facilidad de manejo de su plataforma."
            />
          </div>
        </div>
      </>
    );
  }
  // const CoursesTop = ({ response, loading }) => {
  //   return (
  //     <div className="CoursesTop">
  //       {!loading ? (
  //         response && validateArray(response.programs) ? (
  //           response.programs.map((program) => {
  //             if (program.name === "EDUCACIÓN SUPERIOR") {
  //               return (
  //                 <Link
  //                   key={program.id}
  //                   to={`/graduates/${program.id}`}
  //                   className="CoursesTop__link"
  //                 >
  //                   <img
  //                     className="CoursesTop__banner"
  //                     src="https://res.cloudinary.com/dsgaf5zoj/image/upload/v1621950372/assets/y6n2ysokyg4w8rzcd3eo.png"
  //                     alt="Diplomado Educación Superior"
  //                   />
  //                   <div className="CoursesTop__figure">
  //                     <span>EL MÁS</span>
  //                     <span>VENDIDO</span>
  //                   </div>
  //                 </Link>
  //               );
  //             } else {
  //               return null;
  //             }
  //           })
  //         ) : null
  //       ) : (
  //         <LoadCircle />
  //       )}
  //     </div>
  //   );
  // };

  return (
    <div className="Home">
      <div className="Home__main">
        <Switch>
          <Route exact path="/about" component={About} />
          <Route exact path="/" component={HomeView} />
        </Switch>
      </div>
      <FooterComponent />
    </div>
  );
};
export const FooterComponent = () => {
  return (
    <div className="Home__footer">
      <div className="Home__Fdirection">
        <i className="fas fa-map-marker-alt"></i>
        <p>Ballivián y Bolivar 616 Edif. Arzobispado. Piso 3 oficina 3A</p>
      </div>
      <div className="Home__Femail">
        <i className="fas fa-envelope"></i>
        <p>aulacapacitaglobal@gmail.com</p>
        <div className="Home__Ficons">
          <a
            href="https://www.facebook.com/CapcitaGlobal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-square"></i>
          </a>
          <a
            href="https://www.instagram.com/capacitaglobal/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://wa.me/59170140353?text=%C2%A1Hola!%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20los%20cursos"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <p className="Home__Fdown">
            © {moment().clone().format("YYYY")} Aula Virtual de Capacita Global
            Internacional.
          </p>
        </div>
      </div>
      <div className="Home__Fphone">
        <i className="fas fa-phone"></i>
        <p>+591 78960810</p> <p>+591 70140353</p> <p>+591 2 2312135</p>
      </div>
    </div>
  );
};

export default connect(null, {
  changeLayout,
})(Home);

import { H2, H3 } from '../components/text'
import { useState, useEffect } from 'react'
import { BoxFlex } from '../components/boxes'
import { RenderButton } from '../components/buttons'
import { VideoPlayer } from '../components/common'

const useSteps = (props) => {
  const [showVideoModal, setShowVideoModal] = useState(true);
  const [showPopups, setShowPopups] = useState(false);
  const { name, amount, changeModal, welcome, initialStep = 1 } = props
  const [step, setStep] = useState(
    localStorage.getItem(name) ? amount + 5 : initialStep,
  )
  useEffect(() => {

   
    if (step === 1) {
      changeModal(true, '', <MainBoard />, null)
    }
  
  }, [showVideoModal, step])

  function nextStep(status = false) {
    if (amount === step) {
       localStorage.setItem(name, true)
    }
    if (status) {
      setStep((a) => a - 1)
    }else{
      setStep((a) => a + 1)
    }
  }

  const MainBoard = () => {
    return (
      <div style={{ maxWidth: '500px' }}>
        {welcome}
        <BoxFlex justify="space-between">
          <H3 margin="0">Consejo 1 de {amount}</H3>
          <BoxFlex justify="space-between">
            <RenderButton
              onClick={() => {
                setStep(7)
                changeModal(false, '')
              }}
              background="white"
              color="var(--orange2)"
            >
              Omitir tutorial
            </RenderButton>
            <RenderButton
              onClick={() => {
                setStep((a) => a + 1)
                changeModal(false, '')
              }}
            >
              Siguiente <i className="fas fa-long-arrow-alt-right"></i>
            </RenderButton>
          </BoxFlex>
        </BoxFlex>
      </div>
    )
  }

  return { step, setStep, nextStep }
}

export default useSteps

import "./ProgramManager.css";
import { useState } from "react";
// import ProgramContainer from './ProgramContainer'
import { H3, H4 } from "../../../../components/text";
import { BoxFlex, StepTips } from "../../../../components/boxes";
import { GlobalForm } from "../../../../components/form";
import { DefaultBtn } from "../../../../components/buttons";
import { validateStatus } from "../../../../utils/Validation";
import { InputsCourseManagerTeacher } from "../../../../utils/Constant";
import { withRouter } from "react-router-dom";
// import { TableFilterContainer } from '../../../../components/table'
import { postAction } from "../../../../services/action/ActionAuthorization";
const ProgramManagerTeacher = (props) => {
  const { changeInfo, step, nextStep, amount } = props;

  return <BoxFlex justify="end" margin="5px 0">
  <StepTips
    step={step}
    nextStep={nextStep}
    stepNumber={2}
    title="Crear curso"
    description="Con este botón tendrás acceso al formulario para crear tu curso."
    amount={amount}
  >
    <DefaultBtn
      onClick={() => changeInfo(<CreateCourseManager {...props} />)}
    >
      Crear Curso{" "}
      <i style={{ fontSize: "1.4rem" }} className="fas fa-plus"></i>
    </DefaultBtn>
  </StepTips>
</BoxFlex>;
};

const CreateCourseManager = (props) => {
  const { changeInfo, reload, openSnackbar, sesionUser, changeSession } = props;
  const [load, setload] = useState(true);

  function processData(values) {
    let formData = new FormData();
    for (let key in values) {
      if (values[key]) {
        formData.append(key, values[key]);
      }
    }
    return formData;
  }

  const onSubmit = async (values) => {
    try {
      setload(false);
      await postAction(
        "/programmanager/registerCourse",
        processData({
          ...values,
          Name: values.Name.toUpperCase(),
          CategoryId: 4,
        })
      ).then((response) => {
        setload(true);
        if (validateStatus(response.status)) {
          changeSession(
            sesionUser.user,
            [
              ...sesionUser.rols,
              {
                rolName: "educator",
                courseId: response.data.courseBaseCreated.id,
              },
            ],
            sesionUser.institutions,
            true
          );
          reload();
          openSnackbar("Se creó el curso", true, true);
          let { data } = response;
          props.history.push(
            `/program/course/${data.courseBaseCreated.id}/module/${data.courseModule[0].id}/educator`
          );

          changeInfo(null);
        } else {
          openSnackbar("No se pudo crear el curso", true, false);
        }
      });
    } catch (e) {
      setload(true);
      console.log(e);
    }
    reload();
  };
  return (
    <GlobalForm
      InputsJson={InputsCourseManagerTeacher}
      title="Crear Curso"
      nameSteps="form-create-course"
      textBtn="Crear"
      data={{}}
      onSubmit={onSubmit}
      load={load}
      onCancel={() => changeInfo(<></>)}
    />
  );
};

export default withRouter(ProgramManagerTeacher);

import { H4, H2, H3 } from '../../../components/text'
import { ButtonIcon, ActionButton } from '../../../components/buttons'
import { IconOptions } from '../../../components/buttonsIcon'
import { SimpleSelect } from '../../../components/input'
import { LoadCircle, Loading } from '../../../components/animation'
import { validateArray } from '../../../utils/Validation'
import { useAxiosAuth } from '../../../hooks'
import { UseOnSubmit } from '../../../hooks/useOnSubmit'
import { useState, useEffect } from 'react'
const ManageCategory = (props) => {
  const { program, changeInfo } = props
  return (
    <>
      <H2>Categorías asignadas</H2>
      {validateArray(program.programCategories) ? (
        program.programCategories.map((category) => (
          <div className="SubCategory" key={category.id}>
            <H4 margin="0">{category.name}</H4>
            <IconOptions
              noBackground
              content={
                <>
                  {/* <ButtonIcon content="Editar" icon="edit" /> */}
                  <ButtonIcon content="Eliminar categoría" icon="trash" />
                </>
              }
              icon="ellipsis-v"
            />
          </div>
        ))
      ) : (
        <H3>No tienes categorías</H3>
      )}
      <ActionButton
        onClick={() => changeInfo(<AssignCategory {...props} />)}
        onCancel={() => changeInfo(<></>)}
        textBtn="Asignar categoría"
      />
    </>
  )
}
const AssignCategory = (props) => {
  const { changeInfo, program, reload: handleReload } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: '/category',
  })
  const [selectCategory, setSelectCategory] = useState({
    Category: '',
    SubCategory: '',
  })
  const [load, setload] = useState(false)
  const { response: resSubCtg, loading: loadSubCtg, reload } = useAxiosAuth({
    method: 'GET',
    url: selectCategory.Category
      ? `/category/${selectCategory.Category}/children`
      : '',
  })
  function onChangeRole(event) {
    const { name, value } = event.target
    setSelectCategory((a) => ({
      ...a,
      SubCategory: name === 'Category' ? '' : a.SubCategory,
      [name]: value,
    }))
  }
  useEffect(() => {
    reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCategory.Category])

  function getDropdown(categories, init = 'Categoría:') {
    let aux = [{ key: init, value: '' }]
    categories.forEach((category) => {
      aux.push({ key: category.name, value: category.id })
    })
    return aux
  }
  async function onSubmit() {
    setload(true)
    await UseOnSubmit({
      axiosParams: {
        method: 'POST',
        url: `/category/${
          selectCategory.SubCategory
            ? selectCategory.SubCategory
            : selectCategory.Category
        }/ProgramManager/${program.programManager.id}`,
        // data: body,
      },
      openSnackbar: props.openSnackbar,
      textSuccess: 'Se guardaron tus cambios',
      textWrong: 'No se pudo guardar',
      functionSuccess: handleReload,
    })
    setload(false)
  }
  return loading ? (
    <LoadCircle />
  ) : (
    <div className="">
      <SimpleSelect
        name="Category"
        label="Categoría"
        options={getDropdown(response)}
        onChange={onChangeRole}
        err={selectCategory.Category ? '' : 'Campo requerido'}
      />
      {loadSubCtg ? (
        <LoadCircle />
      ) : validateArray(resSubCtg) && selectCategory.Category ? (
        <SimpleSelect
          name="SubCategory"
          label="Sub categoría"
          options={getDropdown(resSubCtg, 'Sub categoría')}
          onChange={onChangeRole}
        />
      ) : null}
      {load ? (
        <Loading />
      ) : (
        <ActionButton
          onClick={onSubmit}
          onCancel={() => changeInfo(<></>)}
          textBtn="Asignar"
          disabled={selectCategory.Category ? false : true}
        />
      )}
    </div>
  )
}

export default ManageCategory

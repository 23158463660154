import { useState, useEffect } from 'react'
import { DragList } from '../../../components/beautifulDnd'
import { LoadCircle, Loading } from '../../../components/animation'
import { ActionButton } from '../../../components/buttons'
import { processDrag } from '../../../utils/ProcessData'
import { validateArray } from '../../../utils/Validation'
import { H2, H3, H4 } from '../../../components/text'
import { useAxiosAuth } from '../../../hooks'
import { UseOnSubmit } from '../../../hooks/useOnSubmit'
const OrderAside = (props) => {
  const {
    url,
    putUrl,
    openSnackbar,
    changeInfo,
    handleReload,
    textTitle,
    data,
    attribute,
  } = props
  const [state, setState] = useState({ quotes: [] })
  const [load, setload] = useState(false)
  const { response, loading, reload } = useAxiosAuth(
    {
      method: 'GET',
      url: url,
    },
    true,
    attribute,
  )
  useEffect(() => {
    reload()
  }, [url])

  useEffect(() => {
    if (response || data) {
      setState({ quotes: processDrag(response || data, 'orderNumber') })
    }
  }, [response, data])

  function functionSuccess() {
    handleReload()
    changeInfo(<></>)
  }

  const OnSubmit = async () => {
    setload(true)
    let body = []
    state.quotes.forEach((e, index) => {
      body.push({ OrderNumber: index + 1, id: parseInt(e.id) })
    })
    await UseOnSubmit({
      axiosParams: {
        method: 'PUT',
        url: putUrl,
        data: body,
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Se guardaron tus cambios',
      textWrong: 'No se pudo guardar',
      functionSuccess: functionSuccess,
    })
    setload(false)
  }

  return (
    <div>
      <H2>Ordenar {textTitle}</H2>
      {loading ? (
        <LoadCircle />
      ) : validateArray(response || data) ? (
        <>
          <H4>*Ordena arrastrándolos y así cambiarán su posición.</H4>
          <DragList state={state} setState={setState} />
          {load ? (
            <Loading />
          ) : (
            <ActionButton
              onClick={OnSubmit}
              onCancel={() => changeInfo(<></>)}
              textBtn="Guardar cambios"
            />
          )}
        </>
      ) : (
        <H3>No tienes {textTitle}</H3>
      )}
    </div>
  )
}

export default OrderAside

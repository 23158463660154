import React from 'react';
import { RenderModal } from '../../components/modal';
import { DefaultBtn } from '../../components/buttons';
import './style/ProfileModal.css';

const ProfileModal = ({ onClose, redirectTo, buttonText }) => {
  return (
    <RenderModal width="400px" onClose={onClose} closeOutside>
      <div style={{ padding: '20px' }}>
        <p>Se necesita que complete datos de su perfil</p>
        <DefaultBtn onClick={() => window.location.href = redirectTo}>
          {buttonText}
        </DefaultBtn>
      </div>
    </RenderModal>
  );
};

export default ProfileModal;

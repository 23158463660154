import { connect } from "react-redux";
import {
  changeLayout,
  openSnackbar,
  startSession,
  changeModal,
} from "../../../../store/actions";
import { H2, H4, Section } from "../../../../components/text";
import { GlobalForm } from "../../../../components/form";
import { BoxFlex } from "../../../../components/boxes";
import { LoadCircle } from "../../../../components/animation";
import { validateArray, validateStatus } from "../../../../utils/Validation";
import { postAction } from "../../../../services/action/Action";
import { postAction as postActionAuth } from "../../../../services/action/ActionAuthorization";
import { diffDate, convertD } from "../../../../utils/ConvertDate";
import { capitalizeJson } from "../../../../utils/ProcessData";
import { useEffect, useState } from "react";
import { useAxios } from "../../../../hooks";
import { withRouter } from "react-router-dom";
import "./SeminarUAGRM.css";
import * as Yup from "yup";
const SeminarUAGRM = (props) => {
  const {
    changeLayout,
    openSnackbar,
    startSession,
    sesionUser: { user },
    changeModal,
  } = props;
  const userData = user
    ? {
        ...user,
        name: user.firstName,
        PhoneNumber: user.telephone,
        BirthDate: user.dateOfBirth ? convertD(user.dateOfBirth, "utc") : null,
      }
    : {};
  const [time, settime] = useState(diffDate("2021-07-20T19:30:00"));
  const [load, setload] = useState(true);
  useEffect(() => {
    changeLayout("FullScreen", false);
  }, [changeLayout]);

  function handelSend(data) {
    const { institutions, courses, user, father } = data.inscriptionProcess;
    if (!window.localStorage.getItem("id")) {
      window.localStorage.setItem("id", data.inscriptionProcess.user.id);
      window.localStorage.setItem(
        "token_seguridad",
        data.inscriptionProcess.token
      );
      startSession({ ...user, father: father }, courses, institutions, true);
      changeModal(
        true,
        "Ya estas inscrito al webinar y te creamos una cuenta",
        "Revise su correo donde se enviaron los datos de la cuenta.",
        "Ir al curso",
        () => props.history.push(`/program/course/${data.courseId}/module/1`)
      );
    } else {
      changeModal(
        true,
        "Ya estas inscrito al webinar",
        "Si desea continuar con el proceso mas tarde revise el correo donde se enviaron los datos de la cuenta.",
        "Ir al curso",
        () => props.history.push(`/program/course/${data.courseId}/module/1`)
      );
    }
  }
  function save(res) {
    setload(true);
    let msj = "register_successful";
    if (validateStatus(res.status)) {
      openSnackbar(msj, true, true);
      handelSend(res.data);
    } else {
      if (res.data.length < 30) {
        msj = res.data;
      } else {
        msj = "save_failed";
      }
      openSnackbar(msj, true, false);
    }
  }
  async function onSubmit(values) {
    try {
      setload(false);
      const userId = window.localStorage.getItem("id");
      if (userId) {
        await postActionAuth("/WebinarPreRegister", {
          ...values,
          // SignedProgramManagerId: 11,
          SignedProgramManagerId: 19,
          userId: parseInt(userId),
        }).then((response) => {
          save(response);
        });
      } else {
        await postAction("/WebinarPreRegister", {
          ...values,
          // SignedProgramManagerId: 11,
          SignedProgramManagerId: 19,
        }).then((response) => {
          save(response);
        });
      }
    } catch (e) {
      setload(true);
      console.log(e);
    }
  }

  useEffect(() => {
    let interval;
    const { days, hours, minutes, seconds } = time;
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    } else {
      interval = setTimeout(function () {
        settime(diffDate("2021-07-20T19:30:00"));
      }, 1000);
    }
    return function cleanup() {
      clearInterval(interval);
    };
  }, [time]);
  const { response, loading } = useAxios({
    method: "GET",
    url: "/country",
  });
  const WebinarRegistration = [
    {
      name: "Name",
      initial: "",
      validate: Yup.string().required("Campo requerido"),
      type: "text",
      label: "Nombres",
    },
    {
      name: "LastName",
      initial: "",
      validate: Yup.string().required("Campo requerido"),
      type: "text",
      label: "Apellidos",
    },
    {
      name: "Email",
      initial: "",
      validate: Yup.string()
        .email("Esto no parece un correo")
        .required("Campo requerido"),
      type: "text",
      label: "Correo electrónico",
    },
    {
      name: "CountryId",
      initial: "",
      validate: Yup.string().required("Campo requerido"),
      control: "select",
      label: "País",
      options: handleCountry(response),
    },
    {
      name: "PhoneNumber",
      initial: "",
      validate: Yup.number()
        .required("Campo requerido")
        .typeError("Eso no parece un número de teléfono"),
      type: "number",
      label: "WhatsApp",
    },
    {
      name: "Company",
      initial: "",
      validate: null,
      type: "text",
      label: "Lugar de trabajo (Opcional)",
    },
    {
      name: "BirthDate",
      initial: null,
      validate: null,
      control: "date",
      label: "Fecha de nacimiento (Opcional)",
      showMonthDropdown: true,
      showYearDropdown: true,
      dateFormat: "MMMM d, yyyy",
      dropdownMode: "select",
      peekNextMonth: true,
    },
  ];

  return (
    <div className="SeminarUAGRM">
      <br />
      <div className="SeminarUAGRM__section">
        <div className="SeminarUAGRM__info">
          <div className="SeminarUAGRM__images">
            <img
              src="https://res.cloudinary.com/dsgaf5zoj/image/upload/h_60,c_scale,q_auto,f_auto/v1622225596/assets/oou1xspdpy69l3rrbe6t.png"
              alt="Logotipo UAGRM"
            />
            <img
              src="https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472641/assets/logo_2_okkya4_zuaobh.png"
              alt="Logotipo Capacita Global"
            />
          </div>
          <br />
          <div className="SeminarUAGRM__header">
            <H2 fontSize="3rem" textAlign="center" color="var(--white)">
              WEBINAR BIG DATA
            </H2>
            <H2 fontSize="3rem" textAlign="center" color="var(--white)">
              Y
            </H2>
            <H2 fontSize="3rem" textAlign="center" color="var(--white)">
              MACHINE LEARNING
            </H2>
          </div>
          <br />
          <div className="SeminarUAGRM__content">
            <center>
              <img
                src="https://res.cloudinary.com/dsgaf5zoj/image/upload/v1625230835/assets/fvr6l9hlesrpmvkxknwn.png"
                alt=""
              />
            </center>
            <br />
            <BoxFlex justify="space-around" gap="30px">
              <BoxFlex gap="30px">
                <i className="far fa-calendar-alt"></i>
                <div>
                  <H4 color="var(--gray)" fontSize="2rem" textAlign="center">
                    Fechas:
                  </H4>
                  <H4
                    color="#BF1717"
                    fontWeight="800"
                    fontSize="2.5rem"
                    textAlign="center"
                  >
                    19 - 20 - 21
                  </H4>
                  <H4
                    color="var(--gray)"
                    fontSize="2rem"
                    textAlign="center"
                    margin="0"
                  >
                    Julio
                  </H4>
                </div>
              </BoxFlex>
              <BoxFlex gap="30px">
                <i className="far fa-clock"></i>
                <div>
                  <H4 color="var(--gray)" fontSize="2rem" textAlign="center">
                    Horas:
                  </H4>
                  <H4
                    color="#BF1717"
                    fontWeight="800"
                    fontSize="2.5rem"
                    textAlign="center"
                  >
                    19 : 30 pm (GTM - 4)
                  </H4>
                  <H4
                    color="var(--gray)"
                    fontSize="2rem"
                    textAlign="center"
                    margin="0"
                  >
                    Bolivia - Chile
                  </H4>
                </div>
              </BoxFlex>
              <BoxFlex gap="30px">
                <i className="fas fa-headset"></i>
                <div>
                  <H4 color="var(--gray)" fontSize="2rem" textAlign="center">
                    Contacto:
                  </H4>
                  <H4
                    color="#BF1717"
                    fontWeight="800"
                    fontSize="2.5rem"
                    textAlign="center"
                  >
                    +591 2-2312135
                  </H4>
                  <H4
                    color="#BF1717"
                    fontWeight="800"
                    fontSize="2.5rem"
                    textAlign="center"
                  >
                    +591 78960810
                  </H4>
                  <H4
                    color="#BF1717"
                    fontWeight="800"
                    fontSize="2.5rem"
                    textAlign="center"
                  >
                    +591 76702369
                  </H4>
                  {/* <H4
                    color="var(--gray)"
                    fontSize="2rem"
                    textAlign="center"
                    margin="0"
                  >
                    Julio
                  </H4> */}
                </div>
              </BoxFlex>
            </BoxFlex>
          </div>
        </div>
        <div className="SeminarUAGRM__form">
          <div className="SeminarUAGRM__title">
            <div></div>
            <br />
            <img
              src="https://res.cloudinary.com/dsgaf5zoj/image/upload/v1625230836/assets/zumlq9hg3o0bkdz2fda5.png"
              alt=""
            />
            <H4
              border="1px solid transparent"
              fontSize="3rem"
              margin="0 10px 0"
              fontWeight="800"
            >
              <span style={{ color: "#BF1717" }}>INS</span>
              <span style={{ color: "#193765" }}>CRIPCIÓN</span>
            </H4>
          </div>
          {loading ? (
            <LoadCircle />
          ) : (
            <GlobalForm
              InputsJson={WebinarRegistration}
              title=""
              textBtn="Inscribirme"
              data={capitalizeJson(userData)}
              onSubmit={onSubmit}
              load={load}
            />
          )}
        </div>
      </div>
      <br />
      <Timekeeper {...time} />
      <br />
      <div className="SeminarUAGRM__topics">
        <div className="SeminarUAGRM__title">
          <img
            src="https://res.cloudinary.com/dsgaf5zoj/image/upload/v1625230835/assets/snedbxcsntzaffzi8uf2.png"
            alt=""
          />
          <H4
            border="1px solid transparent"
            fontSize="3.5rem"
            margin="0"
            fontWeight="800"
          >
            <span style={{ color: "#BF1717" }}>TE</span>
            <span style={{ color: "#193765" }}>MAS</span>
          </H4>
          <br />
          <div></div>
        </div>
        <ul className="SeminarUAGRM__list">
          <li>¿QUÉ ES EL BIG DATA Y CÓMO PUEDE APORTAR A MI INSTITUCIÓN?</li>
          <li>MODELOS ESTADÍSTICOS EN DATA SCIENCE</li>
          <li>BIG DATA Y MACHINE LEARNING, ¿CÓMO SE COMPLEMENTAN?</li>
        </ul>
        <br />
        <Section
          margin="0 3%"
          fontWeight="600"
          fontSize="2rem"
          color="var(--gray)"
        >
          Permite a los participantes conocer la problemática de Big Data, las
          plataformas más importantes y las técnicas que permiten manejar esa
          data para comprender e implementar los principales algoritmos de
          Machine Learning y presentar visualmente la información en forma
          efectiva.
        </Section>
        <br />
        <br />
        <div className="SeminarUAGRM__title">
          <div></div>
          <br />
          <img
            src="https://res.cloudinary.com/dsgaf5zoj/image/upload/v1625230836/assets/zumlq9hg3o0bkdz2fda5.png"
            alt=""
          />
          <H4
            border="1px solid transparent"
            fontSize="3.5rem"
            margin="0 10px 0"
            fontWeight="800"
          >
            <span style={{ color: "#BF1717" }}>DO</span>
            <span style={{ color: "#193765" }}>CENTES</span>
          </H4>
        </div>
        <div className="SeminarUAGRM__teachers">
          <CardTeachers
            url="https://res.cloudinary.com/dsgaf5zoj/image/upload/v1625230836/assets/tz4vclmoweldsu5jpq5q.png"
            name="Carla Andrea"
            lastName="Reyes Lopez"
            description="Economista experta en Economía Cuantitativa Aplicada y Ciencia de Datos. Experiencia en evaluación de impacto económico y social, determinación de línea base en proyectos. Investigadora del proyecto “Tecnología en áreas rurales; globalización y entendimiento”. Proyecciones financieras bajo escenarios, análisis de datos estadísticos, construcción de modelos económicos, construcción de indicadores socioeconómicos, seguimiento, monitoreo, evaluación y cierre de proyectos de ámbito privado y social."
            profession="Economista con siete años de experiencia profesional y amplios conocimientos en Economía Cuantitativa Aplicada y Ciencia de Datos."
          />
          <CardTeachers
            url="https://res.cloudinary.com/dsgaf5zoj/image/upload/v1625230836/assets/myciwqfrbbwqiculci8s.png"
            name="Erwin"
            lastName="Lugones Soria"
            description="Ingeniero en telecomunicaciones con postgrados en BI y Analítica de datos. Ingeniero de Core Móvil PS durante 4 años en Huawei Technologies y ZTE para operadores como ENTEL y COMTECO. Administrador de solución de analítica de datos para red móvil basada en Big Data y director de proyecto de Analítica de Datos en Paraguay para operador local."
            profession="Ingeniero en telecomunicaciones con postgrados en BI y Analítica de datos."
          />
          <CardTeachers
            url="https://res.cloudinary.com/dsgaf5zoj/image/upload/v1625230835/assets/m7qtrts5nojz0rsukugk.png"
            name="Lesly"
            lastName="Zerna"
            description="Con una Maestría en Ciencias de Computación e Ingeniería de la Universidad de Bruxellés,  cuenta con amplia experiencia en sistemas de Challenge PyTorch – Deep Learning Nano Degree in Self Driving Cars además de Nano Degree in Data Foundations for Data Science,  reconocida docente de la UCB,  disertó los módulos de Seguridad en dispositivos móviles y Tendencias e-commerce de la Maestría de Ciberseguridad, actualmente es Co Fundadora de MentorHER, Bolivia."
            profession="Machine Learning"
          />
        </div>
      </div>
    </div>
  );
};

function handleCountry(countries) {
  let dropdown = [{ key: "País:", value: "" }];
  if (validateArray(countries)) {
    countries
      .sort((a, b) => a.name.localeCompare(b.name))
      .forEach((country) => {
        dropdown.push({ key: country.name, value: country.id });
      });
  }
  return dropdown;
}

function Timekeeper({ days, hours, minutes, seconds }) {
  const boolDays = days < 0;
  function TextTime({ time, text }) {
    return (
      <div className="Timekeeper__item">
        <h4>{time}</h4>
        <Section margin="0" color="var(--white)" textAlign="center">
          {text}
        </Section>
      </div>
    );
  }
  return (
    <div className="Timekeeper">
      <H4
        margin="0"
        fontSize="2.5rem"
        fontWeight="600"
        color="#193765"
        border="1px solid transparent"
      >
        El webinar inicia en:
      </H4>
      <br />
      <div>
        <TextTime time={boolDays ? 0 : days} text="Días" />
        <div>:</div>
        <TextTime time={boolDays ? 0 : hours} text="Horas" />
        <div>:</div>
        <TextTime time={boolDays ? 0 : minutes} text="Minutos" />
        <div>:</div>
        <TextTime time={boolDays ? 0 : seconds} text="Segundos" />
      </div>
    </div>
  );
}
function CardTeachers({ url, name, lastName, description }) {
  return (
    <div className="CardTeachers">
      <div className="CardTeachers__translate">
        <img src={url} alt="avatar" />
        <H4 margin="5px 0" fontWeight="800" color="#BF1717" textAlign="center">
          {name}
        </H4>
        <H4 margin="5px 0" fontWeight="800" color="#193765" textAlign="center">
          {lastName}
        </H4>
        <br />
        <Section fontWeight="500" margin="0" color="#193765" textAlign="center">
          {description}
        </Section>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, {
  changeLayout,
  openSnackbar,
  startSession,
  changeModal,
})(withRouter(SeminarUAGRM));

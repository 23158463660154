import { useAxiosAuth } from '../../../hooks'
import { validateArray } from '../../../utils/Validation'
import { TypeLesson } from '../../../utils/Constant'
import { Loading } from '../../../components/animation'
import { H2, H3 } from '../../../components/text'
import { BoxFlex } from '../../../components/boxes'

const MonthlyRecords = (props) => {
  const { cid } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/course/MonthlyRecords/${cid}`,
  })
  

  return (
    <>
      <H2 margin="0 0 10px" color="var(--gray)" textAlign="center">
        Registros Mensuales
      </H2>
      {loading ? (
        <Loading />
      ) : (
        validateArray(response) &&
        response.map((item) => {
          if (validateArray(item)) {
            return <CountRegister key={item.moth} module={item} cid={cid} />
          } else {
            return null
          }
        })
      )}
    </>
  )
}

const CountRegister = ({ module }) => {
  var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  console.log()
    return (
      <>
        <H3 fontSize="1.4rem" margin="10px 0">
          {meses[module.moth - 1]}
        </H3>
        <div className="CourseContent__lessons">
          <div>
            <h3>Inscritos: {module.count} 
            <button> --- </button> </h3>
          </div>
          <h3>Total Mensual: </h3>
          <h3>Estado: </h3>
        </div>
      </>
    )
}


export default MonthlyRecords

import "./ProgramManager.css";
import { useState } from "react";
import ProgramContainer from "./ProgramContainer";
import { BoxFlex } from "../../../../components/boxes";
import { GlobalForm } from "../../../../components/form";
import { DefaultBtn } from "../../../../components/buttons";
import { validateStatus } from "../../../../utils/Validation";
import { InputsProgramManager } from "../../../../utils/Constant";
import { TableFilterContainer } from "../../../../components/table";
import { postAction } from "../../../../services/action/ActionAuthorization";
const ProgramManager = (props) => {
  const { changeInfo, iid } = props;
  function handleCreateProgram(reload) {
    return (
      <BoxFlex justify="end" margin="5px 0">
        <DefaultBtn
          onClick={() =>
            changeInfo(<CreateProgramManager {...props} reload={reload} />)
          }
        >
          Crear programa{" "}
          <i style={{ fontSize: "1.4rem" }} className="fas fa-plus"></i>
        </DefaultBtn>
      </BoxFlex>
    );
  }
  return (
    <div>
      <TableFilterContainer
        param="/ProgramManager"
        addParam={`InstitutionId=${iid}`}
        header={[
          { key: "name", name: "Nombre" },
          { key: "creationDate", name: "Fecha de creación", type: "date" },
        ]}
        componet={handleCreateProgram}
        // boxFilters={[
        //   {
        //     type: 'select',
        //     label: 'Ordenar por:',
        //     name: 'OrderBy',
        //     options: [
        //       { label: 'Nombre', name: '' },
        //       { label: 'Fecha de creación', name: 'created' },
        //     ],
        //   },
        //   {
        //     type: 'getSelect',
        //     label: 'Categoría:',
        //     name: 'CategoryId',
        //     url: '/category',
        //     urlKey: 'id',
        //     urlLabel: 'name',
        //     initial: 'Todas las categorías',
        //   },
        // ]}
        onClick={(program, reload) =>
          changeInfo(
            <ProgramContainer
              {...props}
              program={program}
              tableReload={reload}
            />
          )
        }
      />
    </div>
  );
};

const CreateProgramManager = (props) => {
  const { changeInfo, reload, openSnackbar } = props;
  const [load, setload] = useState(true);
  const onSubmit = async (values) => {
    try {
      setload(false);
      await postAction("/programmanager", { ...values, CategoryId: 8 }).then(
        (response) => {
          setload(true);
          if (validateStatus(response.status)) {
            openSnackbar("Se creó el programa", true, true);
            reload();
          } else {
            openSnackbar("No se pudo crear", true, false);
          }
        }
      );
    } catch (e) {
      setload(true);
      console.log(e);
    }
  };
  return (
    <GlobalForm
      InputsJson={InputsProgramManager}
      title="Crear programa"
      textBtn="Crear"
      data={{}}
      onSubmit={onSubmit}
      load={load}
      onCancel={() => changeInfo(<></>)}
    />
  );
};

export default ProgramManager;

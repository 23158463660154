import GlobalForm from './GlobalForm'
import { useState } from 'react'
import {
  postAction,
  putAction,
} from '../../services/action/ActionAuthorization'
import { validateStatus } from '../../utils/Validation'
import { InputsModule, InputsModuleEdit } from '../../utils/FormParameters'
import { capitalizeJson } from '../../utils/ProcessData'
import { convertDate } from '../../utils/ConvertDate'

const FormNewModule = (props) => {
  const { changeInfo, openSnackbar, cid, reload, modules } = props
  const [load, setload] = useState(true)
  function processData(values) {
    let formData = new FormData()
    for (let key in values) {
      if (values[key]) {
        formData.append(key, values[key])
      }
    }
    return formData
  }
  const onSubmit = async (values) => {
    try {
      setload(false)

      const action = modules ? putAction : postAction
      await action(
        `/module${modules ? '/' + modules.id : ''}`,
        processData({
          ...values,
          Name: values.Name.toUpperCase(),
          StartDate: convertDate(values.StartDate),
          FinishDate: convertDate(values.FinishDate),
          CourseId: cid,
          File: values.PhotoUrl,
        }),
      ).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          openSnackbar('Se guardaron tus cambios', true, true)
          reload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <>
      <GlobalForm
        InputsJson={modules ? InputsModuleEdit : InputsModule}
        textBtn={modules ? 'Guardar cambios' : 'Crear'}
        title={modules ? 'Editar módulo' : 'Crear módulo'}
        data={capitalizeJson(modules)}
        load={load}
        onSubmit={onSubmit}
        onCancel={() => changeInfo(<></>)}
      />
    </>
  )
}

export default FormNewModule

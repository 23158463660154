import { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useSteps } from '../../hooks'
import { FormikControl } from '../input'
import { ActionButton } from '../buttons'
import { Loading } from '../animation'
import { UseOnSubmit } from '../../hooks/useOnSubmit'
import './style/CreateLesson.css'

const dropdownEducator = [
  // { key: 'Tipo de contenido:', value: '' },
  { key: 'Examen', value: 'LessonQuiz' },
  { key: 'Video', value: 'LessonVideo' },
  { key: 'Clases virtuales en vivo', value: 'LessonVirtualclass' },
  { key: 'Foro', value: 'LessonForum' },
  { key: 'Material', value: 'Materials' },
]
const dropdownOptions = [
  ...dropdownEducator,
  { key: 'Certificado', value: 'LessonCertificate' },
]

const dropdownExtension = [
  { key: 'Archivo PDF', value: '.pdf' },
  { key: 'Archivo Word', value: '.docx' },
  { key: 'Enlace', value: 'url' },
  { key: 'Imágenes', value: 'image/*' },
  { key: 'Audio', value: 'audio/*' },
  { key: 'Todo tipo de archivos', value: '' },
]
const CreateLesson = (props) => {
  const { openSnackbar, mid, reload, modules, rol, changeInfo } = props
  const { step, nextStep } = useSteps({
    name: 'create-lesson',
    amount: 12,
    changeModal: () => {},
    initialStep: 0,
    welcome: null,
  })
  const [load, setload] = useState(true)
  const initialValues = {
    module: mid,
    content: 'LessonVideo',
    Name: '',
    // StartDate: null,
    // FinishDate: null,
    Description: '',
    aditionalUrlResources: '',
    FileExtension: '.pdf',
    NumberFiles: 1,
    MinimumGrade: 60,
    TimeLimit: '00:30:00',
    Attemps: 1,
  }
  const validationSchema = Yup.object({
    module: Yup.string().required('Campo Requerido'),
    content: Yup.string().required('Campo requerido'),
    Name: Yup.string().required('Campo requerido'),
    // StartDate: Yup.string().required('Campo requerido').nullable(),
    // FinishDate: Yup.string().required('Campo requerido').nullable(),
    aditionalUrlResources: Yup.string().when('content', {
      is: 'LessonVirtualclass',
      then: Yup.string().required('Campo requerido.'),
    }),
    TimeLimit: Yup.string().when('content', {
      is: 'LessonQuiz',
      then: Yup.string()
        .required('Campo requerido')
        .matches(
          /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/,
          'Tiempo no válido "formato valido: 00:00:00"',
        ),
    }),
  })
  const dropdownModules = generateOptions()
  function generateOptions() {
    let dropOptions = [{ key: 'Módulo:', value: '' }]
    modules.forEach((m) => {
      dropOptions.push({ key: m.name, value: m.id })
    })
    return dropOptions
  }
  const onSubmit = async (values) => {
    setload(false)
    const { module, content, Description, ...rest } = values
    if (content === 'Jitsi') {
      rest.Name = `${module} ${rest.Name}`
    }
    /* TODO */
    await UseOnSubmit({
      axiosParams: {
        method: 'POST',
        url: `/module/${module}/${content}`,
        data: {
          Description: Description
            ? JSON.stringify(Description).replace(/"/g, "'")
            : Description,
          ...rest,
          Name: rest.Name.toUpperCase(),
        },
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Lección creada',
      textWrong: 'No se pudo crear',
      functionSuccess: reload,
      process: true,
    })
    setload(true)
  }

  return (
    <div className="ContentUpload">
      <h3>Subir contenido</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                step={step}
                nextStep={nextStep}
                index={0}
                titleStep="Selecciona el moduló"
                descriptionStep="En este modulo se incluirá el contenido"
                amount={3}
                control="select"
                label="Módulo donde añadir:"
                name="module"
                options={dropdownModules}
              />
              <FormikControl
                step={step}
                nextStep={nextStep}
                index={1}
                titleStep="Selecciona el tipo de contenido que adicionaras"
                descriptionStep=""
                amount={3}
                control="select"
                label="Tipo de contenido"
                name="content"
                options={
                  rol === 'educator' ? dropdownEducator : dropdownOptions
                }
              />
              <FormikControl
                step={step}
                nextStep={nextStep}
                index={2}
                titleStep="Escribe el titulo de tu contenido"
                descriptionStep="Es un nombre descriptivo de tu contenido"
                amount={3}
                control="input"
                type="text"
                label="Nombre del contenido"
                name="Name"
              />
              {formik.values.content === 'LessonHomework' && (
                <>
                  <FormikControl
                    step={step}
                    nextStep={nextStep}
                    index={3}
                    titleStep="Selecciona el modulo"
                    description=""
                    amount={12}
                    control="select"
                    label="Tipo de archivo permitido"
                    name="FileExtension"
                    options={dropdownExtension}
                  />
                  <FormikControl
                    step={step}
                    nextStep={nextStep}
                    index={4}
                    titleStep="Selecciona el modulo"
                    description=""
                    amount={12}
                    control="input"
                    type="number"
                    label="Numero de archivos permitidos"
                    name="NumberFiles"
                  />
                </>
              )}
              {/* <FormikControl
                control="date"
                label="Fecha de inicio"
                name="StartDate"
                placeholderText="Fecha de inicio"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              <FormikControl
                control="date"
                label="Fecha de conclusión"
                name="FinishDate"
                placeholderText="Fecha de conclusión"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
              /> */}
              {formik.values.content === 'LessonVirtualclass' && (
                <>
                  <FormikControl
                    step={step}
                    nextStep={nextStep}
                    index={3}
                    titleStep="Pega el enlace de ingreso a la clase virtual"
                    amount={5}
                    control="input"
                    type="text"
                    label="Enlace de la clase virtual"
                    name="aditionalUrlResources"
                  />
                  <FormikControl
                    step={step}
                    nextStep={nextStep}
                    index={4}
                    titleStep="Selecciona cuando se realizara la clase virtual"
                    amount={5}
                    control="input"
                    type="date"
                    label="Fecha de realizacion"
                    name="StartDate"
                    dateFormat="MMMM d, yyyy HH:mm"
                    showTimeSelect
                    timeIntervals={15}
                  />
                </>
              )}
              {formik.values.content === 'Materials' && (
                <FormikControl
                  step={step}
                  nextStep={nextStep}
                  index={3}
                  titleStep="Pega el enlace del material que quieres compartir"
                  amount={4}
                  control="input"
                  type="text"
                  label="Enlace"
                  name="aditionalUrlResources"
                />
              )}
              {formik.values.content === 'LessonQuiz' && (
                <>
                  <FormikControl
                    step={step}
                    nextStep={nextStep}
                    index={3}
                    titleStep="Indica la nota mínima de aprobación del examen"
                    amount={6}
                    control="input"
                    type="number"
                    label="Nota mínima de aprobación"
                    name="MinimumGrade"
                  />

                  <FormikControl
                    step={step}
                    nextStep={nextStep}
                    index={4}
                    titleStep="Describe cuanto tiempo tendrán los estudiantes para resolver el examen"
                    amount={6}
                    control="input"
                    type="text"
                    label="Tiempo limite"
                    name="TimeLimit"
                  />
                  <FormikControl
                    step={step}
                    nextStep={nextStep}
                    index={5}
                    titleStep="Indica cuantos intentos tienen los estudiantes para resolver el examen"
                    amount={6}
                    control="input"
                    type="number"
                    label="Numero de intentos"
                    name="Attemps"
                  />
                </>
              )}
              <FormikControl
                control="editor"
                label="Descripción"
                name="Description"
                onChange={formik.setValues}
              />
              <br />
              <br />
              {load ? (
                <ActionButton
                  type="submit"
                  disabled={!formik.isValid}
                  textBtn="Subir contenido"
                  onCancel={() => changeInfo(<></>)}
                />
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default CreateLesson

import React from 'react'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { H2, H4, Section } from '../../components/text'
import { useActionParam } from '../../hooks'
import { changeLayout } from '../../store/actions'
import { getAction } from '../../services/action/Action'
import { ButtonFloating } from '../../components/buttons'
import { validateStatus, validateArray } from '../../utils/Validation'

const TermsOfService = ({ changeLayout }) => {
  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])

  return (
    <div>
      <H2 margin="0 0 20px 0" textAlign="center">
        Términos Generales de Uso de Midas.li
      </H2>
      <H4>Versión actualizada el 8 de mayo de 2023</H4>
      <div style={{ padding: '10px 15px', background: '#e3ad2d8a' }}>
        <H4>¡Gracias por utilizar los servicios de Midas.li!</H4>
        <H4>
          Este acuerdo establece los términos y condiciones bajo los cuales
          Midas.li otorgamos a los afiliados el derecho a promover nuestros
          cursos en línea y recibir una comisión por cada venta realizada a
          través de su enlace de afiliado. Al unirse al programa de afiliados de
          Cursos, usted acepta cumplir con los términos y condiciones descritos
          a continuación.
        </H4>
      </div>
      <H2 margin="20px 0 0 0">Registro</H2>
      <H4>
        Para unirse al programa de afiliados de Cursos, usted debe completar el
        proceso de registro en nuestro sitio web y aceptar estos términos y
        condiciones. Nos reservamos el derecho de rechazar cualquier solicitud.
      </H4>
      <H2 margin="20px 0 0 0">Comisiones</H2>
      <H4>
        Le pagaremos una comisión por cada venta realizada a través de su enlace
        de afiliado. La tasa de comisión se establecerá por adelantado y puede
        variar en función de los cursos promovidos. Nos reservamos el derecho de
        cambiar la tasa de comisión en cualquier momento, y le notificaremos por
        escrito con al menos 30 días de anticipación. Las comisiones solo se
        pagarán por ventas que hayan sido verificadas como válidas y
        completadas. Nos reservamos el derecho de retener el pago de comisiones
        si sospechamos de fraude o actividad ilegal.
      </H4>

      <H2 margin="20px 0 0 0">Promoción</H2>
      <H4>
        Como afiliado, usted tiene derecho a promocionar los Cursos utilizando
        los enlaces de afiliado proporcionados por nosotros. Usted acepta
        utilizar únicamente los materiales promocionales que proporcionamos, y
        no utilizará ninguna táctica de marketing que pueda dañar nuestra
        reputación. También acepta no hacer declaraciones falsas o engañosas
        sobre los Cursos.
      </H4>

      <H2 margin="20px 0 0 0">Propiedad intelectual</H2>
      <H4>
        Nosotros somos los propietarios exclusivos de todos los derechos de
        propiedad intelectual relacionados con los Cursos y cualquier material
        promocional proporcionado a los afiliados. Usted acepta no hacer nada
        que pueda infringir o comprometer nuestros derechos de propiedad
        intelectual.
      </H4>
      <H2 margin="20px 0 0 0">Divulgación y cumplimiento de la ley</H2>
      <H4>
        Usted acepta cumplir con todas las leyes y regulaciones aplicables en
        relación con su participación en el programa de afiliados de Cursos.
        También acepta revelar completamente cualquier relación financiera o de
        otro tipo que tenga con nosotros o con nuestros competidores al
        promocionar los Cursos.
      </H4>
      <H2 margin="20px 0 0 0">Limitación de responsabilidad</H2>
      <H4>
        Nosotros no seremos responsables de ninguna pérdida, daño o costo en los
        que pueda incurrir como resultado de su participación en el programa de
        afiliados de Cursos. En ningún caso seremos responsables de daños
        especiales, indirectos, consecuentes o punitivos.
      </H4>
      <H2 margin="20px 0 0 0">Terminación </H2>
      <H4>
        Nos reservamos el derecho de rescindir este acuerdo en cualquier momento
        y sin previo aviso si consideramos que ha violado estos términos y
        condiciones. En caso de rescisión, se le pagará cualquier comisión
        pendiente que haya acumulado antes de la fecha de rescisión.
      </H4>
      <H2 margin="20px 0 0 0">Modificaciones</H2>
      <H4>
        Nos reservamos el derecho de modificar estos términos y condiciones en
        cualquier momento y sin previo aviso. Se espera que usted revise estos
        términos y condiciones regularmente para estar al tanto de cualquier
        cambio. El uso continuado de los enlaces de afiliado después de
        cualquier modificación constituirá su aceptación de los términos y
        condiciones modificados.
      </H4>
      <H2 margin="20px 0 0 0">Confidencialidad</H2>
      <H4>
        Usted acepta mantener la confidencialidad de cualquier información
        confidencial que se le proporcione durante su participación en el
        programa de afiliados de Cursos.
      </H4>
      <H2 margin="20px 0 0 0">Acuerdo completo</H2>
      <H4>
        Este acuerdo constituye el acuerdo completo entre usted y nosotros en
        relación con su participación en el programa de afiliados de Cursos.
        Cualquier representación, garantía o acuerdo verbal o escrito anterior a
        este acuerdo quedará anulado y sin efecto.
      </H4>
      <H2 margin="20px 0 0 0">Ley aplicable</H2>
      <H4>
        Este acuerdo se regirá e interpretará de acuerdo con las leyes del país
        en el que se encuentra nuestra empresa.
      </H4>
      <H4>
        Al unirse al programa de afiliados de Cursos, usted acepta cumplir con
        estos términos y condiciones en su totalidad. Si tiene alguna pregunta o
        inquietud acerca de este acuerdo, por favor contáctenos antes de unirse
        al programa de afiliados.
      </H4>
    </div>
  )
}

export default connect(null, { changeLayout })(TermsOfService)

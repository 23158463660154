import './PdfList.css'
import { H2} from '../../components/text'

const PdfList = () => {
  return (
    <div style={{background:''}}>
      <H2 textAlign="center" color="var(--gray)">
        Descargar Pdf
      </H2>

      <div className='container_button'>
        <a className='btn_pdf' href='https://res.cloudinary.com/ahsoizdev/image/upload/v1677101240/imagesCapacita/PDFs/CONTENIDO_ADICIONAL_PARA_LA_PUBLICACI%C3%93N_DE_CURSOS_EN_REDES_SOCIALES_lqea5m.pdf' target='_blank' download rel="noreferrer">CONTENIDO ADICIONAL PARA LA PUBLICACIÓN DE CURSOS EN REDES SOCIALES <i style={{ fontSize: "1.4rem", marginLeft:'3px' }} className="fas fa-external-link-square-alt"></i></a>
        <a className='btn_pdf' href='https://res.cloudinary.com/ahsoizdev/image/upload/v1677101240/imagesCapacita/PDFs/LINEAMIENTOS_DE_CALIDAD_EN_LA_ELABORACI%C3%93N_DE_CURSOS_apkeeg.pdf' target='_blank' download  rel="noreferrer">LINEAMIENTOS DE CALIDAD EN LA ELABORACIÓN DE CURSOS <i style={{ fontSize: "1.4rem", marginLeft:'3px' }} className="fas fa-external-link-square-alt"></i></a>
        <a className='btn_pdf' href="https://res.cloudinary.com/ahsoizdev/image/upload/v1677101240/imagesCapacita/PDFs/PASOS_PARA_LA_SUBIDA_DE_CURSOS_A_LA_PLATAFORMA_ftoccv.pdf" target='_blank' download  rel="noreferrer">PASOS PARA LA SUBIDA DE CURSOS A LA PLATAFORMA <i style={{ fontSize: "1.4rem", marginLeft:'3px' }} className="fas fa-external-link-square-alt"></i></a>
      </div>
    </div>
  )
}

export default PdfList;

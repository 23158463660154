import DefaultBtn from './DefaultBtn'
import { StepTips } from '../boxes'
import './style/ActionButton.css'
const ActionButton = ({
  onClick,
  onCancel,
  textBtn,
  textCancel = 'Cancelar',
  type,
  disabled,
  width = '100%',
  color,
  step,
  nextStep,
  index,
  titleStep,
  descriptionStep,
  amount,
}) => {
  return (
    <div className="ActionButton">
      {onCancel && (
        <DefaultBtn
          width={width}
          onClick={onCancel}
          type="button"
          background={true}
        >
          {textCancel}
        </DefaultBtn>
      )}
      {titleStep ? (
        <div style={{ minWidth: '50%' }}>
          <StepTips
            step={step}
            nextStep={nextStep}
            stepNumber={index}
            title={titleStep}
            description={descriptionStep}
            amount={amount}
          >
            <DefaultBtn
              width={width}
              onClick={onClick}
              type={type ? type : 'button'}
              disabled={disabled ? disabled : false}
              color={color}
            >
              {textBtn}
            </DefaultBtn>
          </StepTips>
        </div>
      ) : (
        <DefaultBtn
          width={width}
          onClick={onClick}
          type={type ? type : 'button'}
          disabled={disabled ? disabled : false}
          color={color}
        >
          {textBtn}
        </DefaultBtn>
      )}
    </div>
  )
}

export default ActionButton

import { IconOptions } from '../../../components/buttonsIcon'
import { ButtonIcon, DefaultBtn } from '../../../components/buttons'
import { FormNewModule } from '../../../components/form'
import { BoxFlex } from '../../../components/boxes'
import { SubtitleData, H2, H3 } from '../../../components/text'
import { convertD } from '../../../utils/ConvertDate'
import { OrderAside } from '../ProgramInstitution'
import UsersRecuperatory from './UsersRecuperatory'
const CardModule = (props) => {
  const {
    module,
    changeInfo,
    cid,
    reload,
    openSnackbar,
    pid,
    changeModal,
  } = props
  return (
    <div
      className={`CourseModules__module ${
        module.type === 'recuperatory' ? 'CourseModules__recuperatory' : ''
      }`}
    >
      <h3>{module.name}</h3>
      <BoxFlex>
        {module.type === 'recuperatory' ? (
          <H3
            color="white"
            margin="0"
            fontWeight="500"
            border="1px solid var(--white)"
            padding="5px"
            borderRadius="5px"
          >
            Recuperatorio
          </H3>
        ) : null}
        <IconOptions
          color={
            module.type === 'recuperatory' ? 'var(--white)' : 'var(--green)'
          }
          content={
            <>
              {/* <ButtonIcon content="Eliminar del módulo" icon="trash" /> */}
              <ButtonIcon
                content="Administrar lecciones"
                icon="chalkboard-teacher"
                onClick={() =>
                  window.open(
                    `/program/${pid ? pid + '/' : ''}course/${cid}/module/${
                      module.id
                    }/admin`,
                    '_blank',
                  )
                }
              />
              {module.type === 'recuperatory' && (
                <ButtonIcon
                  content="Listar alumnos inscritos"
                  icon="users"
                  onClick={() =>
                    changeInfo(
                      <UsersRecuperatory
                        changeInfo={changeInfo}
                        cid={cid}
                        openSnackbar={openSnackbar}
                        reload={reload}
                        modules={module}
                        changeModal={changeModal}
                      />,
                    )
                  }
                />
              )}
              <ButtonIcon
                content="Editar información del módulo"
                icon="edit"
                onClick={() =>
                  changeInfo(
                    <FormNewModule
                      changeInfo={changeInfo}
                      cid={cid}
                      openSnackbar={openSnackbar}
                      reload={reload}
                      modules={module}
                    />,
                  )
                }
              />
              <ButtonIcon
                onClick={() =>
                  changeInfo(
                    <OrderAside
                      changeInfo={changeInfo}
                      openSnackbar={openSnackbar}
                      url={null}
                      putUrl={`/module/${module.id}/OrderLessons`}
                      handleReload={reload}
                      textTitle="lecciones"
                      data={module.lessons}
                    />,
                  )
                }
                content="Ordenar lecciones"
                icon="sort-numeric-down"
              />
              <ButtonIcon
                content="Ver información del módulo"
                icon="info-circle"
                onClick={() =>
                  changeInfo(
                    <ViewModule changeInfo={changeInfo} module={module} />,
                  )
                }
              />
            </>
          }
          noBackground={true}
          icon="ellipsis-v"
        />
      </BoxFlex>
    </div>
  )
}
function ViewModule({ module: mod, changeInfo }) {
  return (
    <>
      <H2 textAlign="center">Información del módulo</H2>
      <SubtitleData subtitle="Nombre">{mod.name}</SubtitleData>
      <SubtitleData subtitle="Fecha de inicio">
        {convertD(mod.startDate, 'LLL')}
      </SubtitleData>
      <SubtitleData subtitle="Fecha de finalización">
        {convertD(mod.finishDate, 'LLL')}
      </SubtitleData>
      {mod.description && (
        <SubtitleData subtitle="Descripción">{mod.description}</SubtitleData>
      )}
      <DefaultBtn
        background={true}
        width="100%"
        onClick={() => changeInfo(<></>)}
      >
        Cerrar
      </DefaultBtn>
    </>
  )
}
export default CardModule

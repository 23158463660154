import './StepTips.css'
import { BoxFlex } from '../boxes'
import { H3, H4 } from '../text'
import { RenderButton } from '../buttons'
const StepTips = (props) => {
  const {
    children,
    content = null,
    step,
    nextStep,
    stepNumber,
    amount,
    title,
    description,
    right = true,
  } = props

  if (step === stepNumber) {
    return (
      <>
        <div className="StepTips__content">
          <div className="StepTips__children">{children}</div>
          <div
            style={right ? { right: '0' } : { left: '0' }}
            className="StepTips__Ccontent"
          >
            {content ? (
              content
            ) : (
              <>
                <H3 margin="0">{title}</H3>
                <H4 margin="0" color="var(--gray)">
                  {description}
                </H4>
              </>
            )}
            <BoxFlex justify="space-between">
              <H3 margin="0">
                Consejo {stepNumber} de {amount}
              </H3>
              <BoxFlex gap="2px" >
                <RenderButton onClick={() => nextStep(true)}>
                  <i className="fas fa-long-arrow-alt-left"></i>
                </RenderButton>
                <RenderButton onClick={() => nextStep(false)}>
                  {stepNumber === amount ? 'Terminar' : 'Siguiente'}{' '}
                  <i className="fas fa-long-arrow-alt-right"></i>
                </RenderButton>
              </BoxFlex>
            </BoxFlex>
          </div>
        </div>
        <div className="StepTips__background"></div>
      </>
    )
  }

  return children
}

export default StepTips

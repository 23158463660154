import { useState } from "react";
import "./style/ButtonFloating.css";
const ButtonFloating = ({ text }) => {
  const [state, setState] = useState(true);
  const handleState = () => {
    setState(!state);
  };
  if (state) {
    return (
      <div className="ButtonFloating" onClick={handleState}>
        <i className="fab fa-whatsapp"></i>
      </div>
    );
  } else {
    return (
      <div className="CardFloating">
        <div className="CardFloating__header">
          <div className="CardFloating__title">
            <i className="fab fa-whatsapp"></i>
            <h3>WhatsApp</h3>
          </div>
          <div className="CardFloating__close" onClick={handleState}>
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="CardFloating__main">
          <ul>
            <li>
              ¿Quieres inscribirte a alguno de los cursos?, te ayudamos
              encantados.
            </li>
            <li>Si tienes otras consultas nos gustará escucharte.</li>
          </ul>
        </div>
        <div className="CardFloating__btn">
          <a
            href={`https://wa.me/59178960810?text=Hola%20Midas${
              text ? text.replace(" ", "%20") : ""
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Abrir chat</span>
          </a>
        </div>
      </div>
    );
  }
};

export default ButtonFloating;

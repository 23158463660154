import "./UserInstitution.css";
import { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { useAxiosAuth } from "../../../hooks";
import { convertD, dataLock } from "../../../utils/ConvertDate";
import { urlAvatar, avatarChild } from "../../../utils/Constant";
import { TableOptions, TableFilterContainer } from "../../../components/table";
import { validateArray, validateStatus } from "../../../utils/Validation";
import { postAction } from "../../../services/action/ActionAuthorization";
import moment from "moment";
import { LoadCircle, Loading } from "../../../components/animation";
import { Icon, DefaultBtn, RenderButton } from "../../../components/buttons";
import { H2, H3, H4, SubtitleData } from "../../../components/text";
import { BoxFlex } from "../../../components/boxes";
import { ViewEditUser } from "../../../components/form/ViewEditUser";
import FormAddCourse from "./FormAddCourse";

import {
  ChangePassword,
  ChangeImage,
  EditPerfil,
  TablePreregistration,
} from "../../../containers/account";
import AddUser from "../../account/AddUser";
const header = [
  { key: "firstName", name: "Nombres" },
  { key: "lastName", name: "Apellidos" },
  { key: "email", name: "Correo" },
  { key: "telephone", name: "Teléfono" },
];
const listOrder = [
  { key: "created", name: "Fecha de creación" },
  { key: "lastName", name: "Apellidos" },
  { key: "lastActive", name: "Última conexión" },
];
const UserInstitution = (props) => {
  const {
    match: {
      params: { iid },
    },
    changeInfo,
    openSnackbar,
  } = props;
  const [reload, setreload] = useState(false);

  function handleReload() {
    setreload(!reload);
    changeInfo(<></>);
  }
  function handleAddUser() {
    changeInfo(
      <>
        <AddUser />
      </>
    );
  }
  return (
    <div className="UserInstitution">
      <TableOptions
        param={`/institution/${iid}/users`}
        header={header}
        listOrder={listOrder}
        buttonAdd={
          <RenderButton
            background="var(--orange2)"
            onClick={() => handleAddUser()}
          >
            Adicionar Usuario<i className="fas fa-plus"></i>
          </RenderButton>
        }
        choice={true}
        handleReload={reload}
        handleInfo={(user) =>
          changeInfo(
            <ViewUser
              user={user}
              changeModal={props.changeModal}
              changeInfo={changeInfo}
              handleReload={handleReload}
              openSnackbar={openSnackbar}
              {...props}
            />
          )
        }
      />
    </div>
  );
};
export default withRouter(UserInstitution);

function ViewUser(props) {
  const {
    user,
    changeModal,
    openSnackbar,
    handleReload,
    iid,
    startSession,
    history,
  } = props;

  const [edit, setedit] = useState(true);
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `course/user/${user.id}/admin`,
  });
  const {
    response: respCourse,
    loading: loadCourse,
    reload: relCourse,
  } = useAxiosAuth({
    method: "GET",
    url: `/course/user/${user.id}/educator/admin`,
  });

  function onClose() {
    setedit(!edit);
  }
  const [loadBtn, setloadBtn] = useState(true);
  function handleResendUser(user) {
    setloadBtn(false);
    postAction(`/email/${user.id}/ResendCredentials`).then((response) => {
      if (response && validateStatus(response.status)) {
        openSnackbar("Se volvio a enviar el mensaje", true, true);
        changeModal(
          true,
          "Se Reenviaron las credenciales exitosamente...",
          "",
          "",
          null
        );
      } else {
        openSnackbar("No se pudo enviar el mensaje", true, false);
        changeModal(true, "No se Reenviaron las credenciales...", "", "", null);
      }
      setloadBtn(true);
    });
  }
  function resendUser(user) {
    changeModal(
      true,
      "¿Estás seguro que quieres reenviar las credenciales del usuario?",
      "",
      "Enviar",
      () => handleResendUser(user)
    );
  }

  useEffect(() => {
    reload();
    relCourse();
    setedit(true);
  }, [user.id]);

  function handleDelist(course) {
    changeModal(
      true,
      `¿Estás seguro que quieres dar de baja a ${user.firstName}?`,
      `Darás de baja a ${user.firstName} del curso de ${course.name} - ${course.versionName}`,
      "Dar de baja",
      () => delist(course)
    );
  }

  function delist(course) {
    // console.log(course)
    changeModal(
      true,
      `¿Estás seguro que quieres dar de baja a ${user.firstName}?`,
      <Loading />,
      null
    );
    postAction(
      `/${course.type === "course" ? "course" : "program"}/${
        course.id
      }/DelistUser`,
      {
        CourseId: course.id,
        ProgramId: course.id,
        UserId: user.id,
      }
    ).then((resp) => {
      if (validateStatus(resp.status)) {
        openSnackbar("Se dio de baja al usuario", true, true);
        changeModal(false, null);
        reload();
      } else {
        openSnackbar("No se pudo dar de baja", true, true);
      }
    });
  }

  function handleListCourse() {
    changeModal(
      true,
      `Elige el curso al que deseas inscribir a ${user.firstName}`,
      <TableFilterContainer
        param="/ProgramManager"
        addParam={`InstitutionId=${iid}`}
        header={[
          { key: "name", name: "Nombre" },
          { key: "creationDate", name: "Fecha de creación", type: "date" },
        ]}
        boxFilters={[
          {
            type: "select",
            label: "Ordenar por:",
            name: "OrderBy",
            options: [
              { label: "Nombre", name: "" },
              { label: "Fecha de creación", name: "created" },
            ],
          },
          {
            type: "getSelect",
            label: "Categoría:",
            name: "CategoryId",
            url: "/category",
            urlKey: "id",
            urlLabel: "name",
            initial: "Todas las categorías",
          },
        ]}
        onClick={handleFormAdd}
      />,
      null
    );
  }

  function handleFormAdd(program) {
    changeModal(
      true,
      "",
      <FormAddCourse
        user={user}
        reload={reload}
        program={program}
        openSnackbar={openSnackbar}
        onCancel={() => changeModal(false, null)}
      />,
      null
    );
  }

  function ListCourses({ courses, type }) {
    return (
      <div className="ListCourses">
        {validateArray(courses)
          ? courses.map((course) => (
              <BoxFlex wrap="nowrap">
                <Link
                  style={{ margin: "0 0 5px" }}
                  className="ListCourses__item"
                  to={`/program/${course.id}/academic/${user.id}/${type}`}
                  key={course.id}
                >
                  {course.name} - {course.versionName}
                </Link>
                <RenderButton
                  background="var(--red)"
                  onClick={() => handleDelist({ ...course, type: type })}
                >
                  <i className="fas fa-trash"></i>
                </RenderButton>
              </BoxFlex>
            ))
          : null}
      </div>
    );
  }
  function handleCopy(value) {
    navigator.clipboard.writeText(value);
    openSnackbar("Copiado!", true, true);
  }
  function handleHistory(user) {
    changeModal(true, "", <ListHistory user={user} />, null);
  }

  function handleLogin(user) {
    changeModal(
      true,
      "¿Estás seguro? Cerrarás tu sesión actual",
      `Iniciarás sesión con la cuenta de ${user.firstName} ${user.lastName}?`,
      "Iniciar sesión",
      () => processLogin(user)
    );
  }

  function processLogin(user) {
    changeModal(
      true,
      "¿Estás seguro? Cerrarás tu sesión actual",
      <Loading />,
      null
    );
    postAction(`/auth/login/replaced/${user.id}`).then((resp) => {
      if (validateStatus(resp.status)) {
        openSnackbar("Inicio Exitoso", true, true);
        changeModal(false, null);
        const { institutions, courses, user, token, father } = resp.data;
        window.localStorage.setItem("id", user.id);
        window.localStorage.setItem("token_seguridad", token);
        startSession({ ...user, father: father }, courses, institutions, true);
        history.push("/programs");
      } else {
        openSnackbar("No se pudo iniciar sesión", true, true);
      }
    });
  }

  return (
    <>
      {edit ? (
        <>
          <BoxFlex justify="flex-end" gap="5px 0">
            {loadBtn ? (
              <Icon ico="address-card" handleModal={() => resendUser(user)}>
                Enviar credenciales
              </Icon>
            ) : (
              <Loading />
            )}
            <Icon ico="stream" handleModal={() => handleHistory(user)}>
              Historial
            </Icon>
            <Icon
              ico="sign-in-alt"
              content="Iniciar sesión"
              handleModal={() => handleLogin(user)}
            ></Icon>
          </BoxFlex>
          <br />
          <H2 textAlign="center">Información del usuario</H2>

          <SubtitleData subtitle="Nombres">{user.firstName}</SubtitleData>
          <SubtitleData subtitle="Apellidos">{user.lastName}</SubtitleData>
          {user.telephone && (
            <SubtitleData subtitle="Celular">
              {user.telephone}{" "}
              <a
                className="ViewUser__anchor"
                href={`https://api.whatsapp.com/send?phone=${user.telephone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-whatsapp"></i>
              </a>{" "}
              <button
                className="ViewUser__copy"
                onClick={() => handleCopy(user.telephone)}
              >
                <i className="fas fa-copy"></i>
              </button>
            </SubtitleData>
          )}
          <SubtitleData subtitle="Correo electrónico">
            {user.email}{" "}
            <button
              className="ViewUser__copy"
              onClick={() => handleCopy(user.email)}
            >
              <i className="fas fa-copy"></i>
            </button>
          </SubtitleData>
          <SubtitleData subtitle="Ultima conexión">
            {user.lastActive && user.lastActive !== "0001-01-01T00:00:00"
              ? convertD(user.lastActive, "LLLL")
              : "N/A"}
          </SubtitleData>
          <DefaultBtn
            width="100%"
            onClick={() => {
              setedit(false);
            }}
          >
            Editar Informacion del Usuario
          </DefaultBtn>
          <br />
          <BoxFlex justify="space-between" margin="10px 0">
            <H2 margin="0">Cursos inscritos</H2>
            <RenderButton onClick={handleListCourse}>
              <i className="fas fa-plus"></i>
            </RenderButton>
          </BoxFlex>
          {loading ? (
            <LoadCircle />
          ) : validateArray(response.programesPerUser) ||
            validateArray(response.coursesPerUser) ? (
            <>
              <ListCourses courses={response.programesPerUser} type="Program" />
              <ListCourses courses={response.coursesPerUser} type="course" />
            </>
          ) : (
            <H3 margin="0">El usuario no tiene cursos</H3>
          )}
          {loadCourse ? (
            <LoadCircle />
          ) : (
            validateArray(respCourse) && (
              <>
                <H2 margin="15px 0 10px" textAlign="center">
                  Cursos dictados
                </H2>
                <BoxFlex direction="row">
                  {respCourse
                    .sort(function (left, right) {
                      return moment
                        .utc(right.finishDate)
                        .diff(moment.utc(left.finishDate));
                    })
                    .map((item) => (
                      <a
                        href={`/institution/1/course/${item.courseId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          border: "1px solid gray",
                          padding: "10px",
                          borderRadius: "5px",
                          marginBottom: "5px",
                          textDecoration: "none",
                          opacity: !dataLock(item.finishDate) ? "1" : "0.5",
                        }}
                        key={item.courseId}
                      >
                        <H3
                          opacity="0.7"
                          color="var(--black)"
                          textAlign="center"
                          margin="0 0 8px"
                        >
                          {item.programName} - {item.parallel}
                        </H3>
                        <H3 fontWeight="500" margin="0">
                          Curso: <strong>{item.courseName}</strong>
                        </H3>
                        <H3 fontWeight="500" margin="0">
                          Fecha de inicio:{" "}
                          <strong>{convertD(item.startDate, "LL")}</strong>
                        </H3>
                        <H3 fontWeight="500" margin="0">
                          Fecha de fin:{" "}
                          <strong>{convertD(item.finishDate, "LL")}</strong>
                        </H3>
                      </a>
                    ))}
                </BoxFlex>
              </>
            )
          )}
        </>
      ) : (
        <div>
          <ViewEditUser
            onClose={onClose}
            handleReload={handleReload}
            user={{ ...user }}
            changeModal={changeModal}
            setedit={setedit}
            openSnackbar={openSnackbar}
            reload={reload}
          />
        </div>
      )}
      <RelationshipParent user={user} changeModal={changeModal} />
    </>
  );
}

const RelationshipParent = ({ user, changeModal }) => {
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `/users/UserTutorId/${user.id}`,
  });
  useEffect(() => {
    if (!loading) {
      reload();
    }
  }, [user]);

  function handleDetail(child) {
    changeModal(true, "", <UserDetail child={child} />, null);
  }

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="">
        <br />
        {validateArray(response.childs) && (
          <>
            <H2>Lista de niños</H2>
            {response.childs.map((child) => (
              <CardChildren
                user={child}
                type="child"
                key={child.id}
                onClick={() => handleDetail(child)}
              />
            ))}
          </>
        )}
        {validateArray(response.fathers) && (
          <>
            <H2>Lista de tutores</H2>
            {response.fathers.map((father) => (
              <CardChildren key={father.id} user={father} type="tutor" />
            ))}
          </>
        )}
      </div>
    );
  }
};

const CardChildren = ({ user, type, onClick = null }) => {
  return (
    <div className="CardChildren" onClick={onClick}>
      <div
        style={{
          backgroundImage: `url("${
            user.photoUrl
              ? user.photoUrl
              : type === "child"
              ? avatarChild
              : urlAvatar
          }")`,
        }}
        className="CardChildren__img"
      ></div>
      <div>
        <H3 fontWeight="700" margin="0">
          {user.name} {user.lastName}
        </H3>
        {user.email && (
          <H3 fontWeight="500" margin="0">
            Correo: <span style={{ fontWeight: "600" }}>{user.email}</span>
          </H3>
        )}
        {user.username && (
          <H3 fontWeight="500" margin="0">
            Usuario: <span style={{ fontWeight: "600" }}>{user.username}</span>
          </H3>
        )}
      </div>
    </div>
  );
};

const UserDetail = ({ child }) => {
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `/users/detailChildForUser?userId=${child.id}`,
  });

  return <div>UserDetail</div>;
};

const ListHistory = ({ user }) => {
  function preProcess(data, key) {
    return (
      <H4
        innerHTML
        color="var(--gray)"
      >{`${data[key]} (<strong>${data.referenceName}</strong>)`}</H4>
    );
  }
  return (
    <div style={{ textAlign: "start" }}>
      <H3 color="var(--gray)">
        Historial del acciones de {user.firstName} {user.lastName}
      </H3>
      <TableFilterContainer
        param={`/userAction/student/${user.id}`}
        header={[
          {
            key: "name",
            name: "Acción",
            type: "preProcess",
            validate: preProcess,
          },
          { key: "createDate", name: "Fecha", type: "date" },
        ]}
        boxFilters={[
          {
            type: "getSelect",
            label: "Acción:",
            name: "action",
            url: "/action",
            urlKey: "id",
            urlLabel: "name",
            initial: "Todas las acciones",
          },
        ]}
      />
    </div>
  );
};

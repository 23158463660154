import { Formik, Form } from "formik";
import { Loading } from "../../animation/index";
import { postAction } from "../../../services/action/Action";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { openSnackbar, startSession } from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormikControl } from "../../input";
import { RenderButton } from "../../buttons";
import { dropdownGender } from "../../../utils/Constant";
import config from "../../../config.json";

import "../../../containers/account/style/EditPerfil.css";
import * as Yup from "yup";
// import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils'

const Register = (props) => {
  const [load, setload] = useState(true);
  const { t } = useTranslation("Register");
  const initialValues = {
    username: "",
    email: "",
    password: "",
    password_confirmation: "",
    number: "",
    termsOfService: false,
    gender: "male",
  };
  const validationSchema = Yup.object({
    username: Yup.string().required(t("fieldObligatory")),
    email: Yup.string().email(t("invalidEmail")).required(t("fieldObligatory")),
    password: Yup.string()
      .required(t("fieldObligatory"))
      .min(4, t("charactersRequired")),
    password_confirmation: Yup.string()
      .required(t("fieldObligatory"))
      .min(4, t("charactersRequired"))
      .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
    termsOfService: Yup.boolean()
      .required("Los términos y condiciones deben ser aceptados.")
      .oneOf([true], "Los términos y condiciones deben ser aceptados."),
  });
  function save(res, values) {
    setload(true);
    if (res.status === 201) {
      props.openSnackbar("Registro Exitoso", true, true);
      const { rolsUserToReturn, userToReturn, token, father } = res.data
      window.localStorage.setItem('id', userToReturn.id)
      window.localStorage.setItem('token_seguridad', token)
      props.startSession(
        { ...userToReturn, father: father },
        rolsUserToReturn,
        [],
        true,
      )
      props.history.push(`/waitConfirmEmail?email=${values.email}`);
      // if (props.location.state) {
      // }
    } else {
      if (res.data === "email_exists") {
        props.openSnackbar("email existente", true, false);
      } else if (res.data === "username_exists") {
        props.openSnackbar("usuario existente", true, false);
      } else {
        props.openSnackbar("Error al guardar", true, false);
      }
    }
  }
  const onSubmit = async (values) => {
    try {
      setload(false);
      await postAction(
        "/auth/register",
        config.domainURL === "https://utbaulavirtual.com"
          ? { ...values, institutionId: 3 }
          : values
      ).then((response) => {
        save(response, values);
      });
    } catch (e) {
      setload(true);
      console.log(e);
    }
  };
  const location = props.location.pathname;

  return (
    <div className="Login">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={t("user")}
                name="username"
                labelColor="white"
              />
              {/* <FormikControl
                control="input"
                type="text"
                label={t("Nombre")}
                name="FirstName"
                labelColor="white"
              />
              <FormikControl
                control="input"
                type="text"
                label={t("Apellido")}
                name="LastName"
                labelColor="white"
              /> */}
              <FormikControl
                control="input"
                type="email"
                label={t("email")}
                name="email"
                labelColor="white"
              />
              {/* <FormikControl
                control="select"
                label="Genero"
                name="gender"
                labelColor="white"
                options={dropdownGender}
              /> */}
              <FormikControl
                control="input"
                type="password"
                label={t("password")}
                name="password"
                labelColor="white"
              />
              <FormikControl
                control="input"
                type="password"
                label={t("confirmPassword")}
                name="password_confirmation"
                labelColor="white"
              />
              <FormikControl
                control="input"
                type="checkbox"
                label={
                  <>
                    Al clicar aquí, concuerdo con los{" "}
                    <a
                      style={{ color: "var(--purple)", fontSize: "1.3rem" }}
                      href="/terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Términos de Uso y Políticas
                    </a>{" "}
                    de Midas.li
                  </>
                }
                name="termsOfService"
                labelColor="white"
              />
              {location === "/auth/test-vocacional-register" && (
                <>
                  <FormikControl
                    control="input"
                    type="text"
                    label={t("number")}
                    name="number"
                    labelColor="white"
                  />
                </>
              )}
              {load ? (
                <RenderButton
                  type="submit"
                  fontSize="1.8rem"
                  background="linear-gradient(90deg, #f0cf0c 0%, #eea417 100%)"
                  width="100%"
                  borderRadius="10px"
                  // disabled={!formik.isValid}
                >
                  {t("createAccount")}
                </RenderButton>
              ) : (
                <Loading />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
const mapDispatchToProps = {
  openSnackbar,
  startSession,
};
export default connect(null, mapDispatchToProps)(withRouter(Register));

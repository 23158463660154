import { InputsCourse } from '../../../../utils/FormParameters'
import { GlobalForm } from '../../../../components/form'
import { UseOnSubmit } from '../../../../hooks/useOnSubmit'
import { useState } from 'react'
const FormCourse = (props) => {
  const { changeInfo, openSnackbar, reload, pid } = props
  const [load, setload] = useState(true)
  async function onSubmit(values) {
    setload(false)
    await UseOnSubmit({
      axiosParams: {
        method: 'POST',
        url: '/course',
        data: {
          ...values,
          Name: values.Name.toUpperCase(),
          ProgramId: pid,
          InstitutionId: 1,
          IsIndependent: false,
        },
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Curso creado con éxito',
      textWrong: 'No se pudo crear',
      functionSuccess: reload,
      process: true,
    })
    setload(true)
  }
  return (
    <>
      <GlobalForm
        InputsJson={InputsCourse}
        title="Crear curso"
        textBtn="Crear"
        data={{}}
        onSubmit={onSubmit}
        load={load}
        onCancel={() => changeInfo(<></>)}
      />
    </>
  )
}

export default FormCourse
